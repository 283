import React, {useState} from 'react';
import {
    Button,
    CardContent,
    Container,
    Grid,
    Modal,
    ModalActions,
    ModalContent,
    ModalHeader
} from "semantic-ui-react";
import languages from "../constants/languages";
import {AiFillEdit} from "react-icons/ai";

const tasks = [
    {id: 'translation', value: 'translation', text: 'Translation'},
    {id: 'proofreading', value: 'proofreading', text: 'Proofreading'},
    {id: 'transcription', value: 'transcription', text: 'Transcription'},
];


const OrderDetailModal = (props) => {
    const downloadBlob = (blob, filename) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename || 'download';
        const clickHandler = () => {
            setTimeout(() => {
                URL.revokeObjectURL(url);
                a.removeEventListener('click', clickHandler);
            }, 150);
        };
        a.addEventListener('click', clickHandler, false);
        a.click();
        return a;
    }

    const downloadAttachment = async (filename, presignedurl) => {
        let response;
        try {
            response = await fetch(presignedurl, {mode: 'cors'});
            const blob = await response.blob()
            downloadBlob(blob, filename)
        } catch {
            window.open(presignedurl, "_blank")
        }
    }

    const [selectedFileModalOpen, setselectedFileModalOpen] = useState(false);

    return (
        <React.Fragment>
            {/*{!!selectedFileModalOpen &&*/}
            {/*<UploadFileModal*/}
            {/*    isOpenModal={selectedFileModalOpen}*/}
            {/*    setisOpenModal={setselectedFileModalOpen}*/}
            {/*    currentFiles={currentFiles}*/}
            {/*    setCurrentFiles={setCurrentFiles}*/}
            {/*    currentFilesWordsCount={currentFilesWordsCount}*/}
            {/*    setcurrentFilesWordsCount={setcurrentFilesWordsCount}*/}
            {/*    countWords={false}*/}
            {/*/>*/}
            {/*}*/}

            <Modal
                open={props.isOpenModal}
            >
                <ModalHeader
                    close={<button
                        className="btn-close" onClick={props.handleDetailModal}/>}
                >
                    {props.row.original.projectName + " - " + "Details"}
                </ModalHeader>
                <ModalContent>
                    <CardContent>
                        <Container>
                            <Grid>
                                <Grid.Row>
                                    <h3> User info </h3>
                                    <Container>
                                        <p><b> Order ID: </b> {props.row.original.orderid}</p>
                                    </Container>
                                    <Container>
                                        <p><b> User Sub: </b> {props.row.original.completeProjectInfo.user_info.sub}</p>
                                    </Container>
                                    <Container>
                                        <p><b> User
                                            Email: </b> {props.row.original.completeProjectInfo.user_info.userAttributes.email}
                                        </p>
                                    </Container>
                                    <Container>
                                        <p><b> User
                                            Name: </b> {props.row.original.completeProjectInfo.user_info.userAttributes.name}
                                        </p>
                                    </Container>
                                </Grid.Row>
                                <Grid.Row>
                                    <h3>{tasks.filter(item => item.value === props.row.original.completeProjectInfo.task)[0].text + " Info"}</h3>
                                    <Container>
                                        <b> Delivery Time: </b>
                                        <i> {props.row.original.completeProjectInfo.selection.deliveryTime}
                                            <AiFillEdit
                                                style={{cursor: "pointer"}}
                                                onClick={() => props.handleOpenModalEditField({column: {'id': 'projectInfo.selection.deliveryTime'}}, props.row)}/>
                                        </i>
                                    </Container>
                                    <Container><b> Source Lang: </b>
                                        {languages.filter(item => item.value === props.row.original.completeProjectInfo.from)[0].text}
                                    </Container>
                                    {(props.row.original.completeProjectInfo.task === 'translation' || props.row.original.completeProjectInfo.task === 'proofreading') &&
                                    <Container>
                                        <div><b>Target Lang(s): </b>
                                            {props.row.original.completeProjectInfo.to.map(lang => (languages.filter(item => item.value === lang.value)[0].text) + " ")}
                                        </div>
                                        <div>
                                            <b>Word Count: </b>
                                            <i>{props.row.original.completeProjectInfo.wordCount}
                                                <AiFillEdit
                                                    style={{cursor: "pointer"}}
                                                    onClick={() => props.handleOpenModalEditField({column: {'id': 'projectInfo.selection.deliveryTime'}}, props.row)}/>
                                            </i>
                                        </div>
                                    </Container>
                                    }
                                </Grid.Row>
                                <Grid.Row className={"mt-3"}>
                                    <h3> Note User: </h3>
                                    <i>
                                        <AiFillEdit
                                            style={{cursor: "pointer"}}
                                            onClick={() => props.handleOpenModalEditField({column: {'id': 'projectInfo.selection.deliveryTime'}}, props.row)}/>
                                    </i>
                                    <Container>
                                        {props.row.original.completeProjectInfo.projectNote}
                                    </Container>
                                </Grid.Row>
                                <Grid.Row>
                                    <h3> Note Internal_h: <AiFillEdit
                                        style={{cursor: "pointer"}}
                                        onClick={() => props.handleOpenModalEditField({column: {'id': 'notes_h'}}, props.row)}/>
                                    </h3>
                                    <Container>
                                        {props.row.original.notes_h}
                                    </Container>
                                </Grid.Row>
                                <Grid.Row className={"mt-3"}>
                                    <h3> Attachments: </h3>
                                    <Container>
                                        {!!props.row.original.attachments && props.row.original.attachments.length > 0 &&
                                        props.row.original.attachments.map(item => (
                                            <i key={item.id}
                                               onClick={() => downloadAttachment(item.currentFileName, item.link)}
                                               style={{cursor: "pointer", color: "blue"}}>{item.currentFileName}</i>
                                        ))
                                        }
                                        {(!props.row.original.attachments || (!!props.row.original.attachments && props.row.original.attachments.length === 0)) &&
                                        <i>No Attachments</i>
                                        }
                                    </Container>
                                </Grid.Row>
                                <Grid.Row>
                                    <h4> Delivery: </h4>
                                    <Container>
                                        {!!props.row.original.deliveredFiles && props.row.original.deliveredFiles.length > 0 &&
                                        props.row.original.deliveredFiles.map(item => (
                                            <i key={item.id} style={{cursor: "pointer", color: "blue"}}
                                               onClick={() => {
                                                   downloadAttachment(item.currentFileName, item.link)
                                               }}
                                            >
                                                {item.currentFileName}
                                            </i>
                                        ))
                                        }
                                        {(!props.row.original.deliveredFiles || (!!props.row.original.deliveredFiles && props.row.original.deliveredFiles.length === 0)) &&
                                        <i>No delivery yet</i>
                                        }
                                    </Container>
                                </Grid.Row>
                            </Grid>
                        </Container>
                    </CardContent>

                </ModalContent>
                <ModalActions>
                    <Button
                        primary
                        onClick={props.handleDetailModal}>
                        {"Close"}
                    </Button>
                </ModalActions>
            </Modal>
        </React.Fragment>
    );
};

export default OrderDetailModal;