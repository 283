import React from 'react';
import {Button, Modal} from "semantic-ui-react";


const ModalMaxLengthReached = (props) => {

    // TODO send us an email with the setted preference

    return (
        <Modal
            centered={false}
            open={props.open}
            onClose={() => props.setOpen(false)}
        >
            <Modal.Header>Max Length For a Free Note Reached</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    We are working on a premium plan that allows you to create unlimited notes with unlimited length.
                    Would you like to see this feature available?
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => props.setOpen(false)}>NO</Button>
                <Button onClick={() => props.setOpen(false)}>YES</Button>
            </Modal.Actions>
        </Modal>
    );

};

export default ModalMaxLengthReached;