import React, {Suspense} from 'react';
import {BrowserRouter as Router, Route, Redirect, Switch} from 'react-router-dom';
import './App.css';
import ResponsiveContainer from "./shared/ResponsiveContainer";
import {AuthContext} from "./shared/context/auth-context";
import {useAuth} from "./shared/hooks/auth-hook";
import TranscriptMgmntPage from "./transcript_mgmt/Pages/TranscriptMgmntPage";
import MyFiles from "./personalArea/Pages/MyFiles";
import SettingPage from "./personalArea/Pages/SettingPage";
import ViewFilePage from "./FileViewsPage/Pages/ViewFilePage";
import NoteWehyPage from "./NoteWhey/Pages/NoteWehyPage";
import AuthPage from "./Auth/pages/AuthPage";
import 'semantic-ui-less/semantic.less';
import BlogCreation from "./BlogCreation/Pages/BlogCreation";
import OrderCreationPage from "./OrderCreation/OrderCreationPage";
import ClientOrders from "./ClientOrders/ClientOrders";
import HeroUsersPage from "./Herousers/HeroUsersPage";

function App() {

    const {isLoggedIn, userId, login, logout, credits, sub, currentPlan} = useAuth();

    let routes;

    if (isLoggedIn) {
        routes = (
            <Switch>

                <Route path={"/account/notewhey"} exact>
                    <NoteWehyPage/>
                </Route>

                <Route path={["/account/my-files", "/account"]} exact>
                    <MyFiles/>
                </Route>

                <Route path={"/account/settings"} exact>
                    <SettingPage/>
                </Route>

                <Route path={"/account/transcript-mgmnt/:transcribeid"} exact>
                    <TranscriptMgmntPage/>
                </Route>

                <Route path={"/account/fileview/:idfile"} exact>
                    <ViewFilePage/>
                </Route>

                <Route path={"/account/blog-creation"} exact>
                    <BlogCreation/>
                </Route>

                <Route path={"/account/order-creation"} exact>
                    <OrderCreationPage/>
                </Route>

                <Route path={"/account/client-orders"} exact>
                    <ClientOrders/>
                </Route>

                <Route path={"/account/herousers"} exact>
                    <HeroUsersPage/>
                </Route>


                <Redirect to={"/account/my-files"}/>

            </Switch>
        )
    } else {
        routes = (
            <Switch>

                <Route path={"/auth"} exact>
                    <AuthPage/>
                </Route>

                <Redirect to={"/auth"}/>

            </Switch>
        )
    }


    return (
        <React.Fragment>
            <AuthContext.Provider
                value={{
                    isLoggedIn: isLoggedIn,
                    userId: userId,
                    credits: credits,
                    login: login,
                    sub: sub,
                    logout: logout,
                    currentPlan: currentPlan
                }}
            >
                <Router>
                    <Suspense fallback="loading">
                        <ResponsiveContainer>
                            <main>
                                {routes}
                            </main>
                        </ResponsiveContainer>
                    </Suspense>
                </Router>
            </AuthContext.Provider>
        </React.Fragment>

    );
}

export default App;
