import React, {useState} from 'react';
import {Button, Checkbox, Container, Dimmer, Dropdown, Input, Loader, Modal, Progress} from "semantic-ui-react";
import ErrorModal from "../shared/FormElements/ErrorModal";
import {API} from "aws-amplify";
import axios from "axios";

const possibleOrderStates = [
    {key: "WAITING_PAYMENT", value: "WAITING_PAYMENT", text: "Waiting Payment"},
    {key: "IN_PROGRESS", value: "IN_PROGRESS", text: "In Progress"},
    {key: "COMPLETED", value: "COMPLETED", text: "Completed"},
    {key: "CANCELED", value: "CANCELED", text: "Canceled"},
]

const paymentPossiblities = [
    {key: "TO_PAY", value: "TO_PAY", text: "To Pay"},
    {key: "COMPLETED", value: "COMPLETED", text: "Payed"},
]

const EditFieldOrder = (props) => {

    const [err, setErr] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [newValue, setNewValue] = useState(props.cell.value);
    const [showProgress, setshowProgress] = useState(false);
    const [uploadPercentage, setuploadPercentage] = useState(0);
    const [deliveryFiles, setDeliveryFiles] = useState([]);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [sendAutomaticEmail, setSendAutomaticEmail] = useState(false);

    const handleCloseErrorModal = () => {
        setErr(false);
        setIsLoading(false);
    }

    const handleCloseModal = () => {
        props.close(false);
        props.setRow(null);
        props.setRow(null);
        props.handleRefres()
    }

    const handleChangeProperty = (e) => {
        let value = e.target.value;
        if (props.cell.column.id === "amount") {
            if (value[value.length - 1] === ".") {
                value = Number(String(value) + "01")
            }
            if (!Number(value) && value !== "") {
                return;
            }
            if (value === "") {
                value = 0;
            }
            value = Number(value);
        }
        setNewValue(value);
    }

    const handleWriteNewValue = async () => {
        setIsLoading(true);
        try {
            await API.post(
                'history',
                '/get-orders',
                {
                    body: {
                        update: true,
                        field: props.cell.column.id,
                        newValue: props.cell.column.id === "amount" ? newValue * 100 : newValue,
                        orderid: props.row.original.orderid,
                        sendAutomaticEmail: sendAutomaticEmail
                    }
                }
            );
            if (props.cell.column.id === 'status' && newValue === "COMPLETED") {
                uploadDeliveryFiles();
            } else {
                handleCloseModal();
            }
        } catch (err) {
            setErr("Error writing the new value");
        }

    }

    const handleChangeStatus = (e, data) => {
        setNewValue(data.value);
    }

    const uploadFileToS3 = async (presignedPostData, file) => {
        const formData = new FormData();

        Object.keys(presignedPostData.fields).forEach(key => {
            formData.append(key, presignedPostData.fields[key]);
        });
        formData.append('file', file);

        setIsLoading(false);
        axios.post(presignedPostData.url, formData, {
            onUploadProgress: progressEvent => {
                setshowProgress(true);
                setIsLoading(false);
                setuploadPercentage(Math.round((progressEvent.loaded / progressEvent.total) * 100));
            },
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(function (response) {
            setshowProgress(false);
        }).catch(function (error) {
            console.log(error)
            setshowProgress(false);
            setErr(true);
        });
    };

    const uploadFiles = async (filesToUpload) => {

        try {
            const presignedurl = await API.post(
                'filemgmt',
                '/createPresignedUrl',
                {
                    body: {
                        object_name: filesToUpload.file.name,
                        type: filesToUpload.file.type ? filesToUpload.file.type : 'unknown',
                        sub: props.row.original.completeProjectInfo.user_info.sub,
                        orderid: props.row.original.orderid
                    },
                    queryStringParameters: {
                        mod: 'upload_hero_delivery'
                    }
                })
            if (presignedurl !== "error") {
                uploadFileToS3(presignedurl, filesToUpload.file);
            } else {
                setErr("Error uploading files")
            }

        } catch (err) {
            setErr(true);
            setIsLoading(false);
        }
    };

    const onFileChange = async (e) => {
        var files;
        if (!!e.target) {
            files = e.target.files;
        } else {
            files = e.files;
        }
        let deliveryFiles_temp = [];
        let tempFiles = []
        for (var i = 0; i < files.length; i++) {
            var file = files[i];
            deliveryFiles_temp.push(
                {
                    "currentFileName": file.name,
                    "id": file.name
                }
            )
            tempFiles.push(file);
        }
        setDeliveryFiles(deliveryFiles_temp);
        setFilesToUpload(tempFiles);
    }

    const uploadDeliveryFiles = async () => {

        for (var i = 0; i < filesToUpload.length; i++) {
            var file = filesToUpload[i];
            await uploadFiles({'file': file});
        }

        API.post(
            'history',
            '/get-orders',
            {
                body: {
                    update: true,
                    field: "deliveryFiles",
                    newValue: deliveryFiles,
                    orderid: props.row.original.orderid
                }
            }
        ).then(res => {
                setIsLoading(false);
                handleCloseModal();
            }
        ).catch(err => {
                setIsLoading(false);
                setErr(true);
            }
        )
    }

    const handleCheckSendEmail = (e, data) => {
        setSendAutomaticEmail(data.checked);
    }

    return (
        <React.Fragment>

            <Modal
                centered={true}
                open={props.open}
                onClose={handleCloseModal}
            >
                <Modal.Header>Change Client Project Informations</Modal.Header>
                <Modal.Content>
                    <Dimmer active={isLoading}>
                        <Loader/>
                    </Dimmer>
                    <label>Information that you are modifying: <b>{" " + props.cell.column.id}</b></label>
                    <br/>
                    {props.cell.column.id !== 'status' && props.cell.column.id !== 'payment' &&
                    <Input
                        style={{width: "370px"}}
                        id={props.cell.column.id}
                        name={props.cell.column.id}
                        placeholder={props.cell.column.id}
                        value={newValue}
                        onChange={handleChangeProperty}
                    />
                    }
                    {(props.cell.column.id === 'status' || props.cell.column.id === 'payment') &&
                    <Dropdown
                        name={"orderStatusModal"}
                        onChange={handleChangeStatus}
                        options={props.cell.column.id === 'status' ? possibleOrderStates : paymentPossiblities}
                        placeholder={"Select the state"}
                        value={newValue}
                        search
                        selection
                    />
                    }
                    {props.cell.column.id === 'status' && newValue === "COMPLETED" &&
                    <Container style={{marginTop: '20px'}}>
                        <p><b> Upload delivery Files </b></p>
                        <input
                            type={"file"}
                            multiple={true}
                            onChange={onFileChange}
                        />
                        <br/>
                        <br/>
                    </Container>
                    }
                    {props.cell.column.id === 'status' && newValue === "COMPLETED" &&
                    <Container>
                        <div><Checkbox
                            onClick={handleCheckSendEmail}
                        />
                            <b> Send email to customer </b>
                        </div>
                    </Container>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button secondary onClick={handleCloseModal}> Cancel </Button>
                    <Button primary disabled={!newValue} onClick={handleWriteNewValue}>
                        {props.cell.column.id === 'status' && newValue === "COMPLETED" ? "Deliver Project" : "Change"}
                    </Button>
                </Modal.Actions>
            </Modal>

            <ErrorModal
                open={!!err}
                handleCloseErrorModal={handleCloseErrorModal}
                body={err}
                title={'An error occurred'}
                icon={'bug'}
            />

            <Modal
                open={showProgress}
                className={"modal"}
            >
                <Modal.Header>Uploading</Modal.Header>
                <Modal.Content>
                    <Progress
                        color={'blue'}
                        percent={uploadPercentage}
                        progress/>
                </Modal.Content>
            </Modal>

        </React.Fragment>
    )

};

export default EditFieldOrder;