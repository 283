import React, {useState} from 'react';
import OrderDetailModal from "./OrderDetailModal";
import {BiDotsHorizontalRounded} from 'react-icons/bi';
import {Button} from "semantic-ui-react";

const DetailDropDown = (props) => {

    const [showDetailModal, setshowDetailModal] = useState(false);

    const handleDetailModal = () => {
        setshowDetailModal(!showDetailModal);
    }

    return (
        <React.Fragment>

            {!!showDetailModal &&
            <OrderDetailModal
                row={props.row}
                handleOpenModalEditField={props.handleOpenModalEditField}
                isOpenModal={showDetailModal}
                handleDetailModal={handleDetailModal}
            />
            }

            <Button onClick={handleDetailModal} role={"button"}> <BiDotsHorizontalRounded size={25}/> </Button>
        </React.Fragment>
    );

};

export default DetailDropDown