import React from 'react';


const FileDetails = (props) => {

    return (
        <React.Fragment>
            <p>
                <b> File Category: </b> {props.file.Kind}
            </p>
            <p>
                <b> Size: </b> {!!props.file.size && (+props.file.size / 1000000).toFixed(2) + ' MB'}
            </p>
            {!!props.fileDetail && !!props.fileDetail.fileinfos
            && !!props.fileDetail.fileinfos.duration &&
            props.fileDetail.fileinfos.duration !== 'n/a' &&
            <p>
                <b>{'Estiamted ' + props.file.Kind + ' Duration :'}
                </b> {Math.ceil(+props.fileDetail.fileinfos.duration) + ' sec'
            + ' (' + Math.ceil(+props.fileDetail.fileinfos.duration / 60) + ' min)'
            }
            </p>}
            {!!props.fileDetail && !!props.fileDetail.fileinfos && !!props.fileDetail.fileinfos.description &&
            <p>
                <b>Description: </b> {props.fileDetail.fileinfos.description}
            </p>}

        </React.Fragment>
    );

};

export default FileDetails;