import React, {useEffect, useState} from 'react';
import {EditorState, convertToRaw, convertFromRaw} from 'draft-js';
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {Button, Dimmer, Header, Icon, Loader, Modal, Segment} from "semantic-ui-react";
import {API} from "aws-amplify";
import TextEditorButtons from "./TextEditorButtons";

const TextEditor = (props) => {

    const [askCancel, setaskCancel] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [savestatus, setsavestatus] = useState(false);
    const [editorState, setEditorState] = useState(
        () => (EditorState.createWithContent(convertFromRaw(props.initial_text.draftjs))),
    );

    useEffect(() => {
        const content = window.localStorage.getItem('content')
        if (!!content) {
            const parsedContent = JSON.parse(content);
            if (parsedContent.transcribeid === props.transcribeid) {
                const convertedState = convertFromRaw(parsedContent.last_edit);
                setEditorState(EditorState.createWithContent(convertedState));
            } else {
                window.localStorage.removeItem('content');
            }
        } else if (!content) {
            window.localStorage.setItem('content', JSON.stringify({
                'last_edit': props.initial_text.draftjs,
                'transcribeid': props.transcribeid
            }))
        }
    }, [props.transcribeid]);


    const saveContentLocalStorage = (state) => {
        try {
            const contentState = state.getCurrentContent();
            window.localStorage.setItem('content', JSON.stringify({
                'last_edit': convertToRaw(contentState),
                'transcribeid': props.transcribeid
            }));
        } catch {
            // TODO: show error modal
        }
    }

    const onEditorStateChange = (state) => {
        saveContentLocalStorage(state);
        setEditorState(state);
    }

    const handleCancel = () => {
        setaskCancel(true);
    }

    const deleteModifications = () => {
        window.localStorage.removeItem('content');
        setEditorState(EditorState.createWithContent(convertFromRaw(props.initial_text.draftjs)));
        setaskCancel(false);
        window.localStorage.setItem('content', JSON.stringify({
            'last_edit': props.initial_text.draftjs,
            'transcribeid': props.transcribeid
        }))
    }

    const handleSaveModifications = () => {
        setisLoading(true);
        var parsedContent_mod = JSON.parse(window.localStorage.getItem('content'));
        API.put(
            'transcribemgmt',
            '/edit',
            {
                body: {
                    'mod': true,
                    'draftjs': parsedContent_mod.last_edit
                },
                queryStringParameters: {
                    transcribeid: props.transcribeid,
                },
            })
            .then(response => {
                if (response) {
                    setisLoading(false);
                    //setsavestatus("success");
                }
            })
            .catch(error => {
                setisLoading(false);
                setsavestatus("fail");
            });
    }

    const handleSaveSuccess = () => {
        props.handleRefresh();
        setsavestatus(false);
    }

    const handleMod = () => {
        var newBlocks = []
        newBlocks = convertToRaw(editorState.getCurrentContent()).blocks.filter(
            block => !block.text.startsWith("Speaker_") && !block.text.startsWith("Words with confidence below")
        );
        // REMOVE INLINE FORMATS
        // newBlocks = newBlocks.map(block => {
        //     var new_block = block;
        //     new_block.inlineStyleRanges = []
        //     return (new_block)
        // })
        setEditorState(EditorState.createWithContent(convertFromRaw({entityMap: {}, blocks: newBlocks})));
    }

    //defaultChecked
    return (
        <React.Fragment>

            <Segment>
                <Dimmer active={!editorState || isLoading}>
                    <Loader/>
                </Dimmer>
                <br/>
                <TextEditorButtons
                    modvisible={props.initial_text.mod !== 'plain'}
                    handleMod={handleMod}
                    handleSaveModifications={handleSaveModifications}
                    handleCancel={handleCancel}
                    editorState={editorState}
                />
                <Editor
                    editorStyle={{border: "1px solid", height: '500px', overflow: 'scroll'}}
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={onEditorStateChange}
                />
            </Segment>

            <Modal
                closeIcon
                open={askCancel}
                onClose={() => setaskCancel(false)}
            >
                <Header icon='archive' content='Ary you sure!?'/>
                <Modal.Content>
                    <p>
                        Do you want to revert you modifications?
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={() => setaskCancel(false)}>
                        <Icon name='remove'/> No
                    </Button>
                    <Button color='green' onClick={deleteModifications}>
                        <Icon name='checkmark'/> Yes
                    </Button>
                </Modal.Actions>
            </Modal>

            <Modal
                basic
                onClose={() => setsavestatus(false)}
                open={savestatus === 'success' || savestatus === 'fail'}
                size='small'
            >
                <Header icon>
                    <Icon name='archive'/>
                    {savestatus === 'success' ? 'Success' : 'Error'}
                </Header>
                <Modal.Content>
                    <p>
                        {savestatus === 'success' ? 'Modification Save With Success' : 'Modification Saver Error. Please Retry.'}
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='green' inverted onClick={handleSaveSuccess}>
                        <Icon name='checkmark'/> Ok
                    </Button>
                </Modal.Actions>
            </Modal>

        </React.Fragment>
    );
};

export default TextEditor;