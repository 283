import React, {useState} from 'react';
import {Button, Container, Dimmer, Grid, Loader, Pagination} from "semantic-ui-react";
import Table from "../shared/UIElements/Table";
import ModalUserDetails from "./ModalUserDetails";
import ModalSendPromo from "./ModalSendPromo";

const itemperpage = [
    {"value": 10, "id": 10, "text": "10"},
    {"value": 25, "id": 25, "text": "25"},
    {"value": 50, "id": 50, "text": "50"},
];

const UserTable = (props) => {

    const [modalUserDetails, setmodalUserDetails] = useState(false);
    const [row, setRow] = useState('');
    const [cell, setCell] = useState('');
    const [showModalPromo, setshowModalPromo] = useState(false);

    const handleModalUserDetails = (row, cell) => {
        setmodalUserDetails(!modalUserDetails);
        setRow(row);
        setCell(cell);
    }

    const handleShowModalPromo = (row) => {
        setshowModalPromo(!showModalPromo)
        setRow(row);
    }

    const handlePageChange = (page) => {

        if (props.currentPage === page) {
            return;
        }

        console.log(page)
        if (!props.isloading) {
            let tagetPage;
            if (page === 'next') {
                if (!props.LastEvaluatedKey || props.lastPage === props.currentPage) {
                    return;
                }
                tagetPage = props.currentPage + 1
                props.setCurrentPage(tagetPage);
            } else if (page === 'previous') {
                if (props.currentPage === -1) {
                    return;
                }
                tagetPage = props.currentPage - 1;
                if (tagetPage >= -1) {
                    props.setCurrentPage(props.currentPage - 1);
                }
            } else {
                props.setCurrentPage(page);
            }
        }
    };

    const handleFilterOrderStatus = (status) => {
        props.setOrderStatus(status)
    }

    const handleChangeItemPerPage = (e) => {
        props.setitemPerPage(e.target.value);
        props.setLastPage(-2);
        props.setLastEvaluatedKey([]);
        props.setpageData(null);
        props.setCurrentPage(-1);
    }

    return (
        <React.Fragment>
            {!!modalUserDetails &&
            <ModalUserDetails
                open={!!modalUserDetails}
                handleModalUserDetails={handleModalUserDetails}
                row={row}
                cell={cell}
            />
            }

            {!!showModalPromo &&
            <ModalSendPromo
                open={!!showModalPromo}
                handleShowModalPromo={handleShowModalPromo}
                row={row}
            />
            }

            <Container fluid>
                <Grid.Row>
                    <Button
                        onClick={() => handleShowModalPromo(undefined)}
                    >
                        Generate Discount Code
                    </Button>
                </Grid.Row>
                {!!props.pageData && !!props.pageData && !!props.pageData.columns && !!props.pageData.data &&
                <Table
                    columns={props.pageData.columns}
                    data={props.pageData.data}
                    handleRefres={props.handleRefres}
                    handleModalUserDetails={handleModalUserDetails}
                    setCell={setCell}
                    setRow={setRow}
                    handleShowModalPromo={handleShowModalPromo}
                />
                }
                {(!(!!props.pageData && !!props.pageData.columns && !!props.pageData.data) || props.isloading) &&
                <Dimmer active={true} page>
                    <Loader/>
                </Dimmer>
                }
                {!!props.pageData && !!props.pageData.columns && !!props.pageData.data &&
                <Container textAlign={"right"} style={{marginTop: "20px"}}>
                    <Pagination
                        firstItem={null}
                        lastItem={null}
                        totalPages={props.LastEvaluatedKey.length + 1}
                        onPageChange={(e, data) => {
                            handlePageChange(data.activePage - 2)
                        }}
                    >
                    </Pagination>
                </Container>
                }
            </Container>
        </React.Fragment>
    );

};

export default UserTable;