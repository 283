import React, {useState} from 'react';
import {Button, Container, Dimmer, Form, Loader, Modal, Segment} from "semantic-ui-react";
import {Auth} from "aws-amplify";
import SuccessModal from "../../shared/FormElements/SuccessModal";
import ErrorModal from "../../shared/FormElements/ErrorModal";
import QRCode from "qrcode.react";


const ModalEnableMFA = (props) => {

    const [error, setError] = useState(false);
    const [successModal, setsuccessModal] = useState(false);

    const [otpC1, setotpC1] = useState('');
    const [isLoading, setisLoading] = useState(false);


    const handleConfirmEnableMFA = async (event) => {
        event.preventDefault();
        setisLoading(true);
        Auth.verifyTotpToken(props.user, otpC1).then(() => {
            // don't forget to set TOTP as the preferred MFA method
            Auth.setPreferredMFA(props.user, 'TOTP');
            props.setpreferredMFA('TOTP');
            setisLoading(false);
        }).catch(e => {
            setisLoading(false);
            if (!!e && !!e.message) {
                setError(e.message)
            } else {
                setError(true);
            }
        });
        props.setqrCode('');
    };

    const handleNewPassowrdSet = (e) => {
        setotpC1(e.target.value);
    }

    const handleCloseSuccessModal = () => {
        setsuccessModal(false);
    }

    const handleCloseErrorModal = () => {
        setError(false);
    }

    return (
        <React.Fragment>
            <Modal
                open={!!props.qrCode}
                onClose={() => props.setqrCode('')}
            >
                <Modal.Header>Enable MFA Authentication</Modal.Header>
                <Modal.Content>
                    <Dimmer active={!!isLoading}>
                        <Loader/>
                    </Dimmer>
                    {!!error &&
                    <Segment textAlign={'center'}>
                        <p>{error + '. Try agian in few minutes or contact us.'}</p>
                    </Segment>
                    }
                    <p>
                        We currently supporto MFA only using TOTP-generating app
                        (like Google Authenticator, Microsoft Authenticator).
                    </p>
                    <p>
                        Scan the below code with your TOTP-generating app. And enter the OTP.
                        For any issue contact us at info@filewhey.com.
                    </p>
                    {!!props.qrCode &&
                    <Container textAlign={'center'}>
                        <QRCode value={props.qrCode}/>
                    </Container>
                    }
                    <Form>
                        <Form.Input
                            type='MFACODE1'
                            onChange={handleNewPassowrdSet}
                            fluid
                            label='Enter the First OTP Code'
                            placeholder='OTP COde'
                            id='otp-1'
                            value={otpC1}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => props.setqrCode('')} negative>
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmEnableMFA} positive>
                        Confirm
                    </Button>
                </Modal.Actions>
            </Modal>

            <SuccessModal
                open={successModal}
                title={'MFA Enabled Successfully'}
                handleCloseSuccessModal={handleCloseSuccessModal}
                body={successModal}
                icon={'check'}
            />

            <ErrorModal
                open={!!error}
                handleCloseErrorModal={handleCloseErrorModal}
                body={error}
                title={'An error occurred'}
                icon={'bug'}
            />

        </React.Fragment>
    );

};

export default ModalEnableMFA;