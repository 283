import React, {useEffect, useState} from 'react';
import {Dimmer, Dropdown, Loader} from "semantic-ui-react";
import FileExplorerConstatns from "../../shared/const/consts";
import {useHistory} from "react-router-dom";
import handleDownloadFile from "../../shared/hooks/download-hook";
import {API} from "aws-amplify";
import SuccessNotification from "../../shared/FormElements/SuccessNotification";
import ErrorModal from "../../shared/FormElements/ErrorModal";
import SharedUrlModal from "./SharedUrlModal";


const CheckStatus = [
    {key: 'checkStatus', text: 'Check Status', value: 'checkStatus'},
    {key: 'forcefail', text: 'Force Fail', value: 'forcefail'},
]

const CompletedFileOptions = {
    default:
        [{key: 'download', text: 'Download File', value: 'download'},
            {key: 'move', text: 'Move to your files', value: 'move'},
            {key: 'share', text: 'Share Link', value: 'share'}
        ],
    transcription: [
        {key: 'view', text: 'View/Edit', value: 'view'},
        {
            key: 'transcript_download_docx',
            text: 'Download Transcription & Report',
            value: 'transcript_download_docx'
        },
        {
            key: 'transcript_download_vtt',
            text: 'Download WebVTT subtitles',
            value: 'transcript_download_vtt'
        },
        {
            key: 'transcript_download_srt',
            text: 'Download .srt subtitles',
            value: 'transcript_download_srt'
        },
        {
            key: 'transcript_download_csv',
            text: 'Download transcription as .csv',
            value: 'transcript_download_csv'
        }
    ]
}

const CompletedActions = (props) => {

    const [refreshFileStatus, setrefreshFileStatus] = useState(false);
    const history = useHistory();
    const goToPage = (page) => {
        history.push(page)
    }

    const [thereisactionsToComplete, setthereisactionsToComplete] = useState(false);
    const [completedActions, setcompletedActions] = useState();
    const [showSuccessNot, setshowSuccessNot] = useState('');

    const [error, setError] = useState(false);
    const [showSharedUlrModal, setshowSharedUlrModal] = useState(false);
    const [outputKey, setoutputKey] = useState(null);
    const [isLoading, setisLoading] = useState('');

    const handleCloseSuccNotification = () => {
        setshowSuccessNot('');
    };

    const handleCloseErrorModal = () => {
        setError(false);
        window.location.reload();
    };

    const handleCloseShareModal = () => {
        setshowSharedUlrModal(false);
        setoutputKey(null);
    }

    let reloadTimer;
    useEffect(() => {
        let isMounted = true;
        const refreshDashboarHandeler = () => {
            if (isMounted) {
                clearTimeout(reloadTimer);
                setrefreshFileStatus(!refreshFileStatus);
                if (!!thereisactionsToComplete) {
                    if (props.autoRefresh === 'auto_refresh') {
                        props.setAutoRefresh('refresh_auto')
                    } else {
                        props.setAutoRefresh('auto_refresh')
                    }
                }
            }
        }
        reloadTimer = setTimeout(refreshDashboarHandeler, 10000);
        return () => {
            isMounted = false;
        };
    }, [refreshFileStatus])

    useEffect(() => {
        let checkcompletedactions = false
        let completedActionsList = [];
        for (var i = 0; i < FileExplorerConstatns.allActions.length; i++) {
            if (!!props.fileDetail[FileExplorerConstatns.allActions[i].value]
                && (
                    props.fileDetail[FileExplorerConstatns.allActions[i].value].job_status === 'COMPLETED' ||
                    props.fileDetail[FileExplorerConstatns.allActions[i].value].job_status === 'IN_PROGRESS' ||
                    props.fileDetail[FileExplorerConstatns.allActions[i].value].job_status === 'STARTED' ||
                    props.fileDetail[FileExplorerConstatns.allActions[i].value].job_status === 'QUEUED' ||
                    props.fileDetail[FileExplorerConstatns.allActions[i].value].job_status === 'COMPLETED_WITH_ERROR' ||
                    props.fileDetail[FileExplorerConstatns.allActions[i].value].job_status === 'FAILED'
                )) {
                completedActionsList.push({
                    'job_status': props.fileDetail[FileExplorerConstatns.allActions[i].value].job_status,
                    'action': FileExplorerConstatns.allActions[i].text,
                    'action_id': FileExplorerConstatns.allActions[i].value,
                    'color': props.fileDetail[FileExplorerConstatns.allActions[i].value].job_status === 'COMPLETED' ? 'olive' : 'gray',
                    'output_key': props.fileDetail[FileExplorerConstatns.allActions[i].value].output_key,
                    'output_key_location': props.fileDetail[FileExplorerConstatns.allActions[i].value].output_key_location
                });
                if (props.fileDetail[FileExplorerConstatns.allActions[i].value].job_status !== 'COMPLETED'
                    && props.fileDetail[FileExplorerConstatns.allActions[i].value].job_status !== 'COMPLETED_WITH_ERROR' &&
                    props.fileDetail[FileExplorerConstatns.allActions[i].value].job_status !== 'FAILED' && !checkcompletedactions
                ) {
                    checkcompletedactions = true;
                }
            }
        }
        setthereisactionsToComplete(checkcompletedactions);
        setcompletedActions(completedActionsList);
    }, [props.fileDetail]);


    const moveFileFromPrivateToProtected = async (output_key, action_id) => {
        await API.put(
            'filemgmt',
            '/movefile',
            {
                body: {
                    "oldKey": output_key,
                    "from": "myoutfiles",
                    "to": "myfiles",
                    "id_original_file": props.fileDetail.idfile,
                    "action_id": action_id
                }
            })
            .catch(error => {
                setError(error);
            });
        props.handlerRefreshFiles();
    };

    const handleDownloadFileTranscript = async (output_format) => {
        setisLoading('Generating Files');
        try {
            let fileName = props.filekey.split('/')[props.filekey.split('/').length - 1]
            fileName = fileName.split('.').slice(0, 1).join('.') + '.' + output_format
            const response = await API.post(
                'transcribemgmt',
                '/downloadformat',
                {
                    body: {
                        "operation": 'default',
                        "file_name": props.filekey.split('/')[props.filekey.split('/').length - 1],
                        "output_format": output_format,
                        "force": false
                    },
                    queryStringParameters: {
                        transcribeid: props.fileDetail.idfile,
                    },
                })
            handleDownloadFile(response.download_key, 'myoutfiles', fileName);
        } catch (err) {
            setError(error)
        }
        setisLoading('');
    }


    const handleDropDownActions = async (checkToDo, action) => {
        if (checkToDo.value === 'checkStatus') {
            props.handlerRefresh();
        }
        if (checkToDo.value === 'download') {
            setisLoading('Preparing Download');
            setshowSuccessNot('Download Started');
            await handleDownloadFile(action.output_key, !!action.output_key_location ? action.output_key_location : 'myoutfiles', null);
            setisLoading('');
        }
        if (checkToDo.value === 'move') {
            moveFileFromPrivateToProtected(action.output_key, action.action_id);
        }
        if (checkToDo.value === 'share') {
            setoutputKey({
                key: action.output_key,
                location: !!action.output_key_location ? action.output_key_location : 'myoutfiles'
            });
            setshowSharedUlrModal(true);
        }
        if (checkToDo.value === 'view') {
            if (action.action_id.startsWith('transcription')) {
                goToPage(`/account/transcript-mgmnt/${props.fileDetail.idfile}`);
            } else {
                // TODO open a private file
            }
        }
        if (checkToDo.value === 'transcript_download_docx') {
            handleDownloadFileTranscript('docx')
        }
        if (checkToDo.value === 'transcript_download_vtt') {
            handleDownloadFileTranscript('vtt')
        }
        if (checkToDo.value === 'transcript_download_csv') {
            handleDownloadFileTranscript('csv');
        }
        if (checkToDo.value === 'transcript_download_srt') {
            handleDownloadFileTranscript('srt');
        }

    }

    return (
        <React.Fragment>
            <Dimmer active={!!isLoading} page>
                <Loader> {isLoading} </Loader>
            </Dimmer>
            {!!completedActions && completedActions.length > 0 &&
            <p style={{fontWeight: 'bold'}}> Operation Status: </p>
            }
            {!!completedActions && completedActions.length > 0 &&
            completedActions.map(action => (
                    <React.Fragment>
                        <Dropdown
                            style={{color: action.color}}
                            className='icon'
                            floating
                            button
                            options={action.job_status === 'COMPLETED' ?
                                action.action === 'Transcription' ? CompletedFileOptions['transcription'] : CompletedFileOptions['default']
                                : CheckStatus}
                            text={
                                action.job_status in FileExplorerConstatns.status_mapping ?
                                    (action.action === 'Conversion' && !!props.fileDetail && !!props.fileDetail[action.action_id] && !!props.fileDetail[action.action_id].details && props.fileDetail[action.action_id].details.job_configuration) ? action.action + ' to ' + props.fileDetail[action.action_id].details.job_configuration.to_convert + ' ' + FileExplorerConstatns.status_mapping[action.job_status]
                                        : (action.action === 'Translation' && !!props.fileDetail && !!props.fileDetail[action.action_id] && !!props.fileDetail[action.action_id].details && props.fileDetail[action.action_id].details.job_configuration) ? action.action + ' to ' + props.fileDetail[action.action_id].details.job_configuration.TargetLanguageCode + ' ' + FileExplorerConstatns.status_mapping[action.job_status]
                                        : action.action + ' ' + FileExplorerConstatns.status_mapping[action.job_status]
                                    : action.action + ' ' + action.job_status + ' (sorry, contact us)'
                            }
                            value={''}
                            onChange={(e, data) => handleDropDownActions(data, action)}
                        />
                        {!!props.fileDetail.result && action.action === 'Translation' &&
                        <p> {'Remaining Time: ' + props.fileDetail.result} </p>
                        }
                        <Loader
                            active={action.job_status in FileExplorerConstatns.status_mapping && FileExplorerConstatns.status_mapping[action.job_status] === 'in progress'}
                            inline/>
                        <br/>
                        <br/>
                    </React.Fragment>
                )
            )
            }

            <SuccessNotification
                open={!!showSuccessNot}
                handleClose={handleCloseSuccNotification}
                title={showSuccessNot}
                icon={'check'}
            />

            <ErrorModal
                open={!!error}
                handleCloseErrorModal={handleCloseErrorModal}
                body={"There was an unexpected error when starting the " + 'operation' + ". Plese try again in some minutes."}
                title={'Unexpected Error'}
                icon={'bug'}
            />

            {!!showSharedUlrModal && !!outputKey &&
            <SharedUrlModal
                open={!!showSharedUlrModal && !!outputKey}
                closeModal={handleCloseShareModal}
                outputkey={outputKey}
            />
            }

        </React.Fragment>
    );

};

export default CompletedActions;