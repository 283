import React from 'react';
import AuthCustom from "./Auth";
import {Container} from "semantic-ui-react";

const AuthPage = () => {


    return (
        <Container
            style={{
            marginTop: '8em',
            minHeight: '70vh',
            width: '25rem'
        }}>
            <AuthCustom/>
        </Container>

    );

};

export default AuthPage;