import React, {useContext, useState} from 'react';
import {Button, Dimmer, Divider, Header, Loader, Radio, Segment} from "semantic-ui-react";
import ModalChangePwd from "../ModalChangePassword";
import {Auth} from 'aws-amplify';
import {AuthContext} from "../../../shared/context/auth-context";
import ModalEnableMFA from "../ModalEnableMFA";
import ErrorModal from "../../../shared/FormElements/ErrorModal";

const SecuritySettings = (props) => {

    const [changePwd, setchangePassword] = useState(false);
    const [preferredMFA, setpreferredMFA] = useState(props.preferredMFA);
    const [qrCode, setqrCode] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleChangePwd = () => {
        setchangePassword(!changePwd)
    }
    const auth = useContext(AuthContext);

    const handleSingoutAllDefices = async () => {
        try {
            await Auth.signOut({global: true});
        } catch (error) {
            setError(true);
        }
        auth.logout();
    }

    const handleEnalbeMFA = () => {
        Auth.setupTOTP(props.user).then((code) => {
            const issuer = encodeURI('AWSCognito');
            const str = "otpauth://totp/FileWhey:" + props.username + "?secret=" + code + "&issuer=" + issuer;
            setqrCode(str);
        }).catch(err => {
            if (!!err && !!err.message) {
                setError(err.message);
            } else {
                setError(true);
            }
        });
    }

    const handleMod = (e, data) => {
        if (data.checked === false) {
            // disable MFA
            setIsLoading(true);
            Auth.setPreferredMFA(props.user, 'NOMFA').then(res => {
                setIsLoading(false);
            }).catch(err => {
                setIsLoading(false);
                if (!!err && !!err.message) {
                    setError(true);
                } else {
                    setError(true);
                }
            })
            setpreferredMFA('NOMFA');
        } else {
            // enable MFA
            handleEnalbeMFA();
        }
    };

    const handleCloseErrorModal = () => {
        setError(false);
        window.location.reload();
    }

    return (
        <React.Fragment>

            <Segment>
                <Dimmer active={!!isLoading} page>
                    <Loader> Loading </Loader>
                </Dimmer>
                <Header as="h1">Security</Header>

                <Header as="h3">Password</Header>
                <p style={{fontWeight: 'bold', color: 'darkblue', cursor: 'pointer'}}
                   onClick={() => setchangePassword(true)}>
                    Change Password
                </p>

                <Header as="h3">Two Factor Authentication</Header>
                {!!preferredMFA &&
                <Radio
                    toggle
                    checked={preferredMFA !== "NOMFA"}
                    onClick={handleMod}
                />
                }
                <Divider/>
                <Header as="h3">Devices</Header>

                <Button primary onClick={handleSingoutAllDefices}>
                    Sign out users from all devices
                </Button>

            </Segment>

            <ModalEnableMFA
                user={props.user}
                qrCode={qrCode}
                setqrCode={setqrCode}
                setpreferredMFA={setpreferredMFA}
            />

            <ModalChangePwd
                changePwd={changePwd}
                handleChangePwd={handleChangePwd}
            />

            <ErrorModal
                open={!!error}
                handleCloseErrorModal={handleCloseErrorModal}
                body={error}
                title={'An error occurred'}
                icon={'bug'}
            />

        </React.Fragment>

    );

};

export default SecuritySettings;