import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import common_en from '../src/translations/en/common.json'
import common_it from '../src/translations/it/common.json'
import config from "./aws-exports";
import Amplify from "aws-amplify";
import {isIE} from 'react-device-detect'
import {setChonkyDefaults} from 'chonky';
import {ChonkyIconFA} from 'chonky-icon-fontawesome';
import AppIE from "./AppIE";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-203666367-1');
ReactGA.pageview(window.location.pathname + window.location.search);

setChonkyDefaults({iconComponent: ChonkyIconFA});

i18next.init({
    interpolation: {escapeValue: false},
    lng: 'en',
    resources: {
        en: {
            common: common_en
        },
        it: {
            common: common_it
        }
    }
});


if (config.aws_user_pools_id === 'us-east-2_qo64XD6YI') {
    // svil
    config.oauth.domain = "auth.svil.filewhey.com";
}

Amplify.configure(config);


const MFATypes = {
    SMS: true, // if SMS enabled in your user pool
    TOTP: true, // if TOTP enabled in your user pool
    Optional: true, // if MFA is set to optional in your user pool
}

if (isIE) {
    ReactDOM.render(
        <React.StrictMode>
            <AppIE/>
        </React.StrictMode>,
        document.getElementById('root')
    );
} else {
    ReactDOM.render(
        <React.StrictMode>
            <I18nextProvider i18n={i18next}>
                <App/>
            </I18nextProvider>
        </React.StrictMode>,
        document.getElementById('root')
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();