import React, {useContext, useEffect, useState} from 'react';
import TextEditor from "../Components/TextEditor";
import {AuthContext} from "../../shared/context/auth-context";
import {Dimmer, Grid, Header, Loader} from "semantic-ui-react";
import {API} from "aws-amplify";
import {useParams} from "react-router";
import BreadCrumbNavigator from "../../shared/Breadcrumb/BreadCrumbNavigator";
import AudioVideoPlayer from "../Components/AudioVideoPlayer";
import useWindowDimensions from "../../shared/hooks/useWindowsDimensions";

const videoFormat = ['mp4']

const TranscriptMgmntPage = () => {
    const idfile = useParams().transcribeid;
    const auth = useContext(AuthContext);
    const [isLoading, setisLoading] = useState(false);
    const [transcriptionDetail, settranscriptionDetail] = useState();
    const [current_transcript_edit, setcurrent_transcript_edit] = useState('');

    const {height, width} = useWindowDimensions();

    const [refresh, setrefresh] = useState(false);

    useEffect(() => {
        auth.login();
    }, [auth]);

    useEffect(() => {
        setisLoading(true);
        API.post(
            'filemgmt',
            '/filedetails',
            {
                body: {
                    "idfile": idfile
                },
                queryStringParameters: {
                    transcription_obj: 'true',
                },
            })
            .then(response => {
                if (response) {
                    settranscriptionDetail(response.transcriptiondefault.transcription_text)
                    setisLoading(false);
                }
            })
            .catch(error => {
                setisLoading(false);
            });
        API.post(
            'filemgmt',
            '/getkey',
            {
                body: {
                    "idfile": idfile
                }
            }).then(response => {
            setcurrent_transcript_edit(
                {
                    key: response.key,
                    kind: videoFormat.includes(response.key.split('.')[response.key.split('.').length - 1]) ? 'Video' : 'Audio'
                });
        })

    }, [idfile, refresh]);

    const handleRefresh = () => {
        setrefresh(!refresh);
    }

    return (
        <React.Fragment>

            <BreadCrumbNavigator/>

            {!!transcriptionDetail && transcriptionDetail.formatted && !!current_transcript_edit &&
            <Grid centered stackable>
                <Dimmer active={isLoading} page>
                    <Loader/>
                </Dimmer>
                <Grid.Row style={{padding: width < 768 ? '0em 0.5em' : '0em 2em'}}>
                    <Header as='h3' style={{fontSize: '2em'}} textAlign={'center'}>
                        {'Transcript of ' +
                        current_transcript_edit.key.split('/')[current_transcript_edit.key.split('/').length - 1]}
                    </Header>
                </Grid.Row>
                <Grid.Row style={{padding: width < 768 ? '0em 0.5em' : '0em 2em', marginBottom: '6em'}}>
                    <div>
                        <p style={{fontSize: '1.2em'}}>
                            Review your transcript. If you want you can edit it and download.
                        </p>
                        {!!current_transcript_edit &&
                        <AudioVideoPlayer
                            mod={"audio"}
                            current_transcript_edit={current_transcript_edit}
                        />
                        }
                    </div>
                    <TextEditor
                        initial_text={transcriptionDetail.formatted}
                        transcribeid={idfile}
                        handleRefresh={handleRefresh}
                    />
                </Grid.Row>

            </Grid>
            }
            <Dimmer active={isLoading || !transcriptionDetail} page>
                <Loader/>
            </Dimmer>
        </React.Fragment>
    )

};

export default TranscriptMgmntPage;