import React, {useState} from 'react';
import {Button, Dimmer, Input, Loader, Modal} from 'semantic-ui-react'
import ErrorModal from "../../shared/FormElements/ErrorModal";
import {API} from "aws-amplify";


const ModalCreateNewFolder = (props) => {

    const [error, setError] = useState(false);

    const [newFolderName, setnewFolderName] = useState('');
    const [activedimmer, setactivedimmer] = useState(false);

    const handleNewFolderSet = (e) => {
        setnewFolderName(e.target.value);
    };

    const handleCloseErrorModal = () => {
        setError(false);
        window.location.reload();
    }

    const handleCreateFolder = async () => {
        props.setisLoading(true);
        setactivedimmer(true);
        try {
            await API.post(
                'filemgmt',
                '/createFolder',
                {
                    body: {
                        folder_key: props.newPrefix + newFolderName + '/'
                    }
                })
            props.setrefresh(!props.refresh);
        } catch (err) {
            setError(true);
        }
        setactivedimmer(false);
        props.setisLoading(false);
        props.handlerRefresh();
        props.close(false);
    }

    return (
        <React.Fragment>

            <Modal
                centered={true}
                open={props.open}
                onClose={() => props.close(false)}
            >
                <Modal.Header>Create folder</Modal.Header>
                <Dimmer active={activedimmer}>
                    <Loader/>
                </Dimmer>
                <Modal.Content>
                    <label>Name</label>
                    <br/>
                    <br/>
                    <Input
                        style={{width: "370px"}}
                        id="name"
                        name="name"
                        placeholder='Folder name'
                        onChange={handleNewFolderSet}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button secondary onClick={() => props.close(false)}>Cancel</Button>
                    <Button primary disabled={!newFolderName} onClick={handleCreateFolder}>Create</Button>
                </Modal.Actions>
            </Modal>

            <ErrorModal
                open={!!error}
                handleCloseErrorModal={handleCloseErrorModal}
                body={error}
                title={'An error occurred'}
                icon={'bug'}
            />

        </React.Fragment>
    )

};

export default ModalCreateNewFolder;