import React, {useContext, useEffect, useState} from 'react';
import {
    Dimmer, Grid,
    Loader, Menu,
} from "semantic-ui-react";

import {API, Auth} from "aws-amplify";
import {useHistory} from "react-router-dom";
import ErrorModal from "../../shared/FormElements/ErrorModal";
import {useAuth} from "../../shared/hooks/auth-hook";
import {AuthContext} from "../../shared/context/auth-context";
import AccountSettings from "../Components/SettingsComponent/AccountSettings";
import NotificationSettings from "../Components/SettingsComponent/NotificationSettings";
import SecuritySettings from "../Components/SettingsComponent/SecuritySettings";

const SettingPage = () => {
        const history = useHistory();
        const goToPage = (page) => {
            history.push(page)
        }

        const [accountSettings, setaccountSettings] = useState('');
        const [accountSettingsAuth, setaccountSettingsAuth] = useState('');
        const [error, setError] = useState(false);
        const [activeItem, setactiveItem] = useState('Account');
        const [currentStorage, setcurrentStorage] = useState(0);

        const [refresh, setrefresh] = useState(false);
        const {isLoggedIn} = useAuth();
        const auth = useContext(AuthContext);

        useEffect(() => {
            auth.login();
            const handleGetAuthUserInfo = () => {
                Auth.currentAuthenticatedUser({bypassCache: true}).then(user => {
                        setaccountSettingsAuth(
                            {
                                user: user,
                                username: user.username,
                                user_name: user.attributes.name,
                                email: user.attributes.email,
                                preferredMFA: user.preferredMFA
                            }
                        )
                    }
                ).catch(err => {
                        setError(true);
                    }
                )
            }
            const handlegetUserInfoBackend = () => {
                API.get(
                    'settings',
                    '/account-settings',
                    {})
                    .then(response => {
                        setaccountSettings(
                            response
                        );
                    })
                    .catch(error => {
                        setError(true);
                    });
            }
            handleGetAuthUserInfo();
            handlegetUserInfoBackend();
        }, [refresh]);

        useEffect(() => {
            const handleGetFileDetailsUser = () => {
                API.get(
                    'filemgmt',
                    '/listfiles',
                    {
                        queryStringParameters: {
                            check_storage: true
                        }
                    })
                    .then(response => {
                        setcurrentStorage({
                            'total_size': response.total_size,
                            'storage_limit': response.storage_limit,
                            'percentage_util': (Math.round(response.total_size * 100)) / response.storage_limit
                        })
                    })
                    .catch(error => {
                        setError(true);
                    });
            }
            handleGetFileDetailsUser();
        }, []);


        const handleRefresh = () => {
            setrefresh(!refresh);
        }

        const handleCloseErrorModal = () => {
            setError(false);
            goToPage('/account/my-files');
        }

        return (
            <React.Fragment>

                <Dimmer active={!currentStorage || !accountSettings || !accountSettingsAuth || !isLoggedIn} page>
                    <Loader>Loading</Loader>
                </Dimmer>

                {!!isLoggedIn && !!accountSettings && !!currentStorage && !!accountSettingsAuth &&
                <Grid columns={1} stackable centered>

                    <Grid.Column width={12}>
                        <Menu
                            stackable
                            pointing
                            secondary
                        >
                            <Menu.Item
                                name='Account'
                                active={activeItem === 'Account'}
                                onClick={() => setactiveItem('Account')}
                            />
                            <Menu.Item
                                name='Notifications'
                                active={activeItem === 'Notifications'}
                                onClick={() => setactiveItem('Notifications')}
                            />
                            <Menu.Item
                                name='Security'
                                active={activeItem === 'Security'}
                                onClick={() => setactiveItem('Security')}
                            />
                        </Menu>

                        {activeItem === 'Account' &&
                        <AccountSettings
                            email={accountSettingsAuth.email}
                            user_name={accountSettingsAuth.user_name}
                            username={accountSettingsAuth.username}
                            UserCreateDate={accountSettings.UserCreateDate}
                            StatusEmotional={accountSettings.StatusEmotional}
                            currentStorage={currentStorage}
                            handleRefresh={handleRefresh}
                        />
                        }
                        {activeItem === 'Notifications' &&
                        <NotificationSettings
                            email={accountSettingsAuth.email}
                            notifications={accountSettings.settings.notifications}
                        />
                        }

                        {activeItem === 'Security' &&
                        <SecuritySettings
                            name={accountSettingsAuth.user_name}
                            preferredMFA={accountSettingsAuth.preferredMFA}
                            username={accountSettingsAuth.username}
                            user={accountSettingsAuth.user}
                        />
                        }
                    </Grid.Column>

                </Grid>
                }


                <ErrorModal
                    open={!!error}
                    handleCloseErrorModal={handleCloseErrorModal}
                    body={'An unexpected error occurred! Try again in few minutes.'}
                    title={'Unexpected Error'}
                    icon={'bug'}
                />


            </React.Fragment>
        );


    }
;

export default SettingPage;