import React from "react";
import {useTable} from "react-table";
import {Button, Table as TableS} from 'semantic-ui-react';

const Table = props => {

    let columns = props.columns;
    let data = props.data;


    const {getTableProps, headerGroups, rows, prepareRow} = useTable({
        columns,
        data,
    })

    return (
        <TableS color={"orange"} style={{overflow: 'auto'}}>
            <TableS.Header className={"table_header"}>
                {headerGroups.map(headerGroup => (
                    <TableS.Row {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <TableS.HeaderCell {...column.getHeaderProps()}>
                                <div>
                                    {column.render('Header')}
                                </div>
                            </TableS.HeaderCell>
                        ))}
                    </TableS.Row>
                ))}
            </TableS.Header>
            <TableS.Body>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <TableS.Row {...row.getRowProps()}>
                            {row.cells.map(cell => (
                                <TableS.Cell {...cell.getCellProps()}>
                                    <div>
                                        {cell.value === 'actions_cell_h' ?
                                            <Button
                                                onClick={() => props.handleModalUserDetails(row, cell)}
                                            >
                                                ...
                                            </Button>
                                            : cell.value === 'send_promo' ?
                                                <Button
                                                    onClick={() => props.handleShowModalPromo(row)}
                                                >
                                                    Config&Send
                                                </Button>
                                                : cell.value
                                        }
                                    </div>
                                </TableS.Cell>
                            ))}
                        </TableS.Row>
                    )
                })}
            </TableS.Body>
        </TableS>
    )

};


export default Table;