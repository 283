import React, {useState} from 'react';
import {Button, Dimmer, Header, Icon, Label, Loader, Modal, Progress} from "semantic-ui-react";

import {API} from "aws-amplify";
import {useDrop} from "react-dnd";
import {NativeTypes} from "react-dnd-html5-backend";
import axios from 'axios';
import ErrorModal from "../FormElements/ErrorModal";

const {FILE} = NativeTypes;

const UploadNewImage = (props) => {
    const [isLoading, setisLoading] = useState(false);
    const [showProgress, setshowProgress] = useState(false);
    const [uploadPercentage, setuploadPercentage] = useState(0);
    const [error, setError] = useState(false);

    const [showModalFileTooBig, setshowModalFileTooBig] = useState(false);


    const uploadFileToS3 = async (presignedPostData, file) => {
        const formData = new FormData();

        Object.keys(presignedPostData.fields).forEach(key => {
            formData.append(key, presignedPostData.fields[key]);
        });
        formData.append('file', file);

        setisLoading(false);
        axios.post(presignedPostData.url, formData, {
            onUploadProgress: progressEvent => {
                setshowProgress(true);
                setisLoading(false);
                setuploadPercentage(Math.round((progressEvent.loaded / progressEvent.total) * 100));
            },
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(function (response) {
            setshowProgress(false);
            setisLoading(false);
            props.handlerRefresh();
        }).catch(function (error) {
            setshowProgress(false);
            setisLoading(false);
            setshowModalFileTooBig("File Too Big or Not Enough Space;You can upgrade to " +
                "an higher tire plan or contact us for any issue.");
        });
    };

    const uploadNewImage = async (filesToUpload) => {

        var folderPrefix = props.folderPrefix !== '/' ? props.folderPrefix : '';
        try {
            const presignedurl = await API.post(
                'filemgmt',
                '/createPresignedUrl',
                {
                    body: {
                        object_name: folderPrefix + filesToUpload.file.name,
                        duration: filesToUpload.duration,
                        type: filesToUpload.file.type ? filesToUpload.file.type : 'unknown',
                    },
                    queryStringParameters: {
                        mod: 'upload_file'
                    }
                })
            uploadFileToS3(presignedurl, filesToUpload.file);
        } catch (err) {
            setError(true);
            setisLoading(false);
        }
    };

    const handleNoteImageUpload = async (e) => {
        var files;
        if (!!e.target) {
            files = e.target.files;
        } else {
            files = e.files;
        }
        setisLoading(true);

        for (var i = 0; i < files.length; i++) {
            var mediadurate = 'n/a';
            var file = files[i];
            var type = file.type.split('/')[0];
            if (type === 'video' || type === 'audio') {
                // Get video duration
                var vid = document.createElement(type);
                vid.src = URL.createObjectURL(file);
                // wait for duration to change from NaN to the actual duration
                vid.ondurationchange = function () {
                    mediadurate = this.duration
                    uploadNewImage({
                        'file': file,
                        'duration': mediadurate.toString()
                    });
                };
            } else {
                uploadNewImage({'file': file, 'duration': mediadurate});
            }
        }
        if (!!e && !!e.target) {
            e.target.value = '';
        }
    };

    const [{isOver, canDrop}, drop] = useDrop({
        accept: FILE,
        drop: (item) => {
            handleNoteImageUpload(item)
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    //color: '#fff',

    const handleCloseErrorModal = () => {
        setError(false);
    }

    return (
        <React.Fragment>

            <Dimmer active={!!isLoading} page>
                <Loader> Perpare Uploading </Loader>
            </Dimmer>

            <Modal
                closeIcon
                open={!!showModalFileTooBig}
                onClose={() => setshowModalFileTooBig(false)}
            >
                <Header icon='dont'
                        content={!!showModalFileTooBig && showModalFileTooBig.split(';')[0]}/>
                <Modal.Content>
                    <p>
                        {
                            !!showModalFileTooBig && showModalFileTooBig.split(';').length > 1 ? showModalFileTooBig.split(';')[1] : ''
                        }
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={() => setshowModalFileTooBig(!showModalFileTooBig)}>
                        <Icon name='checkmark'/> OK
                    </Button>
                </Modal.Actions>
            </Modal>

            <Modal
                open={showProgress}
                className={"modal"}
            >
                <Modal.Header>Uploading</Modal.Header>
                <Modal.Content>
                    <Progress
                        color={'green'}
                        percent={uploadPercentage}
                        progress/>
                </Modal.Content>
            </Modal>
            {!!props.showButton &&
            <Label
                as="label"
                basic
                htmlFor="upload"
                floated="left"
            >

                <Button
                    disabled={props.disabled}
                    icon="upload"
                    label={{
                        basic: true,
                        content: 'Upload New File'
                    }}
                    labelPosition="right"
                />
                <input
                    disabled={props.disabled}
                    hidden
                    id="upload"
                    multiple
                    type="file"
                    onChange={(e) => handleNoteImageUpload(e)}
                />
            </Label>
            }
            {!props.showButton &&
            <div>
                <input
                    type='file'
                    id='file'
                    ref={props.inputFile}
                    onChange={(e) => handleNoteImageUpload(e)}
                    style={{display: 'none'}}/>
            </div>
            }
            {!!props.showdnd &&
            <div
                onClick={() => props.inputFile.current.click()}
                ref={drop}
                style={{
                    boxShadow: 'inset rgba(69,123,157,0.5) 0 80px 0',
                    backgroundImage: 'url(./shadow-realm.gif)',
                    lineHeight: '50px',
                    textAlign: 'center',
                    fontSize: '1.2em',
                    marginBottom: 10,
                    borderRadius: 4,
                    height: 50,
                    cursor: 'pointer'
                }}
            >
                <Icon name={'cloud upload'} size={'large'}/>
                {" "}
                {isOver
                    ? canDrop
                        ? "C'mon, drop 'em!"
                        : 'Folders are not allowed!'
                    : 'Upload your Files'}
            </div>
            }

            <ErrorModal
                open={!!error}
                handleCloseErrorModal={handleCloseErrorModal}
                body={'An unexpected error occurred! Try again in few minutes.'}
                title={'Unexpected Error'}
                icon={'bug'}
            />

        </React.Fragment>
    )

};

export default UploadNewImage;