import React, {useState} from 'react';
import {Button, Dimmer, Form, Loader, Modal} from "semantic-ui-react";
import {API, Auth} from "aws-amplify";
import SuccessModal from "../../shared/FormElements/SuccessModal";
import ErrorModal from "../../shared/FormElements/ErrorModal";


const ModalChangeEmail = (props) => {

    const [error, setError] = useState(false);
    const [newEmail, setnewEmail] = useState('');

    const [showAlertNewEmail, setshowAlertNewEmail] = useState(false);
    const [successModal, setsuccessModal] = useState(false);

    const [step, setStep] = useState('change-email');
    const [code, setCode] = useState('');
    const [errorCode, setErrorCode] = useState('');
    const [isLoading, setisLoading] = useState(false);

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const onChangeEmailSubmitted = async () => {
        setisLoading(true);
        const vaildEmail = validateEmail(newEmail);
        if (!newEmail || !vaildEmail) {
            setshowAlertNewEmail("Insert a valid email address");
            setisLoading(false);
            return;
        }

        try {
            const user = await Auth.currentAuthenticatedUser()
            if (user.attributes.email === newEmail && user.attributes.email_verified) {
                setshowAlertNewEmail(`${newEmail} is already your email address`)
                setisLoading(false);
                return;
            }
            let response = await API.put(
                'settings',
                '/change-email',
                {
                    body: {
                        currentEmail: user.attributes.email,
                        newEmail: newEmail,
                        AccessToken: user.signInUserSession.accessToken.jwtToken
                    }
                })
            if (!!response && response !== 'success') {
                props.handleChangeEmail();
                setError(response);
            }
            setStep('confirm-code');
        } catch (e) {
            props.handleChangeEmail();
            if (!!e && !!e.message) {
                setError(e.message)
            } else {
                setError("Unexpected Error")
            }
        }
        setisLoading(false);
    };

    const onConfirmCodeSubmitted = async () => {
        setisLoading(true);
        const user = await Auth.currentAuthenticatedUser()

        try {
            let response = await API.post(
                'settings',
                '/change-email-confirm',
                {
                    body: {
                        code,
                        newEmail: newEmail,
                        AccessToken: user.signInUserSession.accessToken.jwtToken
                    }
                })
            if (!!response && response !== 'success') {
                setErrorCode(response)
            } else {
                if (!!response) {
                    props.handleChangeEmail();
                    setStep('change-email');
                    setsuccessModal(true);
                }

            }
        } catch (e) {
            props.handleChangeEmail();
            if (!!e && !!e.message) {
                setError(e.message)
            } else {
                setError("Unexpected Error")
            }
        }
        setisLoading(false);
    };

    const handleResendConfirmationCode = () => {
        setStep('change-email');
    }

    const handlenewEmailSet = (e) => {
        setshowAlertNewEmail('');
        setnewEmail(e.target.value);
    }

    const handlenewConfirmCode = (e) => {
        setErrorCode('');
        setCode(e.target.value);
    }

    const handleCloseSuccessModal = () => {
        setsuccessModal(false);
    }

    const handleCloseErrorModal = () => {
        setError(false);
    }

    const handleCancel = () => {

        // close modal
        props.handleChangeEmail();
    }

    return (
        <React.Fragment>

            <Modal
                open={props.changeEmail}
                onClose={props.handleChangeEmail}
            >
                <Modal.Header>Change Email</Modal.Header>
                <Modal.Content>
                    <Dimmer active={isLoading}>
                        <Loader/>
                    </Dimmer>
                    <Form>
                        {step === 'change-email' &&
                        <Form.Input
                            onChange={handlenewEmailSet}
                            error={!!showAlertNewEmail ? {
                                content: showAlertNewEmail,
                                pointing: 'below'
                            } : false}
                            fluid
                            label='New Email'
                            placeholder='New Email'
                            id='form-input-new-email'
                            value={newEmail}
                        />
                        }
                        {step === 'confirm-code' &&
                        <React.Fragment>
                            <Form.Input
                                onChange={handlenewConfirmCode}
                                error={!!errorCode ? {
                                    content: errorCode,
                                    pointing: 'below'
                                } : false}
                                label='Confirmation Code'
                                placeholder='Insert the confirmation Code'
                                id='form-input-topt'
                                value={code}
                            />
                            <p style={{
                                marginTop: '1em',
                                fontWeight: 'bold',
                                color: 'darkblue',
                                cursor: 'pointer'
                            }}
                               onClick={handleResendConfirmationCode}
                            >
                                Resend Confirmation Code
                            </p>
                        </React.Fragment>
                        }
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={handleCancel} negative>
                        {'Cancel'}
                    </Button>
                    <Button onClick={
                        step === 'change-email' ?
                            onChangeEmailSubmitted
                            : onConfirmCodeSubmitted}
                            positive>
                        {step === 'change-email' ? 'Verify Email' : 'Confirm'}
                    </Button>
                </Modal.Actions>
            </Modal>

            <SuccessModal
                open={successModal}
                title={'Email Changed Successfully'}
                handleCloseSuccessModal={handleCloseSuccessModal}
                body={successModal}
                icon={'check'}
            />

            <ErrorModal
                open={!!error}
                handleCloseErrorModal={handleCloseErrorModal}
                body={error}
                title={'An error occurred'}
                icon={'bug'}
            />

        </React.Fragment>
    )

};

export default ModalChangeEmail;