import React from 'react';

const OrderCreationPage = () => {

    return (
        <React.Fragment>

        </React.Fragment>
    );

};

export default OrderCreationPage;