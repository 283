import React, {useState} from 'react';
import {useTable} from "react-table";
import DetailDropDown from "./DetailDropDown";
import {AiFillEdit} from 'react-icons/ai';
import {Table as TableS} from 'semantic-ui-react';
import EditFieldOrder from "./EditFieldOrder";

const tasks = [
    {id: 'translation', value: 'translation', text: 'Translation'},
    {id: 'proofreading', value: 'proofreading', text: 'Proofreading'},
    {id: 'transcription', value: 'transcription', text: 'Transcription'},
];

const allowedMod = ["status_h", "notes_h", "status", "amount", "projectName", "payment"]

const TableO = (props) => {

    let columns = props.columns;
    let data = props.data;


    const {getTableProps, headerGroups, rows, prepareRow} = useTable({
        columns,
        data,
    });

    const [modalEditFiled, setModalEditField] = useState(false);
    const [cell, setCell] = useState(null);
    const [row, setRow] = useState(null);

    const handleOpenModalEditField = (cell, row) => {
        setModalEditField(!modalEditFiled)
        setCell(cell);
        setRow(row)
    }

    return (
        <React.Fragment>

            {!!row && !!cell &&
            <EditFieldOrder
                open={!!modalEditFiled}
                close={setModalEditField}
                row={row}
                cell={cell}
                setRow={setRow}
                setCell={setCell}
                handleRefres={props.handleRefres}
            />}

            <div className={"table table-responsive"}>
                <TableS color={"orange"} style={{overflow: 'auto'}}>
                    <TableS.Header className={"table_header"}>
                        {headerGroups.map((headerGroup, index) => (
                            <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, index) => (
                                    <th key={index} {...column.getHeaderProps()}>
                                        <div>
                                            {column.render('Header')}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </TableS.Header>
                    <TableS.Body>
                        {rows.map((row, i) => {
                            prepareRow(row)
                            return (
                                <TableS.Row {...row.getRowProps()}>
                                    {row.cells.map((cell, j) => (

                                        <TableS.Cell {...cell.getCellProps()}>
                                            {String(cell.value) === "..." ? <DetailDropDown row={row}
                                                                                            handleOpenModalEditField={handleOpenModalEditField}/>
                                                : cell.column.id === "status" ? <i> <AiFillEdit
                                                        style={{cursor: "pointer"}}
                                                        onClick={() => handleOpenModalEditField(cell, row)}/> {String(cell.value)}
                                                    </i>
                                                    : String(cell.value).length === 64 ? String(cell.value).slice(String(cell.value).length - 8)
                                                        : cell.column.id === 'amount' ? <i> <AiFillEdit
                                                                style={{cursor: "pointer"}}
                                                                onClick={() => handleOpenModalEditField(cell, row)}/> {row.original.currency + " " + cell.value}
                                                            </i>
                                                            : cell.column.id === 'project_manager' ?
                                                                <i onClick={() => {
                                                                }}>
                                                                    <AiFillEdit
                                                                        style={{cursor: "pointer"}}
                                                                        onClick={() => handleOpenModalEditField(cell, row)}/> {cell.value}
                                                                </i>
                                                                : cell.column.id === 'task' ? tasks.filter(item => item.value === cell.value)[0].text
                                                                    : cell.value === 'Payed' ? "Payed"
                                                                        : allowedMod.includes(cell.column.id) ?
                                                                            <i> <AiFillEdit
                                                                                style={{cursor: "pointer"}}
                                                                                onClick={() => handleOpenModalEditField(cell, row)}/> {cell.value}
                                                                            </i>
                                                                            : cell.value
                                            }
                                        </TableS.Cell>
                                    ))}
                                </TableS.Row>
                            )
                        })}
                    </TableS.Body>
                </TableS>

            </div>
        </React.Fragment>
    );

};
export default TableO;
