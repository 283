import React, {useContext, useEffect, useState} from "react";
import TextEditor from "../TextEditor/TextEditor";
import Sidebar from "../Sidebar/Sidebar";
import {converter} from "../../utils/utils";
import {useEditor} from "../../hooks/hooks";
import {Dimmer, Loader} from "semantic-ui-react";
import "./NoteWehyPage.scss";
import "../../styles/_base.scss";
import "../../styles/_setting.scss";
import ModalMaxLengthReached from "./Components/ModalMaxLengthReached";
import ErrorModal from "../../shared/FormElements/ErrorModal";
import {useHistory} from "react-router-dom";
import {Prompt} from 'react-router';
import {useAuth} from "../../shared/hooks/auth-hook";
import {AuthContext} from "../../shared/context/auth-context";


function NoteWehyPage() {
    const [searchInput, setSearchInput] = useState("");
    const [sidebarIsCollapsed, setSidebarIsCollapsed] = useState(false);
    const editor = useEditor();

    function collapseSidebar() {
        setSidebarIsCollapsed(sidebarIsCollapsed => !sidebarIsCollapsed);
    }

    function getSearchResults() {
        const searchResults = editor.notes.filter(note =>
            converter.toText(note).toLowerCase().includes(searchInput.toLowerCase())
        );
        return searchResults;
    }

    const history = useHistory();
    const goToPage = (page) => {
        history.push(page)
    }

    const handleCloseErrorModal = () => {
        editor.setError(false);
        goToPage('/account/my-files');
    };

    const handleChangeEditorState = (editorState) => {
        editor.setEditorState(editorState)
    }

    const {isLoggedIn} = useAuth();
    const auth = useContext(AuthContext);
    useEffect(() => {
        auth.login();
    }, []);


    return (
        <React.Fragment>
            <Dimmer
                active={!isLoggedIn || !editor.notes || editor.isloading || editor.isChangeVisibility !== 'completed'}
                page>
                <Loader> Loading Notes </Loader>
            </Dimmer>
            {!!isLoggedIn &&
            <React.Fragment>
                <div align={'right'}>
                    <p style={{
                        fontStyle: 'italic',
                        fontWeight: 'bold',
                        marginRight: 10
                    }}>
                        NoteWhey is in beta mode</p>
                </div>


                <div id="app-container">
                    <Sidebar
                        isCollapsed={sidebarIsCollapsed}
                        openNote={editor.openNote}
                        notes={searchInput ? getSearchResults() : editor.notes}
                        searchInput={searchInput}
                        setSearchInput={setSearchInput}
                        currentNoteId={editor.currentNoteId}
                    />

                    {editor.isChangeVisibility === 'completed' &&
                    <TextEditor
                        editorState={editor.editorState}
                        isCollapsed={sidebarIsCollapsed}
                        collapseSidebar={collapseSidebar}
                        toggleBlockType={editor.toggleBlockType}
                        toggleInlineStyle={editor.toggleInlineStyle}
                        onChangeEditorState={handleChangeEditorState}
                        addNote={editor.addNote}
                        deleteNote={editor.deleteNote}
                        disable={editor.notes.length === 0}
                    />
                    }

                    <ModalMaxLengthReached
                        open={!!editor.maxLengthReached}
                        setOpen={editor.setMaxLengthReached}
                    />

                    <ErrorModal
                        open={!!editor.error}
                        handleCloseErrorModal={handleCloseErrorModal}
                        body={editor.error}
                        title={'An error occurred'}
                        icon={'bug'}
                    />

                </div>


                <Prompt
                    message={(location, action) => {
                        if (action === 'PUSH' || action === 'POP') {
                            editor.updateNote(editor.editorState, true, true)
                        }
                        return true
                    }}
                />
            </React.Fragment>
            }
        </React.Fragment>
    );
}

;

export default NoteWehyPage;
