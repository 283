import React, {useContext, useEffect, useState} from 'react';
import {Container, Dimmer, Header, Loader} from "semantic-ui-react";
import BreadCrumbNavigator from "../../shared/Breadcrumb/BreadCrumbNavigator";
import {AuthContext} from "../../shared/context/auth-context";
import FileViewer from "react-file-viewer";
import {useParams} from "react-router-dom";
import {API} from "aws-amplify";
import ReactJson from 'react-json-view';
import renderHTML from 'react-render-html';
import XMLViewer from 'react-xml-viewer';
/*
    react-file-viewer
        Images: png, jpeg, gif, bmp, including 360-degree images
        pdf
        csv
        xslx
        docx
        Video: mp4, webm
        Audio: mp3
*/

const specific_handled = ['json', 'html', 'xml', 'txt']

const ViewFilePage = () => {
    const auth = useContext(AuthContext);
    const [isLoading, setisLoading] = useState(false);
    const [fileurl, setfileurl] = useState();
    const [keysave, setkeysave] = useState();
    const [fileType, setFileType] = useState();
    const idfile = useParams().idfile;


    const [fileObject, setfileObject] = useState('');


    const getjsonobject = async (url) => {
        await fetch(url)
            .then(response => response.json())
            .then((jsonData) => {
                setfileObject(jsonData)
            })
            .catch((error) => {

            })
    }

    const getfileobject = async (url, mode) => {
        await fetch(url)
            .then(response => response.text()
            )
            .then(text => {
                let final_text = text;
                if (mode === 'txt') final_text = text.replace(/(?:\r\n|\r|\n)/g, '\n')
                setfileObject(final_text)
            })
            .catch((error) => {

            })
    }

    useEffect(() => {
        auth.login();
    }, [auth]);


    useEffect(() => {
        setisLoading(true);
        const getMediaUrl = async () => {
            const response = await API.post(
                'filemgmt',
                '/getkey',
                {
                    body: {
                        "idfile": idfile
                    }
                })
            setkeysave(response.key);
            setFileType(response.key.split('.')[response.key.split('.').length - 1].toLowerCase())
            const docurl = await API.post(
                'filemgmt',
                '/createPresignedUrl',
                {
                    body: {
                        location: 'myfiles',
                        key: response.key,
                    },
                    queryStringParameters: {
                        mod: 'download_file'
                    }
                });
            setfileurl(docurl);
            if (response.key.split('.')[response.key.split('.').length - 1].toLowerCase() === 'json') {
                await getjsonobject(docurl);
            }
            if (response.key.split('.')[response.key.split('.').length - 1].toLowerCase() === 'html') {
                await getfileobject(docurl, 'html');
            }
            if (response.key.split('.')[response.key.split('.').length - 1].toLowerCase() === 'xml') {
                await getfileobject(docurl, 'xml');
            }
            if (response.key.split('.')[response.key.split('.').length - 1].toLowerCase() === 'txt') {
                await getfileobject(docurl, 'txt');
            }
            setisLoading(false);
        }
        getMediaUrl();
    }, [idfile]);

    const onError = e => {
        //TODO
    };

    return (
        <React.Fragment>
            <BreadCrumbNavigator/>
            <Dimmer active={isLoading} page>
                <Loader/>
            </Dimmer>

            <Header as='h3'
                    style={{fontSize: '2em'}}
                    textAlign={"center"}
            >
                {!!keysave && keysave.split('/')[keysave.split('/').length - 1]}
            </Header>

            <Container>
                {!!fileurl && !!fileType && !(specific_handled.includes(fileType)) &&
                <FileViewer
                    fileType={fileType}
                    filePath={fileurl}
                    onError={onError}
                />
                }
                {!!fileurl && !!fileType && fileType === 'json' && !!fileObject &&
                <ReactJson
                    src={fileObject}
                />
                }
                {!!fileurl && !!fileType && (fileType === 'html') && !!fileObject &&
                renderHTML(fileObject)
                }
                {!!fileurl && !!fileType && (fileType === 'txt') && !!fileObject &&
                fileObject.split("\n").map((i, key) => (
                    <p key={key}>{i}</p>
                ))
                }
                {!!fileurl && !!fileType && fileType === 'xml' && !!fileObject &&
                <XMLViewer xml={fileObject}/>
                }
            </Container>

        </React.Fragment>
    );
};

export default ViewFilePage;