import React, {useContext, useEffect, useState} from 'react';
import TextEditor from "../Components/ArticleTextEditor";
import {AuthContext} from "../../shared/context/auth-context";
import {Dimmer, Dropdown, Grid, Header, Loader} from "semantic-ui-react";
import BreadCrumbNavigator from "../../shared/Breadcrumb/BreadCrumbNavigator";
import useWindowDimensions from "../../shared/hooks/useWindowsDimensions";
import {API} from "aws-amplify";

const envs = [
    {key: 'svil', value: 'svil', text: 'svil'},
    {key: 'test', value: 'test', text: 'test'},
    {key: 'prod', value: 'prod', text: 'prod'}
]

const BlogCreation = () => {
    const auth = useContext(AuthContext);
    const [isLoading, setisLoading] = useState(false);
    const [error, setError] = useState(false);
    const {height, width} = useWindowDimensions();
    const [refresh, setrefresh] = useState(false);

    const [articlesId, setarticlesId] = useState('');
    const [selecteArticleID, setselecteArticleID] = useState('');
    const [article, setArticle] = useState('');
    const [env, setEnv] = useState('svil');


    useEffect(() => {
        auth.login();
    }, [auth]);


    useEffect(() => {
        setisLoading(true);
        const getArticleId = () => {
            API.post(
                'notewhey',
                '/retrive',
                {
                    body: {
                        'env': 'svil',
                        'type': 'heroarticle',
                    }
                }).then(response => {
                    setisLoading(false);
                    if (response && response.status === 'ko') {
                        console.log("Error retrive article")
                        setError('An unexpected error occurred, try again in few minutes.' +
                            ' Your notes are securely saved, but if the problem persist, contact us');
                    }
                    setarticlesId(response);
                }
            ).catch(err => {
                setisLoading(false);
                setError('A network error occurred. Make sure you are online and try again in few minutes.' +
                    ' Your notes are securely saved. If the problem persist, contact us.');
            })
        }
        getArticleId();
    }, [refresh]);

    const handleRefresh = () => {
        setrefresh(!refresh);
    }

    const handleSelectArticle = (e, data) => {
        setisLoading(true);
        setselecteArticleID(data.value);

        // get full artilc here
        API.post(
            'notewhey',
            '/retrive',
            {
                body: {
                    'env': env,
                    'articleId': data.value,
                    'type': 'heroarticle',
                }
            }).then(response => {
                setisLoading(false);
                if (response && response.status === 'ko') {
                    console.log("Error retrive article")
                    setError('An unexpected error occurred, try again in few minutes.' +
                        ' Your notes are securely saved, but if the problem persist, contact us');
                }
                setArticle(response);
            }
        ).catch(err => {
            setisLoading(false);
            setError('A network error occurred. Make sure you are online and try again in few minutes.' +
                ' Your notes are securely saved. If the problem persist, contact us.');
        })
    }

    return (
        <React.Fragment>

            <BreadCrumbNavigator/>

            <Grid centered stackable>
                <Dimmer active={isLoading} page>
                    <Loader/>
                </Dimmer>
                <Grid.Row style={{padding: width < 768 ? '0em 0.5em' : '0em 2em'}}>
                    <Header as='h3' style={{fontSize: '2em'}} textAlign={'center'}>
                        Blog Article Creation
                    </Header>
                </Grid.Row>
                <Grid.Row>
                    <Dropdown
                        name={"env"}
                        onChange={(e, data) => setEnv(data.value)}
                        options={envs}
                        placeholder={"Select ENV"}
                        value={env}
                        selection
                    />
                    <Dropdown
                        name={"articlelist"}
                        onChange={handleSelectArticle}
                        options={articlesId}
                        placeholder={"Select an article"}
                        value={selecteArticleID}
                        search
                        selection
                    />
                </Grid.Row>

                {!!article &&
                <Grid.Row style={{padding: width < 768 ? '0em 0.5em' : '0em 2em', marginBottom: '6em'}}>
                    <TextEditor
                        env={env}
                        article={article}
                        articleid={articlesId}
                        handleRefresh={handleRefresh}
                    />
                </Grid.Row>}

            </Grid>

            <Dimmer active={!!isLoading} page>
                <Loader/>
            </Dimmer>
        </React.Fragment>
    )

};

export default BlogCreation;