import React from 'react';
import {Button, Header, Icon, Modal} from "semantic-ui-react";


const SuccessModal = (props) => {

    return (
        <Modal
            basic
            onClose={props.handleCloseSuccessModal}
            open={props.open}
            size='small'
        >
            <Header icon>
                <Icon name={props.icon}/>
                {props.title}
            </Header>
            <Modal.Content>
                <p>
                    {props.body}
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button color='green' inverted onClick={props.handleCloseSuccessModal}>
                    <Icon name='checkmark'/> Ok
                </Button>
            </Modal.Actions>
        </Modal>
    );

};

export default SuccessModal;