import React, {useState, useCallback, useEffect} from 'react';
import {API, Auth} from "aws-amplify";


export const useAuth = () => {
    const [isLoggedIn, setisLoggedIn] = useState(false);
    const [userId, setuserId] = useState(false);
    const [credits, setcredits] = useState(null);
    const [sub, setsub] = useState(null);
    const [currentPlan, setcurrentPlan] = useState(null);


    let user
    const handlecredits = async () => {
        try {
            user = await Auth.currentAuthenticatedUser();
            user = user.attributes.name;
            API.get(
                'userinfo',
                '/userinfo',
                {
                    queryStringParameters: {
                        attributes: 'total_credits,current_plan',
                    }
                })
                .then(response => {
                    if (response) {
                        setisLoggedIn(true);
                        setuserId(user);
                        setcredits(response.total_credits)
                        window.localStorage.setItem('temporary_info', JSON.stringify({
                            'credits': response.total_credits,
                            'currentPlan': response.current_plan
                        }))
                        setcurrentPlan(response.current_plan);
                        //setsub(user.attributes.sub);
                    }
                })
                .catch(error => {
                    //TODO modal with error
                });
        } catch {
            //TODO
        }
    }

    const login = useCallback(() => {
        const userInfo = JSON.parse(window.localStorage.getItem('temporary_info'));
        const handleLogin = async () => {
            try {
                user = await Auth.currentAuthenticatedUser();
                if (!!userInfo && userInfo.credits) {
                    setisLoggedIn(true);
                    setuserId(user.attributes.name);
                    setcredits(userInfo.credits);
                    setsub(user.attributes.sub);
                    setcurrentPlan(userInfo.currentPlan);
                } else {
                    await handlecredits();
                }
            } catch {
                setisLoggedIn(false);
            }
        };
        handleLogin();
    }, []);

    useEffect(() => {
        login();
    }, [login]);


    const logout = useCallback(() => {
        setisLoggedIn(false);
        try {
            Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
        window.localStorage.removeItem('notes');
        window.localStorage.removeItem('content');
        window.localStorage.removeItem('temporary_info');
        window.localStorage.removeItem('plans_infos');
    }, []);


    return {isLoggedIn, userId, login, logout, credits, sub, currentPlan};
}