import React from 'react';
import {Button, Header, Modal} from "semantic-ui-react";


const ErrorModal = (props) => {

    return (
        <Modal
            closeIcon
            open={props.open}
            onClose={props.handleCloseErrorModal}
        >
            <Header icon={props.icon} content={props.title}/>
            <Modal.Content>
                <p>
                    {props.body}
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={props.handleCloseErrorModal}>
                    OK
                </Button>
            </Modal.Actions>
        </Modal>
    );

};

export default ErrorModal;