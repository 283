import React, {useState} from 'react';
import {Button, Dimmer, Dropdown, Label, Loader, Modal, Input} from "semantic-ui-react";
import {API} from "aws-amplify";


const currencyList = [
    {"value": "AUD", "key": "AUD", "text": "Australian dollar"},
    {"value": "BRL", "key": "BRL", "text": "Brazilian real"},
    {"value": "CAD", "key": "CAD", "text": "Canadian dollar"},
    {"value": "CNY", "key": "CNY", "text": "Chinese Renmenbi"},
    {"value": "CZK", "key": "CZK", "text": "Czech koruna"},
    {"value": "DKK", "key": "DKK", "text": "Danish krone"},
    {"value": "EUR", "key": "EUR", "text": "Euro"},
    {"value": "HKD", "key": "HKD", "text": "Hong Kong dollar"},
    {"value": "HUF", "key": "HUF", "text": "Hungarian forint"},
    {"value": "ILS", "key": "ILS", "text": "Israeli new shekel"},
    {"value": "MYR", "key": "MYR", "text": "Malaysian ringgit"},
    {"value": "MXN", "key": "MXN", "text": "Mexican peso"},
    {"value": "TWD", "key": "TWD", "text": "New Taiwan dollar"},
    {"value": "NZD", "key": "NZD", "text": "New Zealand dollar"},
    {"value": "NOK", "key": "NOK", "text": "Norwegian krone"},
    {"value": "PHP", "key": "PHP", "text": "Philippine peso"},
    {"value": "PLN", "key": "PLN", "text": "Polish złoty"},
    {"value": "GBP", "key": "GBP", "text": "Pound sterling"},
    {"value": "RUB", "key": "RUB", "text": "Russian ruble"},
    {"value": "SGD", "key": "SGD", "text": "Singapore dollar"},
    {"value": "SEK", "key": "SEK", "text": "Swedish krona"},
    {"value": "CHF", "key": "CHF", "text": "Swiss franc"},
    {"value": "THB", "key": "THB", "text": "Thai baht"},
    {"value": "USD", "key": "USD", "text": "United States dollar"}
];

const kindsOfDiscount = [
    {key: "percent", value: "percent", text: "Percent"},
    {key: "absolute", value: "absolute", text: "Absolute Value"},
]


const ModalSendPromo = (props) => {
    const [isLoading, setisLoading] = useState(false);

    const [amount, setAmount] = useState(10);
    const [dicountKind, setdicountKind] = useState("percent");
    const [currency, setCurrency] = useState("USD");
    const [couponSent, setCouponSent] = useState(false);

    const handleSendPromoToUser = async () => {
        if (!!couponSent) {
            props.handleShowModalPromo(undefined);
        } else {
            try {
                const response = await API.post(
                    'userinfo',
                    '/sendinfo',
                    {
                        body: {
                            infoType: "promo_code",
                            amount: amount,
                            kind: dicountKind,
                            currency: currency,
                            sub: !!props.row ? props.row.original.col_sub : '',
                            send: !!props.row
                        }
                    }
                );
                setCouponSent(response);
            } catch (err) {
                console.log(err);
            }
        }
    }

    const handleChangeKind = (e, data) => {
        setdicountKind(data.value);
    }

    const handleChangeCurrency = (e, data) => {
        setCurrency(data.value)
    }


    const handleChangeAmount = (e) => {
        let value = e.target.value;


        if (!Number(value) && value !== "") {
            return;
        }
        if (value === "") {
            value = 0;
        }
        value = Number(value);

        setAmount(value);
    }

    return (
        <React.Fragment>
            <Modal
                open={props.open}
                centered={true}
                onClose={() => props.handleShowModalPromo(undefined)}
            >
                <Modal.Header>
                    Configure and send promo to user
                </Modal.Header>

                <Modal.Content>
                    <Dimmer active={isLoading}>
                        <Loader/>
                    </Dimmer>
                    {!!couponSent &&
                    <div>
                        <p> {!!props.row
                            ? "Coupon generated: " + couponSent
                            : "Coupon generated and email sent: " + couponSent
                        }</p>
                    </div>
                    }
                    {!couponSent &&
                    <div>
                        <div>
                            <br/>
                            <Label> Value </Label>
                            <Input
                                style={{width: "370px"}}
                                id={"amount"}
                                name={"amount"}
                                placeholder={"Set the Value"}
                                value={amount}
                                onChange={handleChangeAmount}
                            />
                        </div>

                        <div>
                            <br/>
                            <Label> Kind </Label>
                            <Dropdown
                                name={"kind"}
                                onChange={handleChangeKind}
                                options={kindsOfDiscount}
                                placeholder={"Select the kind of discount"}
                                value={dicountKind}
                                search
                                selection
                            />
                        </div>
                        {dicountKind === "absolute" &&
                        <div>
                            <br/>
                            <Label> Currency </Label>
                            <Dropdown
                                name={"kind"}
                                onChange={handleChangeCurrency}
                                options={currencyList}
                                placeholder={"Select the currency"}
                                value={currency}
                                search
                                selection
                            />
                        </div>
                        }
                    </div>
                    }


                </Modal.Content>

                <Modal.Actions>
                    {!couponSent && <Button
                        onClick={() => props.handleShowModalPromo(undefined)}
                        secondary
                    >
                        Cancel
                    </Button>}
                    <Button
                        onClick={handleSendPromoToUser}
                        primary
                        disabled={!amount}
                    >
                        {!!couponSent ? "Close" : !!props.row ? "Generate and Send" : "Generate"}
                    </Button>
                </Modal.Actions>
            </Modal>
        </React.Fragment>
    )

};

export default ModalSendPromo;