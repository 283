import React, {useState} from 'react';
import {Button, Dimmer, Loader, Modal} from "semantic-ui-react";
import {API} from "aws-amplify";


const ModalDeleteKey = (props) => {

    const [activedimmer, setactivedimmer] = useState(false);

    const handleDeleteFile = async (fileKey) => {
        setactivedimmer(true);
        try {
            await API.post(
                'filemgmt',
                '/deletefile',
                {
                    body: {
                        "key": fileKey
                    }
                })
        } catch (err) {

        }
        props.selectedFiles.pop();
        if (props.selectedFiles.length === 0) {
            props.setSelectedFile(false);
            props.setSelectedDir(false);
            props.handlerRefresh();
            setactivedimmer(false);
            props.setrefresh(!props.refresh);
            props.close(false);
        }
    };

    const handleRemoveFileConfirm = async () => {
        props.selectedFiles.map(file => (
                handleDeleteFile(file.id)
            )
        )
    }

    return (
        <Modal
            open={props.open}
            onClose={() => props.close(false)}
        >
            <Modal.Header>File Removal Confirmation</Modal.Header>
            <Modal.Content>
                <Dimmer active={activedimmer}>
                    <Loader/>
                </Dimmer>
                <p>{'Are you sure you want to delete selected file(s) ?'}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => props.close(false)} negative>
                    No
                </Button>
                <Button onClick={handleRemoveFileConfirm} positive>
                    Yes
                </Button>
            </Modal.Actions>
        </Modal>
    );

};

export default ModalDeleteKey;