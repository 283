import React, {useState} from 'react';
import {Button, Form, Icon, Label, Modal, Segment} from "semantic-ui-react";
import {API, Auth} from "aws-amplify";
import {useHistory} from "react-router-dom";
import SuccessModal from "../../shared/FormElements/SuccessModal";
import ErrorModal from "../../shared/FormElements/ErrorModal";


const ModalChangePwd = (props) => {

    const [error, setError] = useState(false);
    const [currentPassword, setcurrentPassword] = useState();
    const [newPassword, setnewPassword] = useState('');
    const [newPasswordC, setnewPasswordC] = useState('');

    const [showAlertCurrentPwd, setshowAlertCurrentPwd] = useState(false);
    const [showAlertNewPwd, setshowAlertNewPwd] = useState(false);
    const [showAlertNewPwdC, setshowAlertNewPwdC] = useState(false);

    const [successModal, setsuccessModal] = useState(false);

    let user;

    const handleConfirmChangePwd = async () => {
        if (newPassword.length < 8 || newPassword !== newPasswordC) {
            if (newPassword.length < 8) {
                setshowAlertNewPwd("Set a password longher thant 8 characters")
            }
            if (newPassword !== newPasswordC) {
                setshowAlertNewPwdC("The password not coincide")
            }
            return;
        }

        user = await Auth.currentAuthenticatedUser();
        API.put(
            'settings',
            '/change-password',
            {
                body: {
                    PreviousPassword: currentPassword,
                    ProposedPassword: newPassword,
                    AccessToken: user.signInUserSession.accessToken.jwtToken
                }
            })
            .then(response => {
                setcurrentPassword('');
                setnewPassword('');
                setnewPasswordC('');
                if (response.result === 'success') {
                    setsuccessModal("Your passwordhave been change successfully.");
                    props.handleChangePwd();
                } else {
                    props.handleChangePwd();
                    setError(response.detail)
                }
            })
            .catch(error => {
                setcurrentPassword('');
                setnewPassword('');
                setnewPasswordC('');
                props.handleChangePwd();
                setError('Unexpected Error');
            });
    };

    const handleOldPassowrdSet = (e) => {
        setshowAlertCurrentPwd(false);
        setcurrentPassword(e.target.value);
    }

    const handleNewPassowrdSet = (e) => {
        setshowAlertNewPwd(false);
        setshowAlertNewPwdC(false);
        setnewPassword(e.target.value);
    }

    const handleConfirmNewPasswordSet = (e) => {
        setshowAlertNewPwdC(false);
        setnewPasswordC(e.target.value)
    }

    const handleCloseSuccessModal = () => {
        setsuccessModal(false);
    }

    const handleCloseErrorModal = () => {
        setError(false);
    }

    return (
        <React.Fragment>
            <Modal
                open={props.changePwd}
                onClose={props.handleChangePwd}
            >
                <Modal.Header>Change Password</Modal.Header>
                <Modal.Content>
                    {!!error &&
                    <Segment textAlign={'center'}>
                        <p>{error + '. Try agian in few minutes or contact us.'}</p>
                    </Segment>
                    }
                    <Form>
                        <Form.Input
                            type='password'
                            onChange={handleOldPassowrdSet}
                            error={!!showAlertCurrentPwd ? {
                                content: showAlertCurrentPwd,
                                pointing: 'below'
                            } : false}
                            fluid
                            label='Current Password'
                            placeholder='Current Password'
                            id='form-input-current-pwd'
                            value={currentPassword}
                        />


                        <Form.Input
                            type='password'
                            onChange={handleNewPassowrdSet}
                            error={!!showAlertNewPwd ? {
                                content: showAlertNewPwd,
                                pointing: 'below'
                            } : false}
                            fluid
                            label='New Password'
                            placeholder='New Password'
                            id='form-input-new-pwd'
                            value={newPassword}
                        />


                        <Form.Input
                            type='password'
                            onChange={handleConfirmNewPasswordSet}
                            error={!!showAlertNewPwdC ? {
                                content: showAlertNewPwdC,
                                pointing: 'below'
                            } : false}
                            fluid
                            label='Confirm New Password'
                            placeholder='New Password'
                            id='form-input-new-pwd-c'
                            value={newPasswordC}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={props.handleChangePwd} negative>
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmChangePwd} positive>
                        Confirm
                    </Button>
                </Modal.Actions>
            </Modal>

            <SuccessModal
                open={successModal}
                title={'Password Changed Successfully'}
                handleCloseSuccessModal={handleCloseSuccessModal}
                body={successModal}
                icon={'check'}
            />

            <ErrorModal
                open={!!error}
                handleCloseErrorModal={handleCloseErrorModal}
                body={error}
                title={'An error occurred'}
                icon={'bug'}
            />

        </React.Fragment>
    );

};

export default ModalChangePwd;