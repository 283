import {useEffect, useState} from "react";
import {EditorState, RichUtils} from "draft-js";
import {converter} from "../utils/utils";
import {v4 as uuid} from "uuid";
import {API} from "aws-amplify";
import {usePageVisibility} from "react-page-visibility";

function useEditor() {
    const [notes, setNotes] = useState([]);
    const [currentNoteId, setCurrentNoteId] = useState(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [isloading, setisloading] = useState(false);
    const [error, setError] = useState(false);
    const [currentBlockwords, setcurrentBlockwords] = useState(0);
    const [maxLengthReached, setMaxLengthReached] = useState(false);
    const [maxTestLenghtCurrent, setmaxTestLenghtCurrent] = useState(0);

    const [reatingNote, setCreatingNote] = useState(false);
    const [isChangeVisibility, setisChangeVisibility] = useState('completed');

    const isVisible = usePageVisibility();

    useEffect(() => {

        if (!!isVisible) {
            const savedNotes = JSON.parse(localStorage.getItem("notes"));
            if (!savedNotes || savedNotes.length === 0) {
                setisloading(true);
                API.post(
                    'notewhey',
                    '/retrive',
                    {
                        body: {}
                    }).then(response => {
                        setisloading(false);
                        if (response && response.status === 'ko') {
                            setError('An unexpected error occurred, try again in few minutes.' +
                                ' Your notes are securely saved, but if the problem persist, contact us');
                        }
                        setNotes(response)
                    }
                ).catch(err => {
                    setisloading(false);
                    setError('A network error occurred. Make sure you are online and try again in few minutes.' +
                        ' Your notes are securely saved. If the problem persist, contact us.');
                })
            } else {
                setNotes(savedNotes); //local storage note
            }
        } else {
            setisChangeVisibility('todo')
        }
    }, [isVisible]);

    useEffect(() => {
        // runs only once, after notes boots up from storage
        if (!currentNoteId && notes.length > 0) {
            setCurrentNoteId(notes[0].id);
            setEditorState(converter.toEditorState(notes[0]));
        } else if (!!currentNoteId && isChangeVisibility === 'todo') {
            setEditorState(converter.toEditorState(notes.filter(note => note.id === currentNoteId)[0]));
        }
    }, [notes, currentNoteId]);


    useEffect(() => {
        if (isChangeVisibility !== 'todo') {
            try {
                localStorage.setItem("notes", JSON.stringify(notes));
            } catch (e) {
                if (e.name === 'QUOTA_EXCEEDED_ERR' || e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
                    setMaxLengthReached(true);
                } else {
                    setError('An unexpected error occurred, try again in few minutes.' +
                        ' Your notes are securely saved, but if the problem persist, contact us');
                }
            }
        }
    }, [notes]);

    useEffect(() => {
        if (notes.length > 0 && isChangeVisibility !== 'todo') {
            updateNote(editorState, false, false);
        } else {
            setisChangeVisibility('completed')
        }
    }, [editorState]);

    const UpdateNotedb = (note, clean) => {
        API.put(
            'notewhey',
            '/update',
            {
                body: {
                    id: note.id,
                    content: note.content
                }
            }).then(res => {
                if (!!res && res.status === 'ok'){
                    if (!!clean) {
                        window.localStorage.removeItem('notes');
                    }
                }
            })
            .catch(
                err => {
                    setError('A network error occurred. Make sure you are online and try again in few minutes.' +
                        ' Your notes are securely saved. If the problem persist, contact us.');
                }
            )
    }

    function updateNote(editorState, force, clean) {
        const updatedNotes = notes.map(note => {
            if (note.id === currentNoteId) {
                note.content = converter.toContent(editorState)
                var currentLenght = editorState.getCurrentContent().getPlainText(' ').length;
                setmaxTestLenghtCurrent(currentLenght);
                if (currentLenght >= 200000 && currentLenght > maxTestLenghtCurrent) {
                    setMaxLengthReached(true);
                }
                if (!reatingNote &&
                    (Math.abs(currentLenght - currentBlockwords) > 50 || !!force)
                ) {
                    setcurrentBlockwords(currentLenght);
                    UpdateNotedb(note, clean);
                }
            }
            return note;
        });
        setNotes(updatedNotes);
    }

    function openNote(id) {
        updateNote(editorState, true, false); // update note in the previous tab
        setCurrentNoteId(id);
        const noteToOpen = notes.find(note => note.id === id);
        setEditorState(converter.toEditorState(noteToOpen));
    }

    function addNote() {
        const newNote = createEmptyNote();
        setNotes([...notes, newNote]);
        setCurrentNoteId(newNote.id);
        setEditorState(converter.toEditorState(newNote));
    }

    function createEmptyNote() {
        const emptyEditorState = RichUtils.toggleBlockType(
            EditorState.createEmpty(),
            "header-one"
        );

        const newNote = {
            id: uuid() + new Date().getTime(),
            content: converter.toContent(emptyEditorState),
        };

        setCreatingNote(true);
        API.post(
            'notewhey',
            '/create',
            {
                body: {
                    id: newNote.id,
                    content: newNote.content
                }
            }).then(res => {
                setCreatingNote(false);
            }
        ).catch(err => {
                setCreatingNote(false);
            }
        )
        return newNote;
    }

    function deleteNote() {
        const updatedNotes = notes.filter(note => note.id !== currentNoteId);
        setCreatingNote(true);
        API.post(
            'notewhey',
            '/delete',
            {
                body: {
                    id: currentNoteId,
                }
            }).then(res => {
                setCreatingNote(false);
            }
        ).catch(err => {
                setCreatingNote(false);
            }
        )
        setNotes(updatedNotes);
        if (updatedNotes.length > 0) {
            setCurrentNoteId(updatedNotes[0].id);
            setEditorState(converter.toEditorState(updatedNotes[0]));
        } else {
            setEditorState(EditorState.createEmpty());
        }
    }

    function toggleBlockType(blockType) {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    }

    function toggleInlineStyle(style) {
        setEditorState(RichUtils.toggleInlineStyle(editorState, style));
    }

    return {
        isChangeVisibility,
        error,
        setError,
        isloading,
        maxLengthReached,
        setMaxLengthReached,
        notes,
        currentNoteId,
        editorState,
        setEditorState,
        addNote,
        openNote,
        deleteNote,
        toggleBlockType,
        toggleInlineStyle,
        updateNote,
    };
}

export {useEditor};
