import React, {useContext, useState} from 'react';
import {Button, Modal} from "semantic-ui-react";
import {API, Auth} from "aws-amplify";
import {AuthContext} from "../../shared/context/auth-context";
import {useHistory} from "react-router-dom";

const ModalRemoveAccount = (props) => {

    const [error, setError] = useState(false);

    const history = useHistory();

    const goToPage = (page) => {
        history.push(page)
    }

    const auth = useContext(AuthContext);
    const logout = async () => {
        try {
            auth.logout();
            goToPage("/");
        } catch (error) {
            //TODO
        }
    };

    const handleconfirmAccountRemve = () => {
        Auth.currentSession().then(res => {
            API.post(
                'settings',
                '/remove-account',
                {
                    body: {
                        AccessToken: res.getAccessToken().getJwtToken()
                    }
                })
                .then(response => {
                    if (response.result === 'success') {
                        logout();
                    } else {
                        // TODO
                    }
                })
                .catch(error => {
                    setError('Unexpected Error');
                });
        })
    }

    return (
        <Modal
            open={props.removeAccount}
            onClose={props.handleRemoveAccount}
        >
            <Modal.Header>Account Removal Confirmation</Modal.Header>
            <Modal.Content>
                <p>Are you sure you want to delete your account?</p>
                <p>
                    If you proceed, your account will be immediately deleted, and it will not be possible to
                    restore it. Click "yes" if you want to proceed.
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={props.handleRemoveAccount} negative>
                    No
                </Button>
                <Button onClick={handleconfirmAccountRemve} positive>
                    Yes
                </Button>
            </Modal.Actions>
        </Modal>
    )
};

export default ModalRemoveAccount;