import React, {useState, useContext} from 'react';
import {Button, Dropdown, Menu} from "semantic-ui-react";
import {useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {AuthContext} from "../context/auth-context";
import {Auth} from "aws-amplify";
import Logo from "../../static/images/templogo.png";

const MenuLinks = props => {
        const auth = useContext(AuthContext);
        const location = useLocation();
        const history = useHistory();

        const goToPage = (page) => {
            history.push(page)
        }

        const {t, i18n} = useTranslation('common');

        const [selectDisabled, setselectDisabled] = useState(false);


        const handelProductDropDownSelect = () => {
            setselectDisabled(true);
        }
        const handelProductDropDownClose = () => {
            setselectDisabled(false);
        }

        let dropDownClassName;
        if (props.mobile) {
            dropDownClassName = 'link item right';
        } else {
            dropDownClassName = 'link item';
        }

        const logout = async () => {
            try {
                await Auth.signOut();
                auth.logout();
                goToPage("/");
            } catch (error) {
                //TODO
            }
        };


        return (
            <React.Fragment>

                <React.Fragment>

                    <img
                        style={{marginLeft: 15, height: '50px', width: '50px'}}
                        src={Logo}
                        onClick={() => goToPage(!auth.isLoggedIn ? "/" : "/account/my-files")}
                    />


                    <Menu.Item as='a'
                               active={!!location && location.pathname && location.pathname === "/" && !selectDisabled}
                               onClick={() => goToPage(!auth.isLoggedIn ? "/" : "/account/my-files")}>
                        Home
                    </Menu.Item>
                    <Dropdown text={"Services"}
                              as='a'
                              onClick={handelProductDropDownSelect}
                              onClose={handelProductDropDownClose}
                              className={dropDownClassName}>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                active={!!location && location.pathname && location.pathname === "/services/transcription" && !selectDisabled}
                                onClick={() => goToPage("/services/transcription")}
                            >
                                Transciptions
                            </Dropdown.Item>
                            <Dropdown.Item
                                active={!!location && location.pathname && location.pathname === "/services/translation" && !selectDisabled}
                                onClick={() => goToPage("/services/translation")}
                            >
                                Translations
                            </Dropdown.Item>
                            {/*<Dropdown.Header> Files </Dropdown.Header>*/}
                            <Dropdown.Item
                                active={!!location && location.pathname && location.pathname === "/services/texttospeech" && !selectDisabled}
                                onClick={() => goToPage("/services/text-extraction")}
                            >
                                Text Extraction
                            </Dropdown.Item>
                            <Dropdown.Item
                                active={!!location && location.pathname && location.pathname === "/services/translation" && !selectDisabled}
                                onClick={() => goToPage("/services/file-conversion")}
                            >
                                File Conversion (& PDF OCR)
                            </Dropdown.Item>
                            <Dropdown.Item
                                active={!!location && location.pathname && location.pathname === "/services/translation" && !selectDisabled}
                                onClick={() => goToPage("/services/text-to-speech")}
                            >
                                Text to Speech
                            </Dropdown.Item>
                            {/*<Dropdown.Item*/}
                            {/*    active={!!location && location.pathname && location.pathname === "/services/translation" && !selectDisabled}*/}
                            {/*    onClick={() => goToPage("/services/storage")}*/}
                            {/*>*/}
                            {/*    Encrypted Storage*/}
                            {/*</Dropdown.Item>*/}
                            {/*<Dropdown.Item*/}
                            {/*    active={!!location && location.pathname && location.pathname === "/services/translation" && !selectDisabled}*/}
                            {/*    onClick={() => goToPage("/services/translation")}*/}
                            {/*>*/}
                            {/*    Images Object Detection // TODO*/}
                            {/*</Dropdown.Item>*/}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Menu.Item as='a'
                               onClick={() => goToPage("/pricing")}
                               active={!!location && location.pathname && location.pathname.includes("pricing") && !selectDisabled}>
                        {"Pricing"}
                    </Menu.Item>
                    <Menu.Item as='a'
                               onClick={() => goToPage("/contacts")}
                               active={!!location && location.pathname && location.pathname.includes("contacts") && !selectDisabled}>
                        {"Contacts"}
                    </Menu.Item>
                </React.Fragment>

                {!auth.isLoggedIn &&
                <Menu.Item position='right'>
                    <Button as='a' primary onClick={() => goToPage("/auth")}>
                        {"Login / Signup"}
                    </Button>
                </Menu.Item>
                }
                {auth.isLoggedIn &&
                <React.Fragment>
                    <Dropdown text={`Credits: ${auth.credits}`}
                              as='a'
                              pointing={props.mobile ?? "right"}
                              direction={props.mobile ?? "left"}
                              onClick={handelProductDropDownSelect}
                              onClose={handelProductDropDownClose}
                              className='link item right'
                    >
                        <Dropdown.Menu>
                            <Dropdown.Header> Buy </Dropdown.Header>
                            <Dropdown.Item
                                active={!!location && location.pathname && location.pathname === "/buy" && !selectDisabled}
                                onClick={() => goToPage("/buymorecredits")}
                            >
                                Buy More Credits
                            </Dropdown.Item>
                            <Dropdown.Item
                                active={!!location && location.pathname && location.pathname === "/services/transcription" && !selectDisabled}
                                onClick={() => goToPage("/pricing")}
                            >
                                Premium Plans
                            </Dropdown.Item>
                            {/*<Dropdown.Header> Earn </Dropdown.Header>*/}
                            {/*<Dropdown.Item*/}
                            {/*    active={!!location && location.pathname && location.pathname === "/services/transcription" && !selectDisabled}*/}
                            {/*    onClick={() => goToPage("/account/my-files")}*/}
                            {/*>*/}
                            {/*    Invite A Friend*/}
                            {/*</Dropdown.Item>*/}
                        </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown text={auth.userId}
                              as='a'
                              pointing={props.mobile ?? "right"}
                              direction={props.mobile ?? "left"}
                              onClick={handelProductDropDownSelect}
                              onClose={handelProductDropDownClose}
                              className='link item right'
                    >
                        <Dropdown.Menu>
                            <Dropdown.Header> Services </Dropdown.Header>
                            <Dropdown.Item
                                active={!!location && location.pathname && location.pathname === "/services/transcription" && !selectDisabled}
                                onClick={() => goToPage("/account/my-files")}
                            >
                                My Files
                            </Dropdown.Item>
                            <Dropdown.Item
                                active={!!location && location.pathname && location.pathname === "/services/transcription" && !selectDisabled}
                                onClick={() => goToPage("/account/notewhey")}
                            >
                                My Notes
                            </Dropdown.Item>
                            <Dropdown.Item
                                active={!!location && location.pathname && location.pathname === "/services/translation" && !selectDisabled}
                                onClick={() => goToPage("/account/op-history")}
                            >
                                Operations History
                            </Dropdown.Item>
                            <Dropdown.Item
                                active={!!location && location.pathname && location.pathname === "/services/translation" && !selectDisabled}
                                onClick={() => goToPage("/account/pay-history")}
                            >
                                Payments History
                            </Dropdown.Item>
                            <Dropdown.Item
                                active={!!location && location.pathname && location.pathname === "/services/translation" && !selectDisabled}
                                onClick={() => goToPage("/account/settings")}
                            >
                                Settings
                            </Dropdown.Item>
                            <Dropdown.Header> User </Dropdown.Header>
                            <Dropdown.Item
                                onClick={() => logout()}
                            >
                                Logout
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </React.Fragment>
                }
            </React.Fragment>
        )
    }
;

export default MenuLinks;