import React, {useState} from "react";
import {Button, Container, Dimmer, Dropdown, Header, Icon, Input, Loader, Modal, Popup} from "semantic-ui-react";
import {API} from "aws-amplify";
import ErrorModal from "../../shared/FormElements/ErrorModal";
import {CopyToClipboard} from 'react-copy-to-clipboard';

const possibilites = [
    {key: false, value: false, text: 'Never'},
    {key: true, value: true, text: 'Set Expiration'}
]

const SharedUrlModal = (props) => {

    const [error, setError] = useState(false);

    const [sharedLink, setsharedLink] = useState(null);
    const [expiration, setexpiration] = useState(false);
    const [expirationInH, setexpirationInH] = useState(1);
    const [linkcopied, setlinkcopied] = useState(false);
    const [isLoading, setisLoading] = useState(false);


    const handleShare = async () => {
        setisLoading(true);
        try {
            const presignedurl = await API.post(
                'filemgmt',
                '/createPresignedUrl',
                {
                    body: {
                        location: props.outputkey.location,
                        key: props.outputkey.key,
                        expiration: !!expiration ? expirationInH : null,
                        type: ''
                    },
                    queryStringParameters: {
                        mod: 'share_file'
                    }
                })
            setsharedLink(presignedurl);
        } catch (err) {
            setError(true);
        }
        setisLoading(false);
    };

    const handleCloseErrorModal = () => {
        setError(false);
    };

    const handleChangeExpirationTime = (e, data) => {
        setexpiration(data.value);
    }

    const handleexpirationInH = (e, data) => {
        setexpirationInH(data.value);
    }

    const handleGenerateLink = (e, data) => {
        handleShare();
    }

    return (
        <React.Fragment>
            <Modal
                closeIcon
                open={props.open}
                onClose={props.closeModal}
                size={'tiny'}
            >
                <Header icon='share' content='Shared Link'/>
                <Modal.Content>
                    <Container>
                        <Dimmer active={isLoading}>
                            <Loader> Generating Link </Loader>
                        </Dimmer>
                        <p> {!!props.outputkey.key && 'File: ' + props.outputkey.key}</p>

                        <b> Expiration Time </b>
                        <br/>
                        <Dropdown
                            name={"outputformat"}
                            onChange={handleChangeExpirationTime}
                            options={possibilites}
                            placeholder={"Select the output format"}
                            value={expiration}
                            search
                            selection
                        />
                        <Popup
                            trigger={<Icon circular name='info'/>}
                            content='Set whether the link should expire or not'
                            size='mini'
                        />
                        <br/>
                        <br/>
                        {!!expiration &&
                        <React.Fragment>
                            <b> Set expiration in hours </b>
                            <br/>
                            <Input
                                style={{width: "200px"}}
                                id="expirationh"
                                name="expirationh"
                                placeholder='Expiration in hours'
                                value={expirationInH}
                                onChange={handleexpirationInH}
                            />
                            <Popup
                                trigger={<Icon circular name='info'/>}
                                content='The desired expiration in hours'
                                size='mini'
                            />
                        </React.Fragment>
                        }
                        {!!sharedLink &&
                        <div>
                            <b> Shared Link </b>
                            <br/>
                            <Input
                                style={{width: "200px"}}
                                id="sharedLink"
                                name="sharedLink"
                                placeholder='Shared Link'
                                value={sharedLink}
                            />
                            <CopyToClipboard text={sharedLink}
                                             onCopy={() => setlinkcopied(true)}>
                                <Button secondary> Copy </Button>
                            </CopyToClipboard>
                            {!!linkcopied &&
                            <p style={{color: 'green', fontWeight: 'bold'}}> Link Copied to Clipboard</p>}
                        </div>
                        }
                        {!!props.isDir &&
                        <div>
                            <br/>
                            <p style={{fontStyle: 'italic'}}> Currently sharing folder is not allowed. We will allow
                                this in future.</p>
                        </div>
                        }
                    </Container>
                </Modal.Content>
                <Modal.Actions>
                    {!sharedLink &&
                    <Button primary disabled={props.isDir}
                            onClick={handleGenerateLink}>
                        Generate Link
                    </Button>
                    }
                    {!!sharedLink &&
                    <Button primary onClick={props.closeModal}>
                        Close
                    </Button>
                    }
                </Modal.Actions>
            </Modal>

            <ErrorModal
                open={!!error}
                handleCloseErrorModal={handleCloseErrorModal}
                body={"There was an unexpected error when starting the " + 'operation' + ". Plese try again in some minutes."}
                title={'Unexpected Error'}
                icon={'bug'}
            />
        </React.Fragment>
    );

};

export default SharedUrlModal;