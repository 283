import React from 'react';
import {Breadcrumb, Button} from 'semantic-ui-react';
import {Link, useHistory, useLocation} from 'react-router-dom';

import './BreadCrumbNavigator.css';
import {IoChevronBackSharp} from "react-icons/io5";


const breadcrumbNameMap = {
    'account': 'Account',
    'pricing': 'Pricing',
    'contacts': 'Contact Us',
    'translation': 'Traduzioni',
    'transcription': 'Trascrizioni',
    'faq': 'FAQ',
    'buy': 'Buy',
    'my-files': 'My Files',
    'op-history': 'Operations History',
    'transcript-mgmnt': 'Trascriptions',
    'temp_intern': 'Temperatura Interna'
};

const getBradCrumbItem = (value, index, pathnames) => {
    if (!!breadcrumbNameMap[value]) {
        return breadcrumbNameMap[value]
    } else {
        return "";
    }
}


const BradCrumbNavigator = (props) => {

    const history = useHistory();
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);

    const goBack = () => {
        history.goBack();
    }

    return (

        <div className={"bradcrumb"}>
            {!props.onlyButton &&
            <Breadcrumb aria-label="breadcrumb">
                <Button onClick={goBack}>
                        <IoChevronBackSharp size={20}/>
                </Button>
                {/*{pathnames.map((value, index) => {*/}
                {/*    const last = index === pathnames.length - 1;*/}
                {/*    const to = `/${pathnames.slice(0, index + 1).join('/')}`;*/}

                {/*    return (*/}
                {/*        !!getBradCrumbItem(value, index, pathnames) ?*/}
                {/*            last ? (*/}
                {/*                <Breadcrumb.Section active key={to}>*/}
                {/*                    {getBradCrumbItem(value, index, pathnames)}*/}
                {/*                </Breadcrumb.Section>*/}
                {/*            ) : (*/}
                {/*                <React.Fragment>*/}
                {/*                    <Breadcrumb.Section link key={to}>*/}
                {/*                        <Link color="inherit" to={to} key={to}>*/}
                {/*                            {getBradCrumbItem(value, index, pathnames)}*/}
                {/*                        </Link>*/}
                {/*                    </Breadcrumb.Section>*/}
                {/*                    <Breadcrumb.Divider icon='right angle'/>*/}
                {/*                </React.Fragment>*/}
                {/*            ) : ""*/}

                {/*    );*/}
                {/*})}*/}
            </Breadcrumb>
            }
            {!!props.onlyButton &&
            <div>
                <Button onClick={goBack}>
                    <IoChevronBackSharp className={"icon-align_b_detail"} size={20}/>
                </Button>
            </div>
            }
        </div>
    );
}

export default BradCrumbNavigator;