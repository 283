import React, {useState} from 'react';
import {Button, Dimmer, Input, Loader, Modal} from 'semantic-ui-react'
import ErrorModal from "../../shared/FormElements/ErrorModal";
import {API, Storage} from "aws-amplify";


const ModalRenameKey = (props) => {

    const [error, setError] = useState(false);

    const [activedimmer, setactivedimmer] = useState(false);

    const [newName, setnewName] = useState(!props.keytorename.isDir ?
        props.keytorename.name.includes('tar.') ?
            props.keytorename.name.split('.').slice(0, -2).join('.') :
            props.keytorename.name.split('.').slice(0, -1).join('.') :
        props.keytorename.name
    )

    const handleNewFolderSet = (e) => {
        setnewName(e.target.value);
    };

    const handleCloseErrorModal = () => {
        setError(false);
        window.location.reload();
    }

    const handleRenameFile = async () => {
        var splits = '';
        var prefix = '';
        var extension = '';
        var oldKey = ''
        if (!props.keytorename.isDir) {
            splits = props.keytorename.key.split('/')
            prefix = splits.slice(0, splits.length - 1).join('/')
            extension = '.' + props.keytorename.extension
            oldKey = props.keytorename.key
        } else {
            splits = props.keytorename.id.split('/')
            prefix = splits.slice(0, splits.length - 2).join('/')
            extension = '/'
            oldKey = props.keytorename.id
        }
        if (prefix) prefix = prefix + '/'
        props.setisLoading(true);
        setactivedimmer(true);
        let newKeySecure = prefix + newName + extension;
        await API.put(
            'filemgmt',
            '/movefile',
            {
                body: {
                    "newKey": newKeySecure[0] === '/' ? newKeySecure.slice(1,) : newKeySecure,
                    "oldKey": oldKey,
                    "from": "myfiles",
                    "to": "myfiles",
                    "isDir": !!props.keytorename.isDir ? props.keytorename.isDir : false
                },
                headers: {'Content-Type': 'application/json'}
            })
            .catch(error => {
                setError(true);
            });
        setactivedimmer(false);
        props.setisLoading(false);
        props.handlerRefresh();
        props.close(false);
    };

    return (
        <React.Fragment>
            <Modal
                centered={true}
                size={'small'}
                open={props.open}
                onClose={() => props.close(false)}
            >
                <Modal.Header>Rename</Modal.Header>
                <Modal.Content>
                    <Dimmer active={activedimmer}>
                        <Loader/>
                    </Dimmer>
                    <label>New Name</label>
                    <br/>
                    <br/>
                    <Input
                        style={{width: "370px"}}
                        id="name"
                        name="name"
                        placeholder={props.keytorename.name.split('.').slice(0, -1).join('.')}
                        value={newName}
                        onChange={handleNewFolderSet}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button secondary onClick={() => props.close(false)}>Cancel</Button>
                    <Button primary disabled={!newName} onClick={handleRenameFile}>Rename</Button>
                </Modal.Actions>
            </Modal>

            <ErrorModal
                open={!!error}
                handleCloseErrorModal={handleCloseErrorModal}
                body={error}
                title={'An error occurred'}
                icon={'bug'}
            />


        </React.Fragment>
    )

};

export default ModalRenameKey;