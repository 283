import React, {useEffect, useState} from 'react';
import ErrorModal from "../shared/FormElements/ErrorModal";
import {Button, Container, Grid} from "semantic-ui-react";
import {API} from "aws-amplify";
import {BiRefresh} from "react-icons/bi";
import UserTable from "./UserTable";

const HeroUsersPage = (props) => {

    const [isloading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const [currentPage, setCurrentPage] = useState(-1);
    const [pageData, setpageData] = useState(null);
    const [LastEvaluatedKey, setLastEvaluatedKey] = useState([]);
    const [lastPage, setLastPage] = useState(-2);
    const [itemPerPage, setitemPerPage] = useState(25);
    const [refresh, setrefresh] = useState(false);
    const [userStatus, setuserStatus] = useState('');

    useEffect(() => {
        setIsLoading(true);
        let arrLastKyes = LastEvaluatedKey;
        const retriveUsers = async () => {
            try {
                const response = await API.post(
                    'userinfo',
                    '/herouserlist',
                    {
                        body: {
                            LastEvaluatedKey: !!LastEvaluatedKey && currentPage !== -1 ? LastEvaluatedKey[currentPage] : null,
                            itemPerPage: itemPerPage,
                            status: userStatus
                        }
                    }
                );
                setpageData(response.currentPageItems)
                if (!!response.LastEvaluatedKey && !LastEvaluatedKey[currentPage + 1]) {
                    arrLastKyes.push(response.LastEvaluatedKey);
                    setLastEvaluatedKey(arrLastKyes);
                }
                if (!response.LastEvaluatedKey) {
                    setLastPage(currentPage);
                }
                setIsLoading(false);
            } catch (err) {
                console.log(err);
                setError(true);
            }
        };

        retriveUsers();

    }, [currentPage, itemPerPage, refresh, userStatus]);

    const handleRefresh = () => {
        setrefresh(!refresh)
    }


    return (
        <React.Fragment>

            {!!error &&
            <ErrorModal
                open={!!error}
                setisOpenModal={setError}
                handleCloseErrorModal={() => (setError(false))}
                errorMessage={"unexpected error"}
            />
            }


            <Container>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <h2>Hero Users <BiRefresh style={{cursor: "pointer"}} onClick={handleRefresh}/></h2>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <UserTable
                            isloading={isloading}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            pageData={pageData}
                            setpageData={setpageData}
                            LastEvaluatedKey={LastEvaluatedKey}
                            setLastEvaluatedKey={setLastEvaluatedKey}
                            lastPage={lastPage}
                            setLastPage={setLastPage}
                            itemPerPage={itemPerPage}
                            setitemPerPage={setitemPerPage}
                            handleRefres={handleRefresh}
                            userStatus={userStatus}
                            setuserStatus={setuserStatus}
                        />
                    </Grid.Row>
                </Grid>
            </Container>

        </React.Fragment>
    );

};

export default HeroUsersPage;