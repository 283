const languages = [
    {
        "value": "af",
        "alpha_2": "af",
        "text": "Afrikaans"
    },
    {
        "value": "sq",
        "alpha_2": "sq",
        "text": "Albanian"
    },
    {
        "value": "am",
        "alpha_2": "am",
        "text": "Amharic"
    },
    {
        "value": "ar",
        "alpha_2": "ar",
        "text": "Arabic"
    },
    {
        "value": "hy",
        "alpha_2": "hy",
        "text": "Armenian"
    },
    {
        "value": "az",
        "alpha_2": "az",
        "text": "Azerbaijani"
    },
    {
        "value": "bn",
        "alpha_2": "bn",
        "text": "Bengali"
    },
    {
        "value": "bs",
        "alpha_2": "bs",
        "text": "Bosnian"
    },
    {
        "value": "bg",
        "alpha_2": "bg",
        "text": "Bulgarian"
    },
    {
        "value": "ca",
        "alpha_2": "ca",
        "text": "Catalan"
    },
    {
        "value": "zh",
        "alpha_2": "zh",
        "text": "Chinese (Simplified)"
    },
    {
        "value": "zh-TW",
        "alpha_2": "zh-TW",
        "text": "Chinese (Traditional)"
    },
    {
        "value": "hr",
        "alpha_2": "hr",
        "text": "Croatian"
    },
    {
        "value": "cs",
        "alpha_2": "cs",
        "text": "Czech"
    },
    {
        "value": "da",
        "alpha_2": "da",
        "text": "Danish"
    },
    {
        "value": "fa-AF",
        "alpha_2": "fa-AF",
        "text": "Dari"
    },
    {
        "value": "nl",
        "alpha_2": "nl",
        "text": "Dutch"
    },
    {
        "value": "en",
        "alpha_2": "en",
        "text": "English"
    },
    {
        "value": "en-US",
        "alpha_2": "en-US",
        "text": "English (US)"
    },
    {
        "value": "en-GB",
        "alpha_2": "en-GB",
        "text": "English (UK)"
    },
    {
        "value": "et",
        "alpha_2": "et",
        "text": "Estonian"
    },
    {
        "value": "fi",
        "alpha_2": "fi",
        "text": "Finnish"
    },
    {
        "value": "fr",
        "alpha_2": "fr",
        "text": "French"
    },
    {
        "value": "fr-CA",
        "alpha_2": "fr-CA",
        "text": "French (Canada)"
    },
    {
        "value": "ka",
        "alpha_2": "ka",
        "text": "Georgian"
    },
    {
        "value": "de",
        "alpha_2": "de",
        "text": "German"
    },
    {
        "value": "el",
        "alpha_2": "el",
        "text": "Greek"
    },
    {
        "value": "gu",
        "alpha_2": "gu",
        "text": "Gujarati"
    },
    {
        "value": "ht",
        "alpha_2": "ht",
        "text": "Haitian Creole"
    },
    {
        "value": "ha",
        "alpha_2": "ha",
        "text": "Hausa"
    },
    {
        "value": "he",
        "alpha_2": "he",
        "text": "Hebrew"
    },
    {
        "value": "hi",
        "alpha_2": "hi",
        "text": "Hindi"
    },
    {
        "value": "hu",
        "alpha_2": "hu",
        "text": "Hungarian"
    },
    {
        "value": "is",
        "alpha_2": "is",
        "text": "Icelandic"
    },
    {
        "value": "alpha_2",
        "alpha_2": "id",
        "text": "Indonesian"
    },
    {
        "value": "it",
        "alpha_2": "it",
        "text": "Italian"
    },
    {
        "value": "ja",
        "alpha_2": "ja",
        "text": "Japanese"
    },
    {
        "value": "kn",
        "alpha_2": "kn",
        "text": "Kannada"
    },
    {
        "value": "kk",
        "alpha_2": "kk",
        "text": "Kazakh"
    },
    {
        "value": "ko",
        "alpha_2": "ko",
        "text": "Korean"
    },
    {
        "value": "lv",
        "alpha_2": "lv",
        "text": "Latvian"
    },
    {
        "value": "lt",
        "alpha_2": "lt",
        "text": "Lithuanian"
    },
    {
        "value": "mk",
        "alpha_2": "mk",
        "text": "Macedonian"
    },
    {
        "value": "ms",
        "alpha_2": "ms",
        "text": "Malay"
    },
    {
        "value": "ml",
        "alpha_2": "ml",
        "text": "Malayalam"
    },
    {
        "value": "mt",
        "alpha_2": "mt",
        "text": "Maltese"
    },
    {
        "value": "mn",
        "alpha_2": "mn",
        "text": "Mongolian"
    },
    {
        "value": "no",
        "alpha_2": "no",
        "text": "Norwegian"
    },
    {
        "value": "fa",
        "alpha_2": "fa",
        "text": "Persian"
    },
    {
        "value": "ps",
        "alpha_2": "ps",
        "text": "Pashto"
    },
    {
        "value": "pl",
        "alpha_2": "pl",
        "text": "Polish"
    },
    {
        "value": "pt",
        "alpha_2": "pt",
        "text": "Portuguese"
    },
    {
        "value": "ro",
        "alpha_2": "ro",
        "text": "Romanian"
    },
    {
        "value": "ru",
        "alpha_2": "ru",
        "text": "Russian"
    },
    {
        "value": "sr",
        "alpha_2": "sr",
        "text": "Serbian"
    },
    {
        "value": "si",
        "alpha_2": "si",
        "text": "Sinhala"
    },
    {
        "value": "sk",
        "alpha_2": "sk",
        "text": "Slovak"
    },
    {
        "value": "sl",
        "alpha_2": "sl",
        "text": "Slovenian"
    },
    {
        "value": "so",
        "alpha_2": "so",
        "text": "Somali"
    },
    {
        "value": "es",
        "alpha_2": "es",
        "text": "Spanish"
    },
    {
        "value": "es-MX",
        "alpha_2": "es-MX",
        "text": "Spanish (Mexico)"
    },
    {
        "value": "sw",
        "alpha_2": "sw",
        "text": "Swahili"
    },
    {
        "value": "sv",
        "alpha_2": "sv",
        "text": "Swedish"
    },
    {
        "value": "tl",
        "alpha_2": "tl",
        "text": "Tagalog"
    },
    {
        "value": "ta",
        "alpha_2": "ta",
        "text": "Tamil"
    },
    {
        "value": "te",
        "alpha_2": "te",
        "text": "Telugu"
    },
    {
        "value": "th",
        "alpha_2": "th",
        "text": "Thai"
    },
    {
        "value": "tr",
        "alpha_2": "tr",
        "text": "Turkish"
    },
    {
        "value": "uk",
        "alpha_2": "uk",
        "text": "Ukrainian"
    },
    {
        "value": "ur",
        "alpha_2": "ur",
        "text": "Urdu"
    },
    {
        "value": "uz",
        "alpha_2": "uz",
        "text": "Uzbek"
    },
    {
        "value": "vi",
        "alpha_2": "vi",
        "text": "Vietnamese"
    },
    {
        "value": "cy",
        "alpha_2": "cy",
        "text": "Welsh"
    }
];

export default languages;