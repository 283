import React from 'react';
import {Image, Menu, Sidebar} from 'semantic-ui-react'
import Logo from "../../static/images/templogo.png";
import SideBarHomeMenuItems from "./SideBarHomeMenuItems";

import './SideBarHome.css';
import {useHistory} from "react-router-dom";

const SidebarHome = (props) => {

    const history = useHistory();
    const goToPage = (page) => {
        history.push(page)
    }


    return (
        <React.Fragment>

            <Sidebar
                as={Menu}
                animation='overlay'
                icon='labeled'
                vertical
                inverted
                width={'thin'}
                visible={true}
                className={'custom'}
                color={'pink'}
            >
                <Menu.Item position={'left'} style={{padding: '3em'}}>
                    <Image
                        src={Logo}
                        size={"tiny"}

                    />
                </Menu.Item>
                <SideBarHomeMenuItems/>

            </Sidebar>


        </React.Fragment>
    );

};

export default SidebarHome;