import React, {useEffect, useRef, useState} from 'react'
import "video-react/dist/video-react.css";
import ReactAudioPlayer from 'react-audio-player';
import {Player} from 'video-react';
import {Dimmer, Loader, Radio, Segment} from "semantic-ui-react";
import {API} from "aws-amplify";

const AudioVideoPlayer = (props) => {

    const [video_url, setvideo_url] = useState();
    const [playermod, setplayermod] = useState('audio');
    const [disablplayerchange, setdisablplayerchange] = useState(true);

    useEffect(() => {
        const getMediaUrl = async () => {
            const video_url = await API.post(
                'filemgmt',
                '/createPresignedUrl',
                {
                    body: {
                        location: 'myfiles',
                        key: props.current_transcript_edit.key,
                    },
                    queryStringParameters: {
                        mod: 'download_file'
                    }
                });
            setvideo_url(video_url);
        }
        getMediaUrl();
        setdisablplayerchange(props.current_transcript_edit.kind !== 'Video');
    }, [props.current_transcript_edit]);

    let player;

    if (playermod === "video") {
        player = (
            <Player
                playsInline
                poster="/assets/poster.png"
                src={video_url}
            />
        )
    } else {
        //https://www.npmjs.com/package/react-audio-player
        player = (
            <ReactAudioPlayer
                src={video_url}
                autoPlay={false}
                controls
            />
        )
    }

    const handleMod = (e, data) => {
        if (data.checked === false) {
            setplayermod('audio');
        } else {
            setplayermod('video');
        }
    }

    return (
        <React.Fragment>

            {!!video_url &&
            <Segment>
                <div style={{marginBottom: '0.5em'}}>
                    <Radio toggle disabled={disablplayerchange} label='Video' onClick={handleMod}/>
                </div>
                {player}
            </Segment>
            }
            {!video_url &&
            <Dimmer active>
                <Loader/>
            </Dimmer>
            }

        </React.Fragment>
    )


};

export default AudioVideoPlayer;

