import React from 'react';
import {Header, Icon, TransitionablePortal, Segment} from "semantic-ui-react";

const animation = 'fade left';
const duration = 500;

const SuccessNotification = (props) => {
    setTimeout(props.handleClose, 5000);
    return (
        <TransitionablePortal
            closeOnTriggerClick
            openOnTriggerClick
            onClose={props.handleClose}
            open={props.open}
            transition={{animation, duration}}
        >
            <Segment
                size={'tiny'}
                style={{
                    marginRight: '1em',
                    left: '75%',
                    position: 'fixed',
                    top: '0%',
                    zIndex: 1000,
                }}
            >
                <Header color={'green'}>
                    <Icon size={'mini'} color={'green'} name={props.icon}/>
                    {!!props.title && props.title}
                </Header>
                {!!props.body && props.body}
            </Segment>
        </TransitionablePortal>
    );

};

export default SuccessNotification;