import React, {useContext, useState} from 'react';
import {Button, Container, Dropdown, Header, Icon, Modal, Popup} from "semantic-ui-react";
import FileExplorerConstatns from "../../shared/const/consts";
import {API} from "aws-amplify";
import {AuthContext} from "../../shared/context/auth-context";

const TextExtractionSettingModal = (props) => {

    const auth = useContext(AuthContext);

    const [dominantLang, setdominantLang] = useState('auto');


    const handleDominantLang = (e, data) => {
        setdominantLang(data.value);
    }

    const textExtractRequest = () => {
        props.setisLoading(true);
        try {
            API.post(
                'documentsmgmnt',
                '/extracttext',
                {
                    body: {
                        "key": props.file.key,
                        'comprend': false,
                        'dominant_lang': dominantLang
                    },
                })
                .then(response => {
                    if (response.operation_allowed) {
                        const currentTempInfo = JSON.parse(window.localStorage.getItem('temporary_info'))
                        window.localStorage.setItem('temporary_info', JSON.stringify({
                            'credits': response.updated_credits,
                            'currentPlan': currentTempInfo.currentPlan
                        }))
                        auth.login();
                        props.setfileDetail(response);
                        props.setrequestSuccess('Text Extract Started');
                    } else {
                        props.handleShowErrorModal("Operation Not Allowed");
                    }
                    props.handlerRefresh();
                })
                .catch(error => {
                    props.handleShowErrorModal("Unexpected Error");
                });
        } catch {
            props.handleShowErrorModal("Operation Not Allowed");
        }
        props.setselectedaction('');
        props.setmodalTextExtraction(false);
        props.setactionbutton(false);
        props.setcreditrequired(false);
    };

    return (
        <Modal
            closeIcon
            open={props.modalTextExtraction}
            onClose={() => props.setmodalTextExtraction(false)}
            size={"mini"}
        >
            <Header icon='settings' content='Some Settings Before Start'/>
            <Modal.Content>
                <Container>
                    <b> Dominant Language </b>
                    <br/>
                    <Dropdown
                        name={"langdominant"}
                        onChange={handleDominantLang}
                        options={FileExplorerConstatns.langs_ocr}
                        placeholder={"Select A Dominant Lenguage"}
                        value={dominantLang}
                        search
                        selection
                    />
                    <Popup
                        trigger={<Icon circular name='info'/>}
                        content='The dominant lenguage of the document'
                        size='mini'
                    />
                </Container>
            </Modal.Content>
            <Modal.Actions>
                <Button secondary onClick={() => props.setmodalTextExtraction(false)}>
                    <Icon name='remove'/> Close
                </Button>
                <Button primary onClick={textExtractRequest}>
                    <Icon name='checkmark'/> Continue
                </Button>
            </Modal.Actions>
        </Modal>
    );

};

export default TextExtractionSettingModal;