const FileExplorerConstatns = {

    langs_ocr: [
        {key: "auto", value: "auto", text: "Automatic"},
        {key: "BG", value: "BG", text: "Bulgarian"},
        {key: "CS", value: "CS", text: "Czech"},
        {key: "DA", value: "DA", text: "Danish"},
        {key: "DE", value: "DE", text: "German"},
        {key: "EL", value: "EL", text: "Greek"},
        {key: "EN", value: "EN", text: "English"},
        {key: "ES", value: "ES", text: "Spanish"},
        {key: "ET", value: "ET", text: "Estonian"},
        {key: "FI", value: "FI", text: "Finnish"},
        {key: "FR", value: "FR", text: "French"},
        {key: "HU", value: "HU", text: "Hungarian"},
        {key: "IT", value: "IT", text: "Italian"},
        {key: "JA", value: "JA", text: "Japanese"},
        {key: "LT", value: "LT", text: "Lithuanian"},
        {key: "LV", value: "LV", text: "Latvian"},
        {key: "NL", value: "NL", text: "Dutch"},
        {key: "PL", value: "PL", text: "Polish"},
        {key: "PT", value: "PT", text: "Portuguese (all Portuguese varieties mixed)"},
        {key: "RO", value: "RO", text: "Romanian"},
        {key: "RU", value: "RU", text: "Russian"},
        {key: "SK", value: "SK", text: "Slovak"},
        {key: "SL", value: "SL", text: "Slovenian"},
        {key: "SV", value: "SV", text: "Swedish"},
        {key: "ZH", value: "ZH", text: "Chinese"},
    ],

    status_mapping: {
        "EXPIRED": "Exipred",
        "NOT_REQUESTED": "Not Requested",
        "ERROR": "Error",
        "COMPLETED": "Completed",
        "IN_PROGRESS": "in progress",
        "STARTED": "in progress",
        "QUEUED": "in progress",
        "COMPLETED_WITH_ERROR": "failed",
        "FAILED": "failed"
    },


    allActions: [
        {
            key: "transcribe",
            value: "transcriptiondefault",
            text: "Transcription"
        },
        {
            key: "translatedeep",
            value: "translationdeep",
            text: "Translation"
        },
        {
            key: "textospeech",
            value: "textospeech",
            text: "Text-To-Speech"
        },
        {
            key: "textextract",
            value: "textextraction",
            text: "Text Extraction"
        },
        {
            key: "convertdocument",
            value: "convertdocument",
            text: "Conversion"
        }
    ]

}

export default FileExplorerConstatns;