import React from 'react';
import {Container, Grid, Header, Icon, List, Segment} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";


const Footer = props => {
    const {t, i18n} = useTranslation('common');

    const history = useHistory();

    const goToPage = (page) => {
        history.push(page)
    }

    return (
        <Segment inverted vertical style={{padding: '5em 0em'}}>
            <Container>
                <Grid divided inverted stackable>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <Header inverted as='h4' content={t('footer.section_1.name')}/>
                            <List link inverted>
                                <List.Item onClick={() => goToPage("/contacts")}
                                           as='a'>{t('footer.section_1.item_1')}</List.Item>
                                <List.Item as='p'>{t('footer.section_1.email')}</List.Item>
                            </List>
                            <Container textAlign={'left'}>
                                <a href="https://www.instagram.com/filewhey/" target="_blank">
                                    <Icon as={'i'} name={'instagram'} link size={'large'}/>
                                </a>
                                <a href="https://www.facebook.com/FileWhey-103816915335340" target="_blank">
                                    <Icon name={'facebook'} link size={'large'}/>
                                </a>
                                <a href="https://www.linkedin.com/company/filewhey" target="_blank">
                                    <Icon name={'linkedin'} link size={'large'}/>
                                </a>
                                <a href="https://www.youtube.com/channel/UCDCOoHWWwuDr8wgRU_VNCtQ/featured" target="_blank">
                                    <Icon name={'youtube'} link size={'large'}/>
                                </a>
                            </Container>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Header inverted as='h4' content={'Resoruces'}/>
                            <List link inverted>
                                {/*<List.Item as='a'>{t('footer.section_2.item_1')}</List.Item>*/}
                                <List.Item onClick={() => goToPage("/faq")}
                                           as='a'>{t('footer.section_2.item_2')}</List.Item>
                                <List.Item onClick={() => goToPage("/privacy")}
                                           as='a'>{'Privacy and legal'}</List.Item>
                            </List>
                        </Grid.Column>
                        {/*<Grid.Column width={7}>*/}
                        {/*    <Header as='h4' inverted>*/}
                        {/*        {t('footer.section_3.name')}*/}
                        {/*    </Header>*/}
                        {/*    <p>*/}
                        {/*        {t('footer.section_3.description')}*/}
                        {/*    </p>*/}
                        {/*</Grid.Column>*/}
                    </Grid.Row>
                </Grid>
                <br/>
                <Header as={"h5"} inverted textAlign={"center"}>
                    © 2021, FileWhey. All rights reserved.
                </Header>
            </Container>
        </Segment>
    );
};

export default Footer;