import React, {useEffect, useState} from 'react';
import {Button, Dimmer, Header, Icon, Loader, Modal, Select} from "semantic-ui-react";
import {API} from "aws-amplify";

const FileActionButtions = (props) => {

        useEffect(() => {
            props.setselectedaction(false);
        }, []);

        const [confirmTryAgain, setconfirmTryAgain] = useState('');
        const [isLoading, setisLoading] = useState(false);

        const handlePreStartSelectedAction = async (confirm) => {
            if (confirm === 'close' && props.selectedaction !== 'transcriptiondefault') {
                setconfirmTryAgain('');
            } else if (confirm === 'proceed' && props.selectedaction !== 'transcriptiondefault') {
                setconfirmTryAgain('');
                props.handleStartSelectedAction();
            } else if (confirm === 'save' && props.selectedaction !== 'transcriptiondefault') {
                setisLoading(true);
                try {
                    await API.put(
                        'filemgmt',
                        '/movefile',
                        {
                            body: {
                                "oldKey": props.fileDetail[props.selectedaction].output_key,
                                "from": "myoutfiles",
                                "to": "myfiles",
                                "id_original_file": props.fileDetail.idfile,
                                "action_id": props.selectedaction
                            }
                        });
                } catch (err) {
                    props.setshowCustomError(['Unexpected Error', 'An unexpected happened when moving your current' + confirmTryAgain + ' to your home']);
                }
                setisLoading(false);
                props.handlerRefreshFiles();
                setconfirmTryAgain('');
                props.handleStartSelectedAction();
            } else if ((confirm === 'proceed' || confirm === 'close') && props.selectedaction === 'transcriptiondefault') {
                setconfirmTryAgain('');
            } else if (confirm === 'save' && props.selectedaction === 'transcriptiondefault') {
                setconfirmTryAgain('');
                props.handleStartSelectedAction();
            } else if (props.actionbutton.startsWith("Start a New") && (!props.fileDetail[props.selectedaction].output_key_location ||
                (!!props.fileDetail[props.selectedaction].output_key_location && props.fileDetail[props.selectedaction].output_key_location === 'myoutfiles'))
            ) {
                setconfirmTryAgain(
                    props.actionbutton.split("Start a New")[props.actionbutton.split("Start a New").length - 1]
                );
            } else {
                props.handleStartSelectedAction();
            }
        };

        return (
            <React.Fragment>
                <p style={{fontWeight: 'bold'}}> Operations: </p>
                <Select
                    name={"actions"}
                    onChange={props.handleSelectedAction}
                    options={props.possibleActions}
                    placeholder={"Select an operation"}
                    value={props.selectedaction}
                />
                <Loader active={!!props.inlineloader} inline/>
                {!!props.creditrequired &&
                <b> {props.creditrequired === true ?
                    '' : 'Credits required: ' + props.creditrequired}
                </b>
                }
                <br/>
                <br/>
                {!!props.actionbutton &&
                <Button
                    style={{marginRight: -10}}
                    floated={"right"}
                    primary
                    disabled={!props.creditrequired && !props.seeaction}
                    onClick={handlePreStartSelectedAction}
                >
                    {props.actionbutton}
                </Button>

                }

                <Modal
                    closeIcon
                    open={!!confirmTryAgain}
                    onClose={() => handlePreStartSelectedAction('close')}
                >
                    <Header icon='archive'
                            content={props.selectedaction === 'transcriptiondefault' ? "Strat transcription again?"
                                : 'Save current' + confirmTryAgain + "?"
                            }/>
                    <Modal.Content>
                        <Dimmer active={isLoading}>
                            <Loader> {"Moving " + confirmTryAgain + " to your home"} </Loader>
                        </Dimmer>
                        <p>
                            {props.selectedaction === 'transcriptiondefault' ? 'If you proceed, your current transcription will be deleted. Do you want to proceed?'
                                : "Do you want to save to you home your current" + confirmTryAgain + " before starting?"}
                        </p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={() => handlePreStartSelectedAction('proceed')}>
                            <Icon name='remove'/> No, continue
                        </Button>
                        <Button color='green' onClick={() => handlePreStartSelectedAction('save')}>
                            <Icon name='checkmark'/> Yes, move and continue
                        </Button>
                    </Modal.Actions>
                </Modal>


            </React.Fragment>
        );

    }
;

export default FileActionButtions;