import React, {Component, useContext} from 'react';
import PropTypes from 'prop-types';
import {createMedia} from "@artsy/fresnel";
import {Container, Grid, Icon, Menu, Segment, Sidebar, Visibility} from "semantic-ui-react";
import MenuLinks from "./Navigation/MenuLinks";
import {useLocation} from 'react-router-dom'
import SidebarHome from "../personalArea/Components/SidebarHome";
import SecondRow from "../personalArea/Components/HompageRows/SecondRow";
import SideBarHomeMenuItems from "../personalArea/Components/SideBarHomeMenuItems";
import {AuthContext} from "./context/auth-context";
import Footer from "./Footer";
import {GiHamburgerMenu} from 'react-icons/gi';

const {MediaContextProvider, Media} = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
});


class DesktopContainer extends Component {
    state = {}

    hideFixedMenu = () => this.setState({fixed: false})
    showFixedMenu = () => this.setState({fixed: true})

    render() {
        const {children} = this.props
        const {fixed} = this.state

        return (
            <Media greaterThan='mobile'>
                <Visibility
                    once={false}
                    onBottomPassed={this.showFixedMenu}
                    onBottomPassedReverse={this.hideFixedMenu}
                >
                    <Segment
                        textAlign='center'
                        style={{minHeight: 40, padding: '1em 0em'}}
                        vertical
                    >
                    </Segment>
                </Visibility>

                {children}

            </Media>
        )
    }
}

class MobileContainer extends Component {
    state = {}

    handleSidebarHide = () => this.setState({sidebarOpened: false})
    handleToggle = () => this.setState({sidebarOpened: true})

    render() {
        const {children} = this.props
        const {sidebarOpened} = this.state

        return (
            <Media as={Sidebar.Pushable} at='mobile'>
                <Sidebar.Pushable>
                    <Sidebar
                        as={Menu}
                        animation='overlay'
                        onHide={this.handleSidebarHide}
                        vertical
                        visible={sidebarOpened}
                    >
                        <MenuLinks
                            mobile={true}
                        />
                    </Sidebar>

                    <Sidebar.Pusher dimmed={sidebarOpened}>
                        <Segment
                            textAlign='center'
                            style={{minHeight: 40, padding: '1em 0em'}}
                            vertical
                        >
                            <Container>
                                <Menu
                                    secondary
                                    borderless
                                    size='large'
                                >
                                    <Menu.Item onClick={this.handleToggle}>
                                        <GiHamburgerMenu size={'20'}/>
                                    </Menu.Item>
                                </Menu>

                            </Container>

                        </Segment>

                        {children}
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
                <Footer/>
            </Media>
        )
    }
}

class AppContainerComputer extends Component {
    state = {}

    hideFixedMenu = () => this.setState({fixed: false})
    showFixedMenu = () => this.setState({fixed: true})

    render() {
        const {children} = this.props
        const {fixed} = this.state


        return (
            <React.Fragment>
                <Media greaterThan='mobile'>
                    <SidebarHome/>

                    <Grid columns={1}>

                        <Grid.Column width={16} style={{marginLeft: 160}}>
                            <Grid.Row textAlign={"right"}>
                                <SecondRow/>
                            </Grid.Row>

                            {children}

                        </Grid.Column>
                    </Grid>
                </Media>
            </React.Fragment>
        )
    }
}

class AppContainerMobile extends Component {
    state = {}

    handleSidebarHide = () => this.setState({sidebarOpened: false})
    handleToggle = () => this.setState({sidebarOpened: true})

    render() {
        const {children} = this.props
        const {sidebarOpened} = this.state

        return (
            <Media as={Sidebar.Pushable} at='mobile'>
                <Sidebar.Pushable>
                    <Sidebar
                        as={Menu}
                        animation='overlay'
                        inverted
                        onHide={this.handleSidebarHide}
                        vertical
                        width={'thin'}
                        visible={sidebarOpened}
                        color={'pink'}
                    >
                        <SideBarHomeMenuItems/>

                    </Sidebar>

                    <Sidebar.Pusher dimmed={sidebarOpened}>
                        <Segment
                            inverted
                            textAlign='center'
                            style={{padding: '0em 0em'}}
                            vertical
                        >
                            <Container>
                                <Menu inverted pointing secondary size='large'>
                                    <Menu.Item onClick={this.handleToggle}>
                                        <GiHamburgerMenu size={'20'}/>
                                    </Menu.Item>
                                </Menu>
                            </Container>
                        </Segment>
                        <Container fluid>
                            <SecondRow/>
                        </Container>

                        {children}

                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </Media>
        )
    }
}


class OtherComputer extends Component {
    state = {}

    hideFixedMenu = () => this.setState({fixed: false})
    showFixedMenu = () => this.setState({fixed: true})

    render() {
        const {children} = this.props


        return (
            <React.Fragment>
                <Media greaterThan='mobile'>
                    <Container fluid>
                        <SecondRow
                            hideUpgradeOptions={true}
                        />
                    </Container>
                    {children}
                    <Footer/>
                </Media>
            </React.Fragment>
        )
    }
}

class OtherMobile extends Component {
    state = {}

    handleSidebarHide = () => this.setState({sidebarOpened: false})
    handleToggle = () => this.setState({sidebarOpened: true})

    render() {
        const {children} = this.props

        return (
            <Media at='mobile'>
                <Container fluid>
                    <SecondRow
                        hideUpgradeOptions={true}
                    />
                </Container>
                {children}
                <Footer/>
            </Media>
        )
    }
}

DesktopContainer.propTypes = {
    children: PropTypes.node,
}

MobileContainer.propTypes = {
    children: PropTypes.node,
}

AppContainerComputer.propTypes = {
    childer: PropTypes.node,
}

OtherComputer.propTypes = {
    childer: PropTypes.node,
}

OtherMobile.propTypes = {
    children: PropTypes.node,
}

const ResponsiveContainer = ({children}) => {
    ResponsiveContainer.propTypes = {
        children: PropTypes.node,
    }

    const location = useLocation();
    const auth = useContext(AuthContext);


    if (!!auth.isLoggedIn && location.pathname.split("/").length > 0
        && location.pathname.split("/")[1] === 'account') {
        return (
            <MediaContextProvider>
                <AppContainerComputer>{children}</AppContainerComputer>
                <AppContainerMobile>{children}</AppContainerMobile>
            </MediaContextProvider>
        )
    } else if (!!auth.isLoggedIn && location.pathname.split("/").length > 0
        && location.pathname.split("/")[1] === 'buy') {
        return (
            <MediaContextProvider>
                <OtherComputer>{children}</OtherComputer>
                <OtherMobile>{children}</OtherMobile>
            </MediaContextProvider>
        );
    } else {
        return (
            <MediaContextProvider>
                <DesktopContainer>{children}</DesktopContainer>
                <MobileContainer>{children}</MobileContainer>
            </MediaContextProvider>
        )
    }


};

export default ResponsiveContainer;