import React, {useContext, useEffect, useState} from 'react';
import {Button, Container, Dimmer, Dropdown, Header, Icon, Loader, Modal, Popup} from "semantic-ui-react";
import {API} from "aws-amplify";
import {AuthContext} from "../../shared/context/auth-context";


const ConvertDocumentsModal = (props) => {

    const auth = useContext(AuthContext);

    const [dominantLang, setdominantLang] = useState('auto');
    const [to_convert, setto_convert] = useState(false);
    const [possibilites, setpossibilities] = useState('');
    const [isloading, setisloading] = useState(false);


    const textExtractRequest = () => {
        props.setisLoading(true);
        try {
            API.post(
                'documentsmgmnt',
                '/convert',
                {
                    body: {
                        "key": props.file.key,
                        'to_convert': to_convert,
                        'dominant_lang': dominantLang,
                        'operation': 'convertdocument',
                    },
                })
                .then(response => {
                    if (response.operation_allowed) {
                         const currentTempInfo = JSON.parse(window.localStorage.getItem('temporary_info'))
                        window.localStorage.setItem('temporary_info', JSON.stringify({
                           'credits': response.updated_credits,
                            'currentPlan': currentTempInfo.currentPlan
                        }))
                        auth.login();
                        props.setfileDetail(response);
                        props.setrequestSuccess('Document Conversion Started');
                    } else {
                        props.handleShowErrorModal("Operation Not Allowed");
                    }
                    props.handlerRefresh();
                })
                .catch(error => {
                    props.handleShowErrorModal("Unexpected Error");
                });
        } catch {
            props.handleShowErrorModal("Unexpected Error");
        }

        props.setselectedaction('');
        props.setconvertdocsModal(false);
        props.setactionbutton(false);
        props.setcreditrequired(false);
    };


    useEffect(() => {
        setisloading(true);
        const get_possibilites = async () => {
            API.post(
                'documentsmgmnt',
                '/getpossibilites',
                {
                    body: {
                        "key": props.file.key,
                        'to_convert': to_convert,
                        'dominant_lang': dominantLang,
                        'operation': 'convertdocument',
                    },
                }).then(res => {
                if (res.possibilities || res.possibilities === []) {
                    setpossibilities(res.possibilities)
                } else {
                    props.handleShowErrorModal("Unexpected Error")
                }
                setisloading(false);
            }).catch(err => {
                props.handleShowErrorModal("Unexpected Error");
                setisloading(false);
            })
        }
        get_possibilites();
    }, [props.file.key])


    const handleDominantLang = (e, data) => {
        setdominantLang(data.value);
    }

    const handleConvertTo = (e, data) => {
        setto_convert(data.value)
    }

    return (
        <Modal
            closeIcon
            open={props.convertdocsModal}
            onClose={() => props.setconvertdocsModal(false)}
            size={"mini"}
        >
            <Header icon='settings' content='Some Settings Before Start'/>
            <Modal.Content>
                <Dimmer active={isloading}>
                    <Loader> Loading </Loader>
                </Dimmer>
                <Container>
                    <b> Output Format </b>
                    <br/>
                    <Dropdown
                        name={"outputformat"}
                        onChange={handleConvertTo}
                        options={possibilites}
                        placeholder={"Select the output format"}
                        value={to_convert}
                        search
                        selection
                    />
                    <Popup
                        trigger={<Icon circular name='info'/>}
                        content='The desired output format'
                        size='mini'
                    />
                    <br/>
                    <br/>
                </Container>
            </Modal.Content>
            <Modal.Actions>
                <Button secondary onClick={() => props.setconvertdocsModal(false)}>
                    <Icon name='remove'/> Close
                </Button>
                <Button disabled={!to_convert}
                        primary
                        onClick={textExtractRequest}>
                    <Icon name='checkmark'/> Continue
                </Button>
            </Modal.Actions>
        </Modal>
    );

};

export default ConvertDocumentsModal;