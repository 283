import React, {useState, useEffect} from 'react';
import {Grid, Header, Segment,} from "semantic-ui-react";
import {API} from "aws-amplify";
import {useHistory} from "react-router-dom";
import TranscriptionSettingModal from "./TranscriptionSettingModal";
import FileExplorerConstatns from '../../shared/const/consts';
import FileDetails from "./FileDetails";
import FileActionButtions from "./FileActionButtons";
import TranslationSettingModal from "./TranslationSettingModal";
import CompletedActions from "./CompletedActions";
import TextExtractionSettingModal from "./TextExtractionSettingModal";
import ConvertDocumentsModal from "./ConvertDocumentsModal";
import ErrorModal from "../../shared/FormElements/ErrorModal";
import TextospeechSettingModal from "./TextospeechSettingModal";
import SuccessNotification from "../../shared/FormElements/SuccessNotification";

const CustomDetailRenderer = (props) => {

    const history = useHistory();
    const goToPage = (page) => {
        history.push(page)
    }

    // handle basic page
    const [fileDetail, setfileDetail] = useState();
    const [isLoading, setisLoading] = useState(false);
    const [refresh, setrefresh] = useState(false);
    const [showErrorModal, setshowErrorModal] = useState('');
    const [showCustomError, setshowCustomError] = useState('');
    const [requestSuccess, setrequestSuccess] = useState('');
    const [autoRefresh, setAutoRefresh] = useState('auto_refresh');

    //handle actions
    const [selectedaction, setselectedaction] = useState('');
    const [possibleActions, setpossibleActions] = useState();
    const [seeaction, setseeaction] = useState(false);
    const [inlineloader, setinlineloader] = useState(false);
    const [actionbutton, setactionbutton] = useState();

    //handle credits required
    const [creditrequired, setcreditrequired] = useState();
    const [operationAllowed, setOperationAllowed] = useState(false);

    // handle transcribe
    const [trancribeModal, settrancribeModal] = useState(false);

    // handle translate
    const [translateModal, settranslateModal] = useState(false);
    const [transmod, settransmod] = useState('deep');

    // handle convert
    const [convertdocsModal, setconvertdocsModal] = useState(false);

    // handle text-to-speech
    const [textospeechModal, settextospeechModal] = useState(false);

    // handle image text recognition
    const [modalTextExtraction, setmodalTextExtraction] = useState(false);

    // Image Object recognition

    const handleGetFileInfos = async () => {
        if (!!props.file.key) {
            try {
                const file_details = await API.post(
                    'filemgmt',
                    '/filedetails',
                    {
                        body: {
                            key: props.file.key
                        },
                        queryStringParameters: {
                            transcription_obj: 'false',
                        },
                    })
                // if (file_details['translationdeep']['job_status'] === 'IN_PROGRESS'
                //     || file_details['translationdeep']['job_status'] === 'QUEUED'
                // ) {
                //     try {
                //         const translationstatus = await API.post(
                //             'translatetext',
                //             '/check-translation-status',
                //             {
                //                 body: {
                //                     idfile: file_details.idfile
                //                 }
                //             })
                //         file_details['result'] = translationstatus.result
                //     } catch {
                //
                //     }
                // }
                setfileDetail(file_details);
            } catch (err) {
                setshowErrorModal(true);
            }
            setisLoading(false);

        }
    }

    useEffect(() => {
        handleGetFileInfos();
    }, [autoRefresh])

    useEffect(() => {
        setisLoading(true);
        if (refresh !== 'force_refresh' && refresh !== 'refresh_force') {
            setselectedaction('');
            setpossibleActions();
            setseeaction(false);
        }
        setactionbutton(false);
        setcreditrequired(false);
        handleGetFileInfos();
        // set possible actions
        let possible_actions = [];
        possible_actions = props.file.operations;
        if (!possible_actions || (!!possible_actions && possible_actions.length === 0)) {
            possible_actions = [{
                key: 'no_action',
                value: 'no_action',
                text: 'No Action Yet Available for this kind of files',
                billing: true
            }]
        }
        setpossibleActions(possible_actions);

    }, [props.file.key, refresh, props.file.extension]);

    const handlerRefresh = () => {
        if (refresh === 'force_refresh') {
            setrefresh('refresh_force');
        } else {
            setrefresh('force_refresh');
        }
    }

    const handleShowErrorModal = (error) => {
        if (!error) {
            setshowErrorModal(!showErrorModal);
        } else {
            try {
                setshowErrorModal(error);
            } catch {
                setshowErrorModal(!showErrorModal);
            }

        }
        setisLoading(false);
    };

    const handleCloseErrorModal = () => {
        setshowErrorModal(false);
        settrancribeModal(false);
    };

    const handleCloseCustomErrorModal = () => {
        setshowCustomError('');
    }


    // handle actions
    const handleSelectedAction = (e, data) => {
        if (data.value !== 'no_action') {
            setinlineloader(true);
            setselectedaction(data.value);
            setcreditrequired(false);
            if (!!fileDetail && !!fileDetail[data.value] && fileDetail[data.value] &&
                (fileDetail[data.value].job_status !== 'IN_PROGRESS' &&
                    fileDetail[data.value].job_status !== 'STARTED' &&
                    fileDetail[data.value].job_status !== 'QUEUED' && fileDetail[data.value].job_status !== 'COMPLETED_WITH_ERROR'
                )) {
                if (fileDetail[data.value].job_status === 'COMPLETED') {
                    setactionbutton('Start a New ' + FileExplorerConstatns.allActions.filter(i => i.value === data.value)[0].text);
                } else {
                    setactionbutton('Start ' + FileExplorerConstatns.allActions.filter(i => i.value === data.value)[0].text);
                }
                setcreditrequired(true);
                setOperationAllowed(true);
                setinlineloader(false);
            } else if (!!fileDetail && !!fileDetail[data.value] && fileDetail[data.value] &&
                (fileDetail[data.value].job_status === 'IN_PROGRESS' ||
                    fileDetail[data.value].job_status === 'QUEUED' ||
                    fileDetail[data.value].job_status === 'STARTED'
                )) {
                setcreditrequired(false);
                setactionbutton('Check Status')
                setseeaction(data.value);
                setinlineloader(false);
            } else if (!!fileDetail && !!fileDetail[data.value] && fileDetail[data.value] &&
                fileDetail[data.value].job_status === 'COMPLETED_WITH_ERROR'
            ) {
                // TODO for translation job, check why
            }
        }
    }

    const handleStartSelectedAction = () => {
        if (selectedaction !== 'no_action') {
            if (!operationAllowed && actionbutton === 'Buy More Credits') {
                goToPage("/pricing");
            }
            if (!!fileDetail && !!fileDetail[selectedaction]
                && fileDetail[selectedaction]
                && (fileDetail[selectedaction].job_status === 'IN_PROGRESS'
                    || fileDetail[selectedaction].job_status === 'STARTED')
            ) {
                handleGetFileInfos();
            } else {
                if (selectedaction === 'transcriptiondefault') {
                    settrancribeModal(true);
                } else if (selectedaction === 'translation' || selectedaction === 'translationdeep') {
                    if (selectedaction === 'translateaet') {
                        settransmod('eat')
                    }
                    settranslateModal(true);
                } else if (selectedaction === 'textextraction') {
                    setmodalTextExtraction(true);
                } else if (selectedaction === 'convertdocument') {
                    setconvertdocsModal(true);
                } else if (selectedaction === 'textospeech') {
                    settextospeechModal(true);
                }
            }
        }
    };

    const handleCloseSuccessModal = () => {
        setrequestSuccess(false);
    }

    return (
        <React.Fragment>
            <Segment
                style={{minHeight: 100}}
                loading={isLoading}
                secondary
                size={'small'}>
                {!!fileDetail && !!possibleActions &&
                <Grid centered padded>
                    <Grid.Row>
                        <Grid.Column>
                            <Header as={"h3"}>
                                {props.file.key.split('/')[props.file.key.split('/').length - 1].length > 60 ?
                                    String(props.file.key.split('/')[props.file.key.split('/').length - 1]).slice(0, 15)
                                    + '...' +
                                    String(props.file.key.split('/')[props.file.key.split('/').length - 1]).slice(-15,)
                                    : props.file.key.split('/')[props.file.key.split('/').length - 1]
                                }
                            </Header>
                            <FileDetails
                                fileDetail={fileDetail}
                                file={props.file}
                            />
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <CompletedActions
                                fileDetail={fileDetail}
                                possibleActions={possibleActions}
                                handlerRefresh={handlerRefresh}
                                setisLoading={setisLoading}
                                setAutoRefresh={setAutoRefresh}
                                autoRefresh={autoRefresh}
                                handlerRefreshFiles={props.handlerRefreshFiles}
                                filekey={props.file.key}
                            />
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <FileActionButtions
                                handlerRefreshFiles={props.handlerRefreshFiles}
                                fileDetail={fileDetail}
                                handleSelectedAction={handleSelectedAction}
                                possibleActions={possibleActions}
                                selectedaction={selectedaction}
                                setselectedaction={setselectedaction}
                                inlineloader={inlineloader}
                                actionbutton={actionbutton}
                                creditrequired={creditrequired}
                                seeaction={seeaction}
                                handleStartSelectedAction={handleStartSelectedAction}
                                handleShowErrorModal={handleShowErrorModal}
                                setshowCustomError={setshowCustomError}
                            />
                            {/*<FileActionDetails*/}
                            {/*    selectedaction={selectedaction}*/}
                            {/*    fileDetail={fileDetail}*/}
                            {/*/>*/}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                }
            </Segment>
            {!!convertdocsModal &&
            <ConvertDocumentsModal
                convertdocsModal={convertdocsModal}
                setconvertdocsModal={setconvertdocsModal}
                setisLoading={setisLoading}
                file={props.file}
                setrequestSuccess={setrequestSuccess}
                setfileDetail={setfileDetail}
                handleShowErrorModal={handleShowErrorModal}
                handlerRefresh={handlerRefresh}
                setselectedaction={setselectedaction}
                setactionbutton={setactionbutton}
                setcreditrequired={setcreditrequired}
            />
            }
            {!!modalTextExtraction &&
            <TextExtractionSettingModal
                modalTextExtraction={modalTextExtraction}
                setmodalTextExtraction={setmodalTextExtraction}
                setisLoading={setisLoading}
                file={props.file}
                setrequestSuccess={setrequestSuccess}
                setfileDetail={setfileDetail}
                handleShowErrorModal={handleShowErrorModal}
                handlerRefresh={handlerRefresh}
                setselectedaction={setselectedaction}
                setactionbutton={setactionbutton}
                setcreditrequired={setcreditrequired}
            />
            }

            {!!textospeechModal &&
            <TextospeechSettingModal
                textospeechModal={textospeechModal}
                settextospeechModal={settextospeechModal}
                setisLoading={setisLoading}
                file={props.file}
                setrequestSuccess={setrequestSuccess}
                setfileDetail={setfileDetail}
                handleShowErrorModal={handleShowErrorModal}
                handlerRefresh={handlerRefresh}
                setselectedaction={setselectedaction}
                setactionbutton={setactionbutton}
                setcreditrequired={setcreditrequired}
            />
            }

            {!!translateModal &&
            <TranslationSettingModal
                translateModal={translateModal}
                settranslateModal={settranslateModal}
                transmod={transmod}
                setisLoading={setisLoading}
                file={props.file}
                setrequestSuccess={setrequestSuccess}
                setfileDetail={setfileDetail}
                handleShowErrorModal={handleShowErrorModal}
                handlerRefresh={handlerRefresh}
                setselectedaction={setselectedaction}
                setactionbutton={setactionbutton}
                setcreditrequired={setcreditrequired}
            />
            }

            {!!trancribeModal &&
            <TranscriptionSettingModal
                trancribeModal={trancribeModal}
                settrancribeModal={settrancribeModal}
                setisLoading={setisLoading}
                setselectedaction={setselectedaction}
                setcreditrequired={setcreditrequired}
                handlerRefresh={handlerRefresh}
                setrequestSuccess={setrequestSuccess}
                setfileDetail={setfileDetail}
                setactionbutton={setactionbutton}
                file={props.file}
                handleShowErrorModal={handleShowErrorModal}
                setshowCustomError={setshowCustomError}
            />
            }

            <ErrorModal
                open={!!showErrorModal}
                handleCloseErrorModal={handleCloseErrorModal}
                body={"There was an unexpected error when starting the operation. Please try again in some minutes."}
                title={'Unexpected Error'}
                icon={'bug'}
            />

            <ErrorModal
                open={!!showCustomError}
                handleCloseErrorModal={handleCloseCustomErrorModal}
                body={showCustomError[1]}
                title={showCustomError[0]}
                icon={'bug'}
            />

            <SuccessNotification
                open={!!requestSuccess}
                handleClose={handleCloseSuccessModal}
                title={requestSuccess}
                icon={'check'}
            />


        </React.Fragment>
    )
};

export default CustomDetailRenderer;