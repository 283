import React, {useState, useContext} from 'react';

import Input from "../../shared/FormElements/Input";
import {Button, Checkbox, Container, Dimmer, Grid, Loader, Segment} from 'semantic-ui-react';
import {
    VALIDATOR_EMAIL,
    VALIDATOR_MINLENGTH,
    VALIDATOR_REQUIRE
} from '../../shared/util/validators';
import {useForm} from '../../shared/hooks/form-hook';
import './Auth.css';
import {AuthContext} from "../../shared/context/auth-context";
import {Auth} from 'aws-amplify';
import {useHistory} from "react-router-dom";

const AuthCustom = () => {
        const auth = useContext(AuthContext);
        const [isLoginMode, setIsLoginMode] = useState(true);

        const [showConfirmSingup, setshowConfirmSingup] = useState(false);
        const [isResetPassword, setisResetPassword] = useState(false);
        const [error, setError] = useState('');
        const [codeResent, setcodeResent] = useState(false);
        const [success, setsuccess] = useState(false);

        const [enterMFACode, setenterMFACode] = useState(false);
        const [currentUser, setUser] = useState(null);
        const [currentChallenge, setCurrentChallenge] = useState(null);
        const [agreementChecked, setagreementChecked] = useState(false);

        const [isLoading, setisLoading] = useState(false);
        const [formState, inputHandler, setFormData] = useForm(
            {
                email: {
                    value: '',
                    isValid: false
                },
                password: {
                    value: '',
                    isValid: false
                },
            },
            false
        );

        const history = useHistory();

        const goToPage = (page) => {
            history.push(page)
        }

        const switchModeHandler = () => {
            setError(false);
            if (!showConfirmSingup) {
                if (!isLoginMode) {
                    setFormData(
                        {
                            ...formState.inputs,
                            name: undefined
                        },
                        formState.inputs.email.isValid && formState.inputs.password.isValid
                    );
                } else {
                    setFormData(
                        {
                            ...formState.inputs,
                            name: {
                                value: '',
                                isValid: false
                            }
                        },
                        false
                    );
                }
                setIsLoginMode(prevMode => !prevMode);
            } else {
                if (!!showConfirmSingup) {
                    setFormData(
                        {
                            ...formState.inputs,
                            otpcode: {
                                value: '',
                                isValid: false
                            }
                        },
                        false
                    );
                } else {
                    setFormData(
                        {
                            ...formState.inputs,
                            forgotpwdotp: {
                                value: '',
                                isValid: false
                            }
                        },
                        false
                    );
                }
            }
        };

        const handlesetisResetPassword = () => {
            setisResetPassword(true);
        }

        const handleAgreeTerms = (e, data) => {
            setagreementChecked(data.checked);
        }

        const handleResendConfirmationCode = async () => {
            setError('');
            setisLoading(true);
            try {
                await Auth.resendSignUp(formState.inputs.email.value);
                setcodeResent('Code resent successfully')
            } catch (err) {
                setcodeResent('Error resending code: ', err)
            }
            setisLoading(false);
        }

        const handleConfrimSignIn = async event => {
            setError('');
            setisLoading(true);
            event.preventDefault();
            setsuccess('');
            try {
                await Auth.confirmSignIn(
                    currentUser,
                    formState.inputs.otpcodemfa.value,
                    currentChallenge
                );
                auth.login();
            } catch (err) {
                if (!!err && !!err.message) {
                    setError(err.message)
                } else {
                    setError("Unexpected Error");
                }
            }
            setisLoading(false);
        }

        const handleSignIN = async () => {
            setError('');
            try {
                const user = await Auth.signIn(
                    formState.inputs.email.value,
                    formState.inputs.password.value);
                setUser(user)
                setCurrentChallenge(user.challengeName);
                if (user.challengeName === 'SMS_MFA' ||
                    user.challengeName === 'SOFTWARE_TOKEN_MFA') {
                    setenterMFACode(true);
                } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    // TODO
                } else if (user.challengeName === 'MFA_SETUP') {
                    // This happens when the MFA method is TOTP
                    // The user needs to setup the TOTP before using it
                    // More info please check the Enabling MFA part
                    Auth.setupTOTP(user);
                } else {
                    // The user directly signs in
                    auth.login();
                }
            } catch (err) {
                if (!!err && !!err.message) {
                    setError(err.message)
                } else {
                    setError("Unexpected Error");
                }
            }
        }

        const authSubmitHandler = async event => {
            setError('');
            event.preventDefault();
            setsuccess('');
            setisLoading(true);
            if (!isResetPassword) {
                if (isLoginMode) {
                    await handleSignIN();
                } else {
                    try {
                        await Auth.signUp({
                            username: formState.inputs.email.value,
                            password: formState.inputs.password.value,
                            attributes: {
                                name: formState.inputs.name.value,
                                email: formState.inputs.email.value
                            }
                        });
                        setshowConfirmSingup(true);
                    } catch (err) {
                        if (!!err && !!err.message) {
                            setError(err.message)
                        } else {
                            setError("Unexpected Error");
                        }
                    }
                }
            } else {
                if (!success) {
                    try {
                        await Auth.forgotPassword(formState.inputs.email.value)
                        setsuccess('Code sent to your email')
                    } catch (err) {
                        if (!!err && !!err.message) {
                            setError(err.message)
                        } else {
                            setError("Unexpected Error");
                        }
                    }
                } else {
                    try {
                        await Auth.forgotPasswordSubmit(formState.inputs.email.value, formState.inputs.forgotpwdotp.value, formState.inputs.password.value);
                        await handleSignIN();
                    } catch (err) {
                        if (!!err && !!err.message) {
                            setError(err.message)
                        } else {
                            setError("Unexpected Error");
                        }
                    }
                }
            }
            setisLoading(false);
        };

        const authConfirmSingup = async event => {
            setError('');
            event.preventDefault();
            setsuccess('');
            setisLoading(true);
            try {
                await Auth.confirmSignUp(formState.inputs.email.value,
                    formState.inputs.otpcode.value);
                await handleSignIN();
            } catch (error) {
                if (!!error && !!error.message) {
                    setError(error.message)
                }
            }
            setisLoading(false);
        };

        return (
            <Container>
                <Dimmer active={!!isLoading} page>
                    <Loader/>
                </Dimmer>
                {!showConfirmSingup && !enterMFACode &&
                <Segment className="authentication">
                    {!!error && <p style={{
                        fontWeight: 'bold',
                        color: 'red'
                    }}>
                        {'Error: ' + error}
                    </p>}
                    {!!success && <p style={{
                        fontWeight: 'bold',
                        color: 'darkgreen'
                    }}>
                        {success}
                    </p>}
                    <h2>{!!isResetPassword ? 'Reset Password' : isLoginMode ? 'Login' : 'Signup'}</h2>
                    <hr/>
                    <form onSubmit={authSubmitHandler}>
                        {!isLoginMode && (
                            <Input
                                element="input"
                                id="name"
                                type="text"
                                label="Name"
                                validators={[VALIDATOR_REQUIRE()]}
                                errorText="Inserisci il tuo nome."
                                onInput={inputHandler}
                            />
                        )}
                        <Input
                            element="input"
                            id="email"
                            type="email"
                            label="E-Mail"
                            validators={[VALIDATOR_EMAIL()]}
                            errorText="Enter a valid email"
                            onInput={inputHandler}
                        />
                        {(!isResetPassword || (!!isResetPassword && !!success)) &&
                        <Input
                            element="input"
                            id="password"
                            type="password"
                            label={(!!isResetPassword && !!success) ? "New Password" : "Password"}
                            validators={[VALIDATOR_MINLENGTH(5)]}
                            errorText="Enter a valid password"
                            onInput={inputHandler}
                        />
                        }
                        {!!isResetPassword && !!success &&
                        <Input
                            element="input"
                            id="forgotpwdotp"
                            label="OTP Code"
                            validators={[VALIDATOR_MINLENGTH(6)]}
                            errorText="Enter OTP received by email"
                            onInput={inputHandler}
                        />
                        }
                        {!isLoginMode &&
                        <div>
                            <div> <Checkbox
                                onClick={handleAgreeTerms}
                            />
                            {'I agree to the'} <a
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                        goToPage('/terms-of-service')
                                    }}>
                                    FileWhey Terms </a>
                            </div>
                            <br/>
                            <br/>
                        </div>
                        }
                        <Button size={'mini'} primary type="submit"
                                disabled={(!!formState && !formState.isValid) || (!isLoginMode && !agreementChecked)}>
                            {!!isResetPassword ? !success ? 'Send confirmation code' : 'Confirm Change' : isLoginMode ? 'Login' : 'Signup'}
                        </Button>
                    </form>
                    {!isResetPassword &&
                    <React.Fragment>
                        <Button size={'mini'} secondary inverse onClick={switchModeHandler}>
                            Go to {isLoginMode ? 'Signup' : 'Login'}
                        </Button>
                        {!!isLoginMode &&
                        <p style={{
                            marginTop: '1em',
                            color: 'darkblue',
                            cursor: 'pointer'
                        }}
                           onClick={handlesetisResetPassword}
                        >
                            Forgot your password?
                        </p>
                        }
                    </React.Fragment>
                    }
                </Segment>}
                {!!showConfirmSingup &&
                <Segment className="authentication">
                    {!!codeResent && <p style={{
                        fontWeight: 'bold',
                        color: 'darkgreen'
                    }}>
                        {codeResent}
                    </p>}
                    <h2> {' Confirm OTP '} </h2>
                    <form onSubmit={authConfirmSingup}>
                        <Input
                            element="input"
                            id="otpcode"
                            label="OTP Code"
                            validators={[VALIDATOR_MINLENGTH(6)]}
                            errorText="Enter OTP received by email"
                            onInput={inputHandler}
                        />
                        <Button primary type="submit" disabled={!formState.isValid}>
                            Confirm
                        </Button>
                    </form>
                    <p style={{
                        marginTop: '1em',
                        color: 'darkblue',
                        cursor: 'pointer'
                    }}
                       onClick={handleResendConfirmationCode}
                    >
                        Resend Confirmation Code
                    </p>
                </Segment>
                }
                {!!enterMFACode &&
                <Segment className="authentication">
                    {!!error && <p style={{
                        fontWeight: 'bold',
                        color: 'red'
                    }}>
                        {'Error: ' + error}
                    </p>}
                    <h2> {' MFA - Confirm TOTP '} </h2>
                    <form onSubmit={handleConfrimSignIn}>
                        <Input
                            element="input"
                            id="otpcodemfa"
                            label="MFA Code"
                            validators={[VALIDATOR_MINLENGTH(6)]}
                            errorText="Enter OTP generated by your Authentication App"
                            onInput={inputHandler}
                        />
                        <Button primary type="submit" disabled={!formState.isValid}>
                            Confirm
                        </Button>
                    </form>
                </Segment>
                }
            </Container>
        );
    }
;

export default AuthCustom;
