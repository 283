import {API} from "aws-amplify";

const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
        }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
}

function download_plain(filename, text) {
    var pom = document.createElement('a');
    pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    pom.setAttribute('download', filename);

    if (document.createEvent) {
        var event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        pom.dispatchEvent(event);
    } else {
        pom.click();
    }
}

const handleDownloadFile = async (fileKey, location, payload) => {

    if (location === 'local_plain') {
        try {
            download_plain(fileKey, payload)
        } catch (err) {

        }
    } else if (location === 'local_blob') {
        try {
            downloadBlob(payload, fileKey)
        } catch (err) {

        }
    } else {
        try {
            const presignedurl = await API.post(
                'filemgmt',
                '/createPresignedUrl',
                {
                    body: {
                        location: location,
                        key: fileKey,
                        expiration: null,
                        type: ''
                    },
                    queryStringParameters: {
                        mod: 'download_file'
                    }
                });
            let response;
            try {
                response = await fetch(presignedurl, {mode: 'cors'});
                const blob = await response.blob()
                let filename = ''
                if (!!payload) {
                    filename = payload
                } else {
                    filename = fileKey.split('/')[fileKey.split('/').length - 1]
                }
                downloadBlob(blob, filename)
            } catch {
                window.open(presignedurl, "_blank")
            }
        } catch (err) {
            //TODO
        }
    }
}

export default handleDownloadFile;