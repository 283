import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Dimmer, Loader} from 'semantic-ui-react'
import {API} from 'aws-amplify';
import handleDownloadFile from "../../shared/hooks/download-hook";
import path from 'path';
import {
    ChonkyActions,
    FullFileBrowser,
    defineFileAction,
    ChonkyIconName
} from 'chonky';

import UploadNewImage from "../../shared/components/UploadNewImage";
import {DndProvider} from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import useWindowDimensions from "../../shared/hooks/useWindowsDimensions";
import {useHistory} from "react-router-dom";
import './FileExplorer.css';
import ModalCreateNewFolder from "./ModalCreateNewFolder";
import ErrorModal from "../../shared/FormElements/ErrorModal";
import ModalRenameKey from "./ModalRenameKey";
import SuccessModal from "../../shared/FormElements/SuccessModal";
import ModalDeleteKey from "./ModalDeleteKey";

const FileExplorer = (props) => {
    const [isLoading, setisLoading] = useState(false);
    const [refresh, setrefresh] = useState(false);
    const [folderPrefix, setKeyPrefix] = useState(
        !!window.localStorage.getItem('currentFileEditorState') &&
        !!JSON.parse(window.localStorage.getItem('currentFileEditorState')) &&
        !!!!JSON.parse(window.localStorage.getItem('currentFileEditorState')).currentPrefix ?
            JSON.parse(window.localStorage.getItem('currentFileEditorState')).currentPrefix :
            '/'
    );
    const [files, setFiles] = useState();
    const [showErrorModal, setshowErrorModal] = useState(null);
    const [requestSuccess, setrequestSuccess] = useState('');
    const [modalInputFolder, setModalInputFolder] = useState(false);
    const [modalRename, setmodalRename] = useState('');
    const [modalRemove, setmodalRemove] = useState('');


    const {height, width} = useWindowDimensions();

    const history = useHistory();
    const goToPage = (page) => {
        history.push(page)
    }
    const inputFile = useRef(null);

    // not user in favor of the original with group included
    // TODO add custom group
    const CustomDownloadKey = defineFileAction({
        id: 'download_files',
        requiresSelection: true,
        button: {
            name: 'Download',
            toolbar: false,
            contextMenu: true,
            group: "Actions",
            icon: ChonkyIconName.download,
        },
    });
    const CustomDeleteKey = defineFileAction({
        id: 'delete_files',
        requiresSelection: true,
        button: {
            name: 'Delete',
            toolbar: false,
            contextMenu: true,
            group: "Actions",
            icon: ChonkyIconName.trash,
        },
    });
    /////////////////

    const RenameKey = defineFileAction({
            id: 'rename_action',
            requiresSelection: true,
            button: {
                name: 'Rename',
                toolbar: false,
                contextMenu: true,
                group: "Actions",
                icon: ChonkyIconName.file,
            },
        }
    );
    const CustomOpenSelectionKey = defineFileAction({
        id: 'open_selection',
        requiresSelection: true,
        button: {
            name: 'Open',
            toolbar: false,
            contextMenu: true,
            icon: ChonkyIconName.openFiles,
        }
    })
    const CustomShareSelectionKey = defineFileAction({
        id: 'share_selection',
        requiresSelection: true,
        button: {
            name: 'Share',
            toolbar: false,
            contextMenu: true,
            icon: ChonkyIconName.share,
        }
    })

    const handleRenameFile = async (oldKey, newKey, isDir) => {
        setisLoading(true);
        await API.put(
            'filemgmt',
            '/movefile',
            {
                body: {
                    "newKey": newKey[0] === '/' ? newKey.slice(1,) : newKey,
                    "oldKey": oldKey,
                    "from": "myfiles",
                    "to": "myfiles",
                    'isDir': !!isDir ? isDir : false,
                },
                headers: {'Content-Type': 'application/json'}
            })
            .catch(error => {
                setshowErrorModal(true);
            });
        setisLoading(false);
        props.handlerRefresh();
    };

    const handleSelectFile = (data) => {
        if (data.file.id.endsWith('/')) {
            // not show custom render, it is a folder or a multi file selection
            props.setSelectedFile(false);
            props.setSelectedDir(data.file);
        } else {
            // set current file and show custom render
            props.setSelectedFile(data.file);
            if (data.clickType === 'double') {
                //props.setcontentModalopen(true) # this open the content modal to see the file
                goToPage(`/account/fileview/${data.file.idfile}`)
            }

            data = '';
        }
    }

    const handle_get_list_file = async (folderPrefix) => {
        setisLoading(true);
        await API.get(
            'filemgmt',
            '/listfiles',
            {
                queryStringParameters: {
                    prefix: folderPrefix !== '/' ? folderPrefix : '',
                }
            })
            .then(response => {
                const chonkyFiles = [];
                const s3Objects = response.files.Contents;
                const s3Prefixes = response.files.CommonPrefixes;
                //.filter(item => item.Key !== '/')
                if (s3Objects) {
                    chonkyFiles.push(
                        ...s3Objects.filter(item => !item.Key.endsWith('/'))
                            .map(
                                (object) => ({
                                    id: object.Key,
                                    idfile: object.idfile,
                                    key: object.Key,
                                    name: path.basename(object.Key),
                                    modDate: object.LastModified,
                                    size: object.Size,
                                    Kind: object.Kind,
                                    operations: object.operations,
                                    extension: path.basename(object.Key).includes('tar.') ?
                                        'tar.' + path.basename(object.Key).split('.')
                                            [path.basename(object.Key).split('.').length - 1] :
                                        path.basename(object.Key).split('.')
                                            [path.basename(object.Key).split('.').length - 1]
                                })
                            )
                    );
                }
                //.filter(item => path.basename(item.Prefix) !== '')
                if (s3Prefixes) {
                    chonkyFiles.push(
                        ...s3Prefixes
                            .map(
                                (prefix) => ({
                                    id: prefix.Prefix,
                                    name: path.basename(prefix.Prefix),
                                    isDir: true,
                                })
                            )
                    );
                }
                setFiles(chonkyFiles);
            }).catch(error => {
                    setshowErrorModal(error.message);
                }
            );
        setisLoading(false);
    };

    useEffect(() => {
        let initialPrefix = folderPrefix
        const currentPrefix = window.localStorage.getItem('currentFileEditorState');
        if (!!currentPrefix) {
            const currentPrefix_paresd = JSON.parse(currentPrefix);
            if (!!currentPrefix_paresd.currentPrefix) {
                initialPrefix = currentPrefix_paresd.currentPrefix
            }
        }
        handle_get_list_file(initialPrefix);
    }, [folderPrefix, setFiles, refresh, props.refresh]);


    const folderChain = React.useMemo(() => {
        let folderChain = [];
        if (folderPrefix === '/') {
            window.localStorage.removeItem('currentFileEditorState')
            folderChain = [];
        } else {
            let currentPrefix = '';
            folderChain = folderPrefix
                .replace(/\/*$/, '')
                .split('/')
                .map(
                    (prefixPart) => {
                        currentPrefix = currentPrefix
                            ? path.join(currentPrefix, prefixPart)
                            : prefixPart;
                        window.localStorage.setItem('currentFileEditorState', JSON.stringify({
                            'currentPrefix': currentPrefix !== '/' ? folderPrefix : '',
                        }))
                        return {
                            id: currentPrefix,
                            name: prefixPart,
                            isDir: true,
                        };
                    }
                );
        }
        folderChain.unshift({
            id: '/',
            name: 'Home',
            isDir: true,
        });
        return folderChain;
    }, [folderPrefix]);


    const handleFileAction = useCallback(
        (data) => {
            if (data.id === ChonkyActions.OpenFiles.id) {
                if (data.payload.files && data.payload.files.length !== 1) return;
                if (!data.payload.targetFile || !data.payload.targetFile.isDir) return;
                const newPrefix = `${data.payload.targetFile.id.replace(/\/*$/, '')}/`;
                setKeyPrefix(newPrefix);
            }
            if (data.id === CustomDownloadKey.id) {
                if (!!data.state && !!data.state.selectedFiles && data.state.selectedFiles.length > 0) {
                    data.state.selectedFiles.map(file => {
                            return handleDownloadFile(file.id, 'myfiles', null);
                        }
                    )
                }
            }

            if (data.id === ChonkyActions.CreateFolder.id) {
                setModalInputFolder(true);
            }

            if (data.id === CustomDeleteKey.id) {

                if (!!data.state && !!data.state.selectedFiles && data.state.selectedFiles.length > 0) {
                    setmodalRemove(data.state.selectedFiles);
                }

            }


            if (data.id === CustomOpenSelectionKey.id || data.id === CustomShareSelectionKey.id) {
                if (!!data.state && !!data.state.selectedFiles && !!data.state.selectedFiles.length > 0 &&
                    (!!data.state.selectedFiles[0].idfile || !!data.state.selectedFiles[0].id)) {
                    let targetKey;
                    let dir;
                    if (!!data.state.selectedFiles[0].idfile) {
                        targetKey = data.state.selectedFiles[0].idfile;
                    } else {
                        targetKey = data.state.selectedFiles[0].id;
                        dir = data.state.selectedFiles[0]
                    }
                    if (!!targetKey.endsWith('/')) {
                        props.setSelectedDir(dir);
                        props.setSelectedFile(false);
                    } else {
                        props.setSelectedFile(data.state.selectedFiles[0]);
                        props.setSelectedDir(false);
                    }
                    if (data.id === CustomShareSelectionKey.id) {
                        props.setshowSharedUlrModal(true);
                    } else {
                        if (!targetKey.endsWith('/')) {
                            if (data.id === CustomOpenSelectionKey.id) {
                                goToPage(`/account/fileview/${targetKey}`);
                            }
                        }
                    }
                    props.setcontentModalopen(true);
                }
            }

            if (data.id === ChonkyActions.MouseClickFile.id) {

                if (!!data.payload) {
                    if (!!data.payload.file && !!data.payload.clickType) {
                        if (!!data.payload.file) {
                            handleSelectFile(data.payload);
                        } else {
                            props.setSelectedFile(false);
                            props.setSelectedDir(false);
                        }
                    }
                }
            }

            // if (data.id === ChonkyActions.ChangeSelection.id) {
            //     props.setSelectedFile(false);
            //     if (!!data.state && !!data.state.selectedFiles && data.state.selectedFiles.length > 0) {
            //         handleSelectFile(data);
            //     } else {
            //         setshowCustomRender(false);
            //         props.setSelectedFile(false);
            //     }
            // }
            //data.payload.draggedFile.id.split('/')[data.payload.draggedFile.id.split('/').length - 1]

            if (data.id === ChonkyActions.MoveFiles.id) {
                if (!!data.payload && !!data.payload.destination && !!data.payload.draggedFile) {
                    var newKey = '';
                    var isDir = false;
                    var baseDestination = data.payload.destination.id;
                    if (!baseDestination.endsWith('/')) baseDestination = baseDestination + '/';
                    if (data.payload.draggedFile.id.endsWith('/')) {
                        newKey = data.payload.draggedFile.id.split('/')[data.payload.draggedFile.id.split('/').length - 2] + '/'
                        isDir = true;
                    } else {
                        newKey = data.payload.draggedFile.id.split('/')[data.payload.draggedFile.id.split('/').length - 1]
                    }
                    handleRenameFile(data.payload.draggedFile.id, baseDestination + newKey, isDir);
                }
            }

            if (data.id === ChonkyActions.UploadFiles.id) {
                inputFile.current.click();
            }

            if (data.id === RenameKey.id) {
                setmodalRename(data.state.contextMenuTriggerFile);
            }

        },
        [setKeyPrefix, folderPrefix, handleDownloadFile, handleRenameFile]
    );

    const fileActions = useMemo(
        () => [
            RenameKey,
            ChonkyActions.DeleteFiles,
            ChonkyActions.DownloadFiles,
            ChonkyActions.OpenSelection,
            CustomShareSelectionKey,
            ChonkyActions.CreateFolder,
            ChonkyActions.OpenFiles,
            ChonkyActions.KeyboardClickFile,
            ChonkyActions.ChangeSelection,
            ChonkyActions.EnableCompactView,
            ChonkyActions.EnableGridView,
            ChonkyActions.EnableListView,
        ],
        []
    );

    const handleRefreshUpload = () => {
        setrefresh(!refresh);
        props.handlerRefresh();
    }

    const thumbnailGenerator = useCallback(
        // TODO
        (file) =>
            file.thumbnailUrl ? `https://chonky.io${file.thumbnailUrl}` : null,
        []
    );

    const handleCloseErrorModale = () => {
        setshowErrorModal(false);
        //window.location.reload();
    }

    const handleCloseSuccessModal = () => {
        setrequestSuccess(false);
    }

    //style={{height: '500px', overflow: 'scroll'}}
    //darkMode={true}
    return (
        <React.Fragment>
            <DndProvider backend={HTML5Backend}>
                <UploadNewImage
                    showButton={false}
                    showdnd={true}
                    inputFile={inputFile}
                    handlerRefresh={handleRefreshUpload}
                    folderPrefix={folderPrefix}
                />
                <Dimmer.Dimmable dimmed={false}
                                 style={{
                                     height: width < 1060 ? height - 200
                                         : height - 150
                                 }}>
                    <Dimmer active={!!isLoading || !files} inverted>
                        <Loader/>
                    </Dimmer>
                    {files &&
                    <FullFileBrowser
                        files={files}
                        folderChain={folderChain}
                        onFileAction={handleFileAction}
                        fileActions={fileActions}
                        thumbnailGenerator={thumbnailGenerator}
                        disableDefaultFileActions={true}
                    />
                    }
                </Dimmer.Dimmable>
            </DndProvider>

            {!!modalInputFolder &&
            <ModalCreateNewFolder
                close={setModalInputFolder}
                open={!!modalInputFolder}
                newPrefix={folderPrefix !== '/' ? folderPrefix : ''}
                setisLoading={setisLoading}
                setrefresh={setrefresh}
                refresh={refresh}
                handlerRefresh={props.handlerRefresh}
            />
            }

            {!!modalRename &&
            <ModalRenameKey
                close={setmodalRename}
                isLoading={isLoading}
                open={!!modalRename}
                keytorename={modalRename}
                oldkey={''}
                newPrefix={folderPrefix !== '/' ? folderPrefix : ''}
                setisLoading={setisLoading}
                setrefresh={setrefresh}
                refresh={refresh}
                handlerRefresh={props.handlerRefresh}
            />
            }
            {!!modalRemove &&
            <ModalDeleteKey
                close={setmodalRemove}
                isLoading={isLoading}
                open={!!modalRemove}
                selectedFiles={modalRemove}
                handlerRefresh={props.handlerRefresh}
                setSelectedFile={props.setSelectedFile}
                setSelectedDir={props.setSelectedDir}
                setrefresh={setrefresh}
                refresh={refresh}
            />
            }

            <ErrorModal
                open={!!showErrorModal}
                handleCloseErrorModal={handleCloseErrorModale}
                body={'An unexpected error occurred! Try again in few minutes.'}
                title={'Unexpected Error'}
                icon={'bug'}
            />

            <SuccessModal
                open={requestSuccess}
                title={'Operation Started Successfly'}
                handleCloseSuccessModal={handleCloseSuccessModal}
                body={"Now wait for the operation to complete. We will inform you by email too."}
                icon={'check'}
            />


        </React.Fragment>
    );
};

export default FileExplorer;

