/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:dc188ecf-7675-43cf-adf5-0d9beae28c9f",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_Un5DJDMl6",
    "aws_user_pools_web_client_id": "hsf6nhctoj59quhcn2fh6o7f1",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "gippyamplify4f43595fee3948c492437ce9fd5593fe181256-prod",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "gioppyamplifyusersinfos-prod",
            "region": "us-east-2"
        },
        {
            "tableName": "gioppyamplifypricing-prod",
            "region": "us-east-2"
        },
        {
            "tableName": "userpayments-prod",
            "region": "us-east-2"
        },
        {
            "tableName": "useroperations-prod",
            "region": "us-east-2"
        },
        {
            "tableName": "gioppyfiles-prod",
            "region": "us-east-2"
        },
        {
            "tableName": "notewhey-prod",
            "region": "us-east-2"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "transcribemgmt",
            "endpoint": "https://mcz5rzdbwk.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "filemgmt",
            "endpoint": "https://q6u8ty30fh.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "userinfo",
            "endpoint": "https://ljvv8qth76.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "gioppypay",
            "endpoint": "https://elornb5qa0.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "calculatepricing",
            "endpoint": "https://oarkch7cjb.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "translatetext",
            "endpoint": "https://fq9o7z1hd6.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "documentsmgmnt",
            "endpoint": "https://kbsmtlodi6.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "settings",
            "endpoint": "https://v0tn4aymmb.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "publicapi",
            "endpoint": "https://6ry8pze3m7.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "notewhey",
            "endpoint": "https://sz45g7zr26.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "history",
            "endpoint": "https://6xgaftctig.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
