import React, {useContext, useEffect, useState} from 'react';
import {Button, Container, Dimmer, Dropdown, Header, Icon, Loader, Modal, Popup} from "semantic-ui-react";
import {API} from "aws-amplify";
import {AuthContext} from "../../shared/context/auth-context";

const TextospeechSettingModal = (props) => {

    const auth = useContext(AuthContext);

    const [dominantLang, setdominantLang] = useState('');
    const [voiceIDPossibilites, setvoiceIDPossibilites] = useState('');
    const [voiceID, setvoiceID] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [possibilites, setpossibilities] = useState('');


    useEffect(() => {
        setIsLoading(true);
        const get_possibilites = async () => {
            API.post(
                'documentsmgmnt',
                '/getpossibilites',
                {
                    body: {
                        "key": props.file.key,
                        'to_convert': '',
                        'dominant_lang': '',
                        'operation': 'textospeech'
                    },
                }).then(res => {
                if (res.possibilities || res.possibilities === []) {
                    setpossibilities(res.possibilities)
                } else {
                    props.handleShowErrorModal("Unexpected Error")
                }
                setIsLoading(false);
            }).catch(err => {
                props.handleShowErrorModal("Unexpected Error");
                setIsLoading(false);
            })
        }
        get_possibilites();
    }, [props.file.key])


    const describeVoice = async (dominantLang) => {
        return await API.post(
            'documentsmgmnt',
            '/describevoices',
            {
                body: {
                    'dominant_lang': dominantLang,
                    'engine': 'standard' // standard
                },
            })
            .catch(error => {
                props.handleShowErrorModal("Unexpected Error");
            })
    }

    const handleDominantLang = async (e, data) => {
        setIsLoading(true);
        setdominantLang(data.value);
        let voicesID = await describeVoice(data.value);
        setvoiceIDPossibilites(
            voicesID.Voices.map(v => ({key: v.Id, value: v.Id, text: v.Name + ' (' + v.Gender + ')'}))
        );
        setIsLoading(false);
    }

    const handleVoiceId = (e, data) => {
        setvoiceID(data.value);
    }

    const textExtractRequest = () => {
        props.setisLoading(true);
        try {
            API.post(
                'documentsmgmnt',
                '/textospeech',
                {
                    body: {
                        "key": props.file.key,
                        'comprend': false,
                        'dominant_lang': voiceID,
                        'engine': 'standard'
                    },
                })
                .then(response => {
                    if (response.operation_allowed) {
                        const currentTempInfo = JSON.parse(window.localStorage.getItem('temporary_info'))
                        window.localStorage.setItem('temporary_info', JSON.stringify({
                            'credits': response.updated_credits,
                            'currentPlan': currentTempInfo.currentPlan
                        }))
                        auth.login();
                        props.setfileDetail(response);
                        props.setrequestSuccess('Text-To-Speech Started');
                    } else {
                        props.handleShowErrorModal("Operation Not Allowed");
                    }
                    props.handlerRefresh();
                })
                .catch(error => {
                    props.handleShowErrorModal("Unexpected Error");
                });
        } catch {
            props.handleShowErrorModal("Operation Not Allowed");
        }
        props.setselectedaction('');
        props.settextospeechModal(false);
        props.setactionbutton(false);
        props.setcreditrequired(false);
    };

    return (
        <Modal
            closeIcon
            open={props.textospeechModal}
            onClose={() => props.settextospeechModal(false)}
            size={"mini"}
        >
            <Header icon='settings' content='Some Settings Before Start'/>
            <Modal.Content>
                <Dimmer active={isLoading}>
                    <Loader> </Loader>
                </Dimmer>
                <Container>
                    <b> Dominant Language </b>
                    <br/>
                    <Dropdown
                        name={"langdominant"}
                        onChange={handleDominantLang}
                        options={possibilites}
                        placeholder={"Select A Dominant Lenguage"}
                        value={dominantLang}
                        search
                        selection
                    />
                    <Popup
                        trigger={<Icon circular name='info'/>}
                        content='The dominant lenguage of the document'
                        size='mini'
                    />
                    <br/>
                    <b> Voice </b>
                    <br/>
                    <Dropdown
                        name={"voiceID"}
                        onChange={handleVoiceId}
                        options={voiceIDPossibilites}  //langs_pyttsx3
                        placeholder={"Select the Desired Voice"}
                        value={voiceID}
                        search
                        selection
                        disabled={!voiceIDPossibilites}
                    />
                    <Popup
                        trigger={<Icon circular name='info'/>}
                        content='The voice ID used for generate the speech'
                        size='mini'
                    />
                    <br/>
                    <br/>
                    <p>
                        * We currently allow the conversion of documents up to 3000 characters.
                        If your document has more than the operation will fail.
                    </p>
                </Container>
            </Modal.Content>
            <Modal.Actions>
                <Button secondary onClick={() => props.settextospeechModal(false)}>
                    <Icon name='remove'/> Close
                </Button>
                <Button primary onClick={textExtractRequest} disabled={!dominantLang || !voiceID}>
                    <Icon name='checkmark'/> Continue
                </Button>
            </Modal.Actions>
        </Modal>
    );

};

export default TextospeechSettingModal;