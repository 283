import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../shared/context/auth-context";
import {Dimmer, Grid, Header, Image, Loader, Segment} from "semantic-ui-react";
import FileExplorer from "../Components/FileExplorer";
import CustomDetailRenderer from "../Components/CustomDetailRenderer";
import FolderImage from '../../static/images/folder.png'
import useWindowDimensions from "../../shared/hooks/useWindowsDimensions";
import {useAuth} from "../../shared/hooks/auth-hook";
import SharedUrlModal from "../Components/SharedUrlModal";

const MyFiles = () => {
    const auth = useContext(AuthContext);
    const [refresh, setrefresh] = useState(false);

    const [SelectedFile, setSelectedFile] = useState();
    const [SelectedDir, setSelectedDir] = useState();
    const [contentModalopen, setcontentModalopen] = React.useState(false);

    const [showSharedUlrModal, setshowSharedUlrModal] = useState(false);

    const handleCloseShareModal = () => {
        setshowSharedUlrModal(false);
    }

    const {height, width} = useWindowDimensions();

    const handlerRefresh = () => {
        setrefresh(!refresh);
    }

    const {isLoggedIn} = useAuth();

    useEffect(() => {
        auth.login();
    }, []);

    return (
        <React.Fragment>
            <Dimmer active={!isLoggedIn} page>
                <Loader/>
            </Dimmer>
            {!!isLoggedIn &&
            <Grid columns={2} stackable style={{marginBottom: 20}}>
                <Dimmer active={false} page>
                    <Loader/>
                </Dimmer>
                <Grid.Row>
                    <Grid.Column width={width < 1060 ? 16 : 9}
                                 style={{marginRight: width < 1060 ? '1em' : '-1em'}}>
                        <FileExplorer
                            refresh={refresh}
                            handlerRefresh={handlerRefresh}
                            setSelectedFile={setSelectedFile}
                            setSelectedDir={setSelectedDir}
                            setcontentModalopen={setcontentModalopen}
                            setshowSharedUlrModal={setshowSharedUlrModal}
                        />
                    </Grid.Column>

                    <Grid.Column width={width < 1060 ? 16 : 7}
                                 style={{
                                     marginLeft: width < 1060 ? 0 : 0,
                                     marginTop: width < 1060 ? 0 : 0,
                                     marginRight: width < 1060 ? '1em' : '0em'
                                 }}

                    >
                        {!!SelectedFile && SelectedFile.key &&
                        <CustomDetailRenderer
                            file={SelectedFile}
                            isDir={false}
                            open={contentModalopen}
                            setOpen={setcontentModalopen}
                            handlerRefreshFiles={handlerRefresh}
                        />
                        }
                        {!SelectedFile &&
                        <Segment secondary>
                            {!!SelectedDir && SelectedDir.name &&
                            <Header as={"h3"}>
                                {SelectedDir.name}
                            </Header>
                            }
                            <p> Click on a file to start working. </p>
                            <Image centered src={FolderImage} size='small'/>
                        </Segment>
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            }

            {!!showSharedUlrModal && (!!SelectedFile || !!SelectedDir) &&
            <SharedUrlModal
                open={!!showSharedUlrModal && (!!SelectedFile || !!SelectedDir)}
                closeModal={handleCloseShareModal}
                isDir={!!SelectedDir}
                outputkey={{
                    key: !!SelectedDir ? SelectedDir.id : SelectedFile.key,
                    location: 'myfiles'
                }}
            />
            }
        </React.Fragment>
    );


};

export default MyFiles;