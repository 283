import { createContext } from 'react';


export const AuthContext = createContext({
  isLoggedIn: false,
  userId: null,
  credits: null,
  sub: null,
  currentPlan: null,
  login: () => {},
  logout: () => {}
});
