import React, {useContext, useEffect, useState} from 'react';
import {Container, Dropdown, Menu} from "semantic-ui-react";
import {useHistory, useLocation} from "react-router-dom";
import {AuthContext} from "../../../shared/context/auth-context";
import {API, Auth} from "aws-amplify";
import useWindowDimensions from "../../../shared/hooks/useWindowsDimensions";


const SecondRow = (props) => {
    const [openDropDown, setopenDropDown] = useState(false);

    const auth = useContext(AuthContext);

    const history = useHistory();
    const goToPage = (page) => {
        history.push(page)
    }

    const {height, width} = useWindowDimensions();

    const logout = async () => {
        try {
            await Auth.signOut();
            auth.logout();
            goToPage("/");
        } catch (error) {
            //TODO
        }
    };

    const [pricingCards, setpricingCards] = useState(null);

    useEffect(() => {
        const getPricingInfos = async () => {
            try {
                const currentTempInfo = JSON.parse(window.localStorage.getItem('plans_infos'))
                if (!currentTempInfo) {
                    API.get(
                        'settings',
                        '/getplans',
                        {})
                        .then(response => {
                            setpricingCards(response);
                            window.localStorage.setItem('plans_infos', JSON.stringify({
                                'plans': response,
                            }))
                        })
                        .catch(error => {

                        });
                } else {
                    setpricingCards(currentTempInfo.plans)
                }
            } catch {
                //TODO
            }
        }
        getPricingInfos();
    }, [auth.isLoggedIn]);

    return (
        <Container fluid>
            <Menu secondary>
                <Menu.Item
                    position={"right"} style={{marginRight: '3em'}}>
                    <Dropdown inline
                              header
                              direction={'left'}
                              pointing={'top'}
                              open={openDropDown}
                              onClick={() => setopenDropDown(!openDropDown)}
                              onClose={() => setopenDropDown(!openDropDown)
                              }
                              text={<b
                                  style={{
                                      fontWeight: 'bold',
                                      color: '#1d3557',
                                      cursor: 'pointer',
                                      marginLeft: '1em',
                                  }}
                                  onClick={() => setopenDropDown(!openDropDown)}>
                                  {`Hello, ${auth.userId}`}
                              </b>}
                    >
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => logout()}>
                                Logout
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu.Item>


            </Menu>
        </Container>
    )

};

export default SecondRow;