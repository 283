import React, {useContext, useEffect, useState} from 'react';
import {Button, Container, Dimmer, Dropdown, Header, Icon, Loader, Modal, Popup} from "semantic-ui-react";
import {API} from "aws-amplify";
import {AuthContext} from "../../shared/context/auth-context";
import ErrorModal from "../../shared/FormElements/ErrorModal";

const TranscriptionSettingModal = (props) => {

    const auth = useContext(AuthContext);

    const [lang, setlang] = useState('');
    const [speckerLabel, setspeckerLabel] = useState(true);
    const [nspeacker, setnspeacker] = useState(10);
    const [chanellID, setchanellID] = useState(false);

    const [possibilites, setpossibilities] = useState('');
    const [isloading, setisloading] = useState(false);

    // handle transcribe
    const handleTranscribeLang = (e, data) => {
        setlang(data.value);
    }

    const handleTranscribeSpeakcerLabels = (e, data) => {
        setspeckerLabel(data.value);
        if (data.value) {
            setchanellID(false);
        }
    }

    const handleNSpeakcer = (e, data) => {
        setnspeacker(data.value);
    }

    const handlesetchanellID = (e, data) => {
        setchanellID(data.value);
        if (data.value) {
            setspeckerLabel(false);
            setnspeacker('N/A');
        }
    };


    useEffect(() => {
        setisloading(true);
        const get_possibilites = async () => {
            API.post(
                'documentsmgmnt',
                '/getpossibilites',
                {
                    body: {
                        "key": props.file.key,
                        'to_convert': '',
                        'dominant_lang': '',
                        'operation': 'transcriptiondefault'
                    },
                }).then(res => {
                if (res.possibilities || res.possibilities === []) {
                    setpossibilities(res.possibilities)
                } else {
                    props.handleShowErrorModal("Unexpected Error")
                }
                setisloading(false);
            }).catch(err => {
                props.handleShowErrorModal("Unexpected Error");
                setisloading(false);
            })
        }
        get_possibilites();
    }, [props.file.key])

    const transcribeRequest = () => {
        props.setisLoading(true);
        try {
            API.post(
                'transcribemgmt',
                '/transcribe',
                {
                    body: {
                        "key": props.file.key,
                        "mod": "default",
                        "extension": props.file.extension,
                        "lang": lang,
                        'IdentifyLanguage': !lang,
                        "settings": {
                            'VocabularyName': false,
                            'ShowSpeakerLabels': speckerLabel,
                            'MaxSpeakerLabels': !!speckerLabel ? nspeacker !== 'N/A' ? +nspeacker : 10 : false,
                            'ChannelIdentification': chanellID,
                            'ShowAlternatives': false,
                            'MaxAlternatives': false,
                            'VocabularyFilterName': false,
                            'VocabularyFilterMethod': false
                        }
                    },
                })
                .then(response => {
                    if (!!response.status && response.status === 'error' && !!response.error_message && response.error_message.length >= 1) {
                        props.setshowCustomError(response.error_message);
                    } else if (!!response.operation_allowed) {
                        const currentTempInfo = JSON.parse(window.localStorage.getItem('temporary_info'))
                        window.localStorage.setItem('temporary_info', JSON.stringify({
                            'credits': response.updated_credits,
                            'currentPlan': currentTempInfo.currentPlan
                        }))
                        auth.login();
                        props.setfileDetail(response);
                        props.setrequestSuccess('Transcription Started');
                    } else {
                        props.handleShowErrorModal("Operation Not Allowed");
                    }
                    props.handlerRefresh();
                })
                .catch(error => {
                    props.handleShowErrorModal("Unexpected Error");
                });
        } catch {
            props.handleShowErrorModal("Unexpected Error");
        }
        props.setselectedaction('');
        props.settrancribeModal(false);
        props.setactionbutton(false);
        props.setcreditrequired(false);
    };

    return (
        <Modal
            closeIcon
            open={props.trancribeModal}
            onClose={() => props.settrancribeModal(false)}
            size={"mini"}
        >
            <Header icon='settings' content='Some Settings Before Start'/>
            <Modal.Content>
                <Dimmer active={isloading}>
                    <Loader> Loading </Loader>
                </Dimmer>
                <Container>
                    <b> Input Audio/Video Language </b>
                    <br/>
                    <Dropdown
                        name={"lang"}
                        onChange={handleTranscribeLang}
                        options={!!possibilites && possibilites.langs && possibilites.langs}
                        placeholder={"Select A Language"}
                        value={lang}
                        search
                        selection
                    />
                    <Popup
                        trigger={<Icon circular name='info'/>}
                        content='The language used in the input media file.'
                        size='mini'
                    />
                    <br/>
                    <br/>
                    <b> Identify Speakers (leave it to YES if you want to create subtitles) </b>
                    <br/>
                    <Dropdown
                        name={"speacker_labels"}
                        onChange={handleTranscribeSpeakcerLabels}
                        options={!!possibilites && !!possibilites.transcription && !!possibilites.transcription.speacker_labels && possibilites.transcription.speacker_labels}
                        placeholder={"Speacker Labels"}
                        value={speckerLabel}
                        search
                        selection
                    />
                    <Popup
                        trigger={<Icon circular name='info'/>}
                        content='Determines whether the transcription job uses speaker recognition to identify different speakers in the input audio.
                            Speaker recognition labels individual speakers in the audio file.
                            You can not set both Speakers Identification and Channel lIdentification at the same time.'
                        size='mini'
                    />
                    <br/>
                    {/*<br/>*/}
                    {/*<b> Speaker Number </b>*/}
                    {/*<br/>*/}
                    {/*<Dropdown*/}
                    {/*    name={"estimate_speacker"}*/}
                    {/*    onChange={handleNSpeakcer}*/}
                    {/*    options={!!possibilites && !!possibilites.transcription && !!possibilites.transcription.estimate_speacker && possibilites.transcription.estimate_speacker}*/}
                    {/*    placeholder={"Estimanted Speackers"}*/}
                    {/*    value={nspeacker}*/}
                    {/*    search*/}
                    {/*    selection*/}
                    {/*/>*/}
                    {/*<Popup*/}
                    {/*    trigger={<Icon circular name='info'/>}*/}
                    {/*    content='The maximum number of speakers to identify in the input audio.'*/}
                    {/*    size='mini'*/}
                    {/*/>*/}
                    {/*<br/>*/}
                    <br/>
                    <b> Channel Identification </b>
                    <br/>
                    <Dropdown
                        name={"channelidentification"}
                        onChange={handlesetchanellID}
                        options={!!possibilites && !!possibilites.transcription && !!possibilites.transcription.channelidentification && possibilites.transcription.channelidentification}
                        placeholder={"Channel Identification"}
                        value={chanellID}
                        search
                        selection
                    />
                    <Popup
                        trigger={<Icon circular name='info'/>}
                        content='Process rocess each audio channel separately and then merge
                            the transcription output of each channel into a single transcription.
                            You can not set both Speakers Identification and Channel lIdentification at the same time.'
                        size='mini'
                    />
                    <br/>
                </Container>
            </Modal.Content>
            <Modal.Actions>
                <Button secondary onClick={() => props.settrancribeModal(false)}>
                    <Icon name='remove'/> Close
                </Button>
                <Button primary disabled={lang === ''} onClick={transcribeRequest}>
                    <Icon name='checkmark'/> Continue
                </Button>
            </Modal.Actions>
        </Modal>
    );

};

export default TranscriptionSettingModal;