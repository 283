import React from 'react';
import {Button, Dropdown, Grid} from "semantic-ui-react";
import {convertToRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import handleDownloadFile from "../../shared/hooks/download-hook";
import HTMLtoDOCX from 'html-to-docx';
import html2pdf from "html2pdf.js";

const DownloadOptions = [
    {key: 'downloaddocx', text: 'Download .docx', value: 'downloaddocx'},
    {key: 'downloadpdf', text: 'Download .pdf', value: 'downloadpdf'},
    {key: 'downloadhtml', text: 'Download .html', value: 'downloadhtml'}
]

const TextEditorButtons = (props) => {


    const handleConvertToHtml = () => {
        const rawContentState = convertToRaw(props.editorState.getCurrentContent());
        return draftToHtml(
            rawContentState
        );
    };

    const handleDropDownActionsTranscriptions = async (todo, data) => {
        let htmldraft = handleConvertToHtml()
        const basefilename = 'test'
        if (todo.value === 'downloadhtml') {
            handleDownloadFile(basefilename + '.html', 'local_plain', htmldraft)
        } else if (todo.value === 'downloaddocx') {
            // TODO, add settings, https://www.npmjs.com/package/html-to-docx
            let docx = await HTMLtoDOCX(htmldraft)
            handleDownloadFile(basefilename + '.docx', 'local_blob', docx)
        } else if (todo.value === 'downloadpdf') {
            var opt = {
                margin: [1, 1, 1, 1],
                filename: basefilename + '.pdf',
                image: {type: 'jpeg', quality: 0.98},
                html2canvas: {scale: 2},
                jsPDF: {unit: 'in', format: 'a4', orientation: 'portrait'}
            };

            html2pdf().set(opt).from(htmldraft).save();

            //html2pdf(element, opt);
            //html2pdf(htmldraft)
        }
    }

    return (
        <React.Fragment>
            <Grid stackable columns={3} style={{marginBottom: '0.25em', marginTop: -40}}>
                <Grid.Row>

                    {/*<Grid.Column>*/}
                    {/*    <Button size={'mini'} onClick={props.handleCancel}> Restore Original Transcription </Button>*/}
                    {/*</Grid.Column>*/}

                    <Grid.Column floated={'right'}>
                        <Button.Group size={'mini'}>
                            <Button positive onClick={props.handleSaveModifications}>Save</Button>
                            <Dropdown
                                className='button icon'
                                floating
                                options={DownloadOptions}
                                onChange={(e, data) => handleDropDownActionsTranscriptions(data, 'transcriptions')}
                                trigger={<></>}
                            />
                        </Button.Group>
                    </Grid.Column>

                </Grid.Row>
            </Grid>
        </React.Fragment>
    );

};

export default TextEditorButtons;