import React, {useState} from 'react';
import {Button, Dimmer, Loader, Modal} from "semantic-ui-react";

const ModalUserDetails = (props) => {

    const [isLoading, setisLoading] = useState(false);

    return (
        <Modal
            centered={true}
            open={props.open}
            onClose={props.handleModalUserDetails}
        >
            <Modal.Header>
                User Infos
            </Modal.Header>
            <Modal.Content>
                <Dimmer active={isLoading}>
                    <Loader/>
                </Dimmer>
                <div>
                    <pre>{
                        JSON.stringify(props.row.original.all_infos, null, 2)
                    }
                    </pre>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={props.handleModalUserDetails}
                >
                    Done
                </Button>
            </Modal.Actions>
        </Modal>

    );

};

export default ModalUserDetails