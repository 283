import React, {useState} from 'react';
import {Header, Progress, Segment} from "semantic-ui-react";
import ModalRemoveAccount from "../ModalRemoveAccount";
import ModalChangeEmail from "../ModalChangeEmail";

const AccountSettings = (props) => {
    const [removeAccount, setremoveAccount] = useState(false);

    const handleRemoveAccount = () => {
        setremoveAccount(!removeAccount)
    }

    const [changeEmail, setchangeEmail] = useState(false);

    const handleChangeEmail = () => {
        props.handleRefresh()
        setchangeEmail(!changeEmail);
    }

    return (
        <React.Fragment>

            <Segment>
                <Header as="h1">Account Setting</Header>

                <Header as="h3"> Storage </Header>
                <Progress percent={props.currentStorage.percentage_util}
                          warning={props.currentStorage.percentage_util >= 50}
                          success={props.currentStorage.percentage_util < 50}
                          error={props.currentStorage.percentage_util > 80}
                >
                    {Math.round(props.currentStorage.total_size * 1000) / 1000
                    + '/' + props.currentStorage.storage_limit + ' GB '
                    + '(' + props.currentStorage.percentage_util + '%' + ')'}
                </Progress>

                <Header as="h3"> User Details </Header>
                <p>{'Name: ' + props.user_name}</p>
                <p> {'Email: ' + props.email}
                    <b style={{
                        fontWeight: 'bold',
                        color: 'darkblue',
                        cursor: 'pointer',
                        marginLeft: '1em'
                    }}
                       onClick={handleChangeEmail}>
                        {'Change Email'}
                    </b>
                </p>

                <p>{'User since:     ' + new Date(Number(props.UserCreateDate) * 1000).toDateString()}</p>

                <p style={{fontWeight: 'bold', color: '#C70039', cursor: 'pointer'}}
                   onClick={handleRemoveAccount}>
                    Remove Account
                </p>
            </Segment>

            <ModalChangeEmail
                changeEmail={changeEmail}
                handleChangeEmail={handleChangeEmail}
            />

            <ModalRemoveAccount
                username={props.username}
                removeAccount={removeAccount}
                handleRemoveAccount={handleRemoveAccount}
            />
        </React.Fragment>
    );

}

export default AccountSettings;