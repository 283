import React, {useEffect, useState} from 'react';
import {EditorState, convertToRaw, convertFromRaw} from 'draft-js';
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {Button, Dimmer, Grid, Header, Icon, Input, Label, Loader, Modal, Segment} from "semantic-ui-react";
import TextEditorButtons from "./TextEditorButtons";
import {API} from "aws-amplify";

const ArticleTextEditor = (props) => {


    const [image, setImage] = useState(props.article.preview.image);
    const [title, setTitle] = useState(props.article.preview.title);
    const [category, setCategory] = useState(props.article.preview.category);
    const [desc, setDesc] = useState(props.article.preview.desc);
    const [articleID, setArticleID] = useState(props.article.id);
    const [Bimage, setBimage] = useState(props.article.preview.backgroundImage);
    const [seo, setSeo] = useState(props.article.seo);

    const [askCancel, setaskCancel] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [savestatus, setsavestatus] = useState(false);
    const [env, setEnv] = useState(props.env);
    const [editorState, setEditorState] = useState(
        !!props.article.content ?
            () => (EditorState.createWithContent(convertFromRaw(props.article.content)))
            : () => (EditorState.createEmpty())
    );

    useEffect(() => {
        const content = window.localStorage.getItem('article');
        if (!!content) {
            const parsedContent = JSON.parse(content);
            if (parsedContent.content === props.articleid) {
                const convertedState = convertFromRaw(parsedContent.content);
                setEditorState(EditorState.createWithContent(convertedState));
            } else {
                window.localStorage.removeItem('article');
            }
        } else if (!content) {
            window.localStorage.setItem('article', JSON.stringify({
                'content': props.article.content,
                'articleid': props.articleid
            }));
        }
    }, [props.articleid]);


    const saveContentLocalStorage = (state) => {
        try {
            const contentState = state.getCurrentContent();
            window.localStorage.setItem('article', JSON.stringify({
                'content': convertToRaw(contentState),
                'articleid': props.articleid
            }));
        } catch {
            // TODO: show error modal
        }
    }

    const onEditorStateChange = (state) => {
        saveContentLocalStorage(state);
        setEditorState(state);
    }

    const handleCancel = () => {
        setaskCancel(true);
    }

    const deleteModifications = () => {
        window.localStorage.removeItem('article');
        setEditorState(EditorState.createWithContent(convertFromRaw(props.article.content)));
        setaskCancel(false);
        window.localStorage.setItem('article', JSON.stringify({
            'content': props.article.content,
            'articleid': props.articleid
        }))
    }

    const handleSaveModifications = () => {
        setisLoading(true);
        var parsedContent_mod = JSON.parse(window.localStorage.getItem('article'));
        let finalArticle = {
            "id": articleID,
            "seo": seo,
            "preview": {
                "image": image,
                "backgroundImage": "",
                "category": category,
                "title": title,
                "desc": desc
            },
            "content": parsedContent_mod.content
        }

        API.post(
            'notewhey',
            '/create',
            {
                body: {
                    type: 'heroarticle',
                    article: finalArticle,
                    env: env
                }
            }).then(res => {
                if (res === 'ko') {
                    console.log("error saving")
                }
                setisLoading(false);
            }
        ).catch(err => {
                console.log("error saving");
                setisLoading(false);
            }
        )

        setisLoading(false);
    }

    const handleSaveSuccess = () => {
        props.handleRefresh();
        setsavestatus(false);
    }

    const handleMod = () => {
        var newBlocks = []
        newBlocks = convertToRaw(editorState.getCurrentContent()).blocks.filter(
            block => !block.text.startsWith("Speaker_") && !block.text.startsWith("Words with confidence below")
        );
        // REMOVE INLINE FORMATS
        // newBlocks = newBlocks.map(block => {
        //     var new_block = block;
        //     new_block.inlineStyleRanges = []
        //     return (new_block)
        // })
        setEditorState(EditorState.createWithContent(convertFromRaw({entityMap: {}, blocks: newBlocks})));
    }

    //defaultChecked
    return (
        <React.Fragment>

            <Segment style={{padding: "2rem"}}>
                <Grid floated={'left'}>
                    <Grid.Row floated={'right'}>
                        <div>
                            <Label> ID </Label>
                            <Input
                                style={{width: "370px"}}
                                id="id"
                                name="id"
                                placeholder={"Article ID"}
                                value={articleID}
                                onChange={(e) => setArticleID(e.target.value)}
                            />
                        </div>
                    </Grid.Row>
                    <Grid.Row floated={'right'}>
                        <div>
                            <Label> Title </Label>
                            <Input
                                style={{width: "370px"}}
                                id="title"
                                name="title"
                                placeholder={"Title"}
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>
                    </Grid.Row>
                    <Grid.Row>
                        <div>
                            <Label> Desc </Label>
                            <Input
                                style={{width: "370px"}}
                                id="Description"
                                name="description"
                                placeholder={"Description"}
                                value={desc}
                                onChange={(e) => setDesc(e.target.value)}
                            />
                        </div>
                    </Grid.Row>
                    <Grid.Row>
                        <div>
                            <Label> Imag </Label>
                            <Input
                                style={{width: "370px"}}
                                id="Image"
                                name="image"
                                placeholder={"Image"}
                                value={image}
                                onChange={(e) => setImage(e.target.value)}
                            />
                        </div>
                    </Grid.Row>
                    <Grid.Row>
                        <div>
                            <Label> BImg </Label>
                            <Input
                                style={{width: "370px"}}
                                id="backgroundImage"
                                name="backgroundImage"
                                placeholder={"backgroundImage"}
                                value={Bimage}
                                onChange={(e) => setBimage(e.target.value)}
                            />
                        </div>
                    </Grid.Row>
                    <Grid.Row>
                        <div>
                            <Label> Cat </Label>
                            <Input
                                style={{width: "370px"}}
                                id="Category"
                                name="category"
                                placeholder={"Category"}
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                            />
                        </div>
                    </Grid.Row>
                    <Grid.Row floated={'right'}>
                        <div>
                            <Label> SEO </Label>
                            <Input
                                style={{width: "370px"}}
                                id="id"
                                name="id"
                                placeholder={"Article ID"}
                                value={seo}
                                onChange={(e) => setSeo(e.target.value)}
                            />
                        </div>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Segment>
                <Dimmer active={!editorState || isLoading}>
                    <Loader/>
                </Dimmer>

                <br/>
                <TextEditorButtons
                    modvisible={true}
                    handleMod={handleMod}
                    handleSaveModifications={handleSaveModifications}
                    handleCancel={handleCancel}
                    editorState={editorState}
                />
                <Editor
                    editorStyle={{border: "1px solid", height: '500px', overflow: 'scroll'}}
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={onEditorStateChange}
                />
            </Segment>

            <Modal
                closeIcon
                open={askCancel}
                onClose={() => setaskCancel(false)}
            >
                <Header icon='archive' content='Ary you sure!?'/>
                <Modal.Content>
                    <p>
                        Do you want to revert you modifications?
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={() => setaskCancel(false)}>
                        <Icon name='remove'/> No
                    </Button>
                    <Button color='green' onClick={deleteModifications}>
                        <Icon name='checkmark'/> Yes
                    </Button>
                </Modal.Actions>
            </Modal>

            <Modal
                basic
                onClose={() => setsavestatus(false)}
                open={savestatus === 'success' || savestatus === 'fail'}
                size='small'
            >
                <Header icon>
                    <Icon name='archive'/>
                    {savestatus === 'success' ? 'Success' : 'Error'}
                </Header>
                <Modal.Content>
                    <p>
                        {savestatus === 'success' ? 'Modification Save With Success' : 'Modification Saver Error. Please Retry.'}
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='green' inverted onClick={handleSaveSuccess}>
                        <Icon name='checkmark'/> Ok
                    </Button>
                </Modal.Actions>
            </Modal>

        </React.Fragment>
    );
};

export default ArticleTextEditor;