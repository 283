import React, {useContext} from 'react';
import {Menu} from "semantic-ui-react";
import {useHistory, useLocation} from "react-router-dom";
import {AuthContext} from "../../shared/context/auth-context";
import {Auth} from "aws-amplify";
import {AiOutlineFileSearch} from 'react-icons/ai';
import {GrDocumentNotes} from 'react-icons/gr';
import {MdProductionQuantityLimits} from 'react-icons/md';
import {HiUserGroup} from 'react-icons/hi';
import {AiOutlineSetting} from 'react-icons/ai';
import {FiLogOut} from 'react-icons/fi';
import {FiPackage} from 'react-icons/fi';

const SideBarHomeMenuItems = (props) => {

    const location = useLocation();
    const pathnames = location.pathname.split('/')[location.pathname.split('/').length - 1];


    const history = useHistory();
    const auth = useContext(AuthContext);

    const goToPage = (page) => {
        history.push(page)
    }

    const logout = async () => {
        try {
            await Auth.signOut();
            auth.logout();
            goToPage("/");
        } catch (error) {
            //TODO
        }
    };

    return (
        <React.Fragment>

            <Menu.Item as='a'
                       onClick={() => goToPage('/account/my-files')}
                       active={pathnames === 'my-files'}
            >

                <AiOutlineFileSearch size={'25'} color={'black'}/>
                <p style={{color: 'black'}}> My Files </p>
            </Menu.Item>
            <Menu.Item as='a'
                       onClick={() => goToPage("/account/notewhey")}
                       active={pathnames === 'notewhey'}
            >
                <GrDocumentNotes size={'25'}/>
                <p style={{color: 'black'}}> My Notes </p>
            </Menu.Item>
            <Menu.Item as='a'
                       onClick={() => goToPage("/account/client-orders")}
                       active={pathnames === 'client-orders'}
            >
                <FiPackage style={{color: 'black'}} size={'25'}/>
                <p style={{color: 'black'}}> Client Orders </p>
            </Menu.Item>
{/*            <Menu.Item as='a'
                       onClick={() => goToPage("/account/blog-creation")}
                       active={pathnames === 'blog-creation'}
            >
                <BsFillPencilFill size={'25'} color={'black'}/>
                <p style={{color: 'black'}}> Blog Creation </p>
            </Menu.Item>*/}
            <Menu.Item as='a'
                       onClick={() => goToPage("/account/herousers")}
                       active={pathnames === 'herousers'}
            >
                <HiUserGroup size={'25'} color={'black'}/>
                <p style={{color: 'black'}}> Hero Users </p>
            </Menu.Item>
            <Menu.Item as='a'
                       onClick={() => goToPage("/account/order-creation")}
                       active={pathnames === 'order-creation'}
            >
                <MdProductionQuantityLimits size={'25'} color={'black'}/>
                <p style={{color: 'black'}}> Order Creation </p>
            </Menu.Item>
            <Menu.Item as='a'
                       onClick={() => goToPage("/account/settings")}
                       active={pathnames === 'settings'}
            >
                <AiOutlineSetting size={'25'} color={'black'}/>
                <p style={{color: 'black'}}> Settings </p>
            </Menu.Item>
            <Menu.Item as='a' onClick={() => logout()}>
                <FiLogOut size={'25'} color={'black'}/>
                <p style={{color: 'black'}}> Log Out </p>
            </Menu.Item>
        </React.Fragment>
    )

};

export default SideBarHomeMenuItems;