import React, {useContext, useEffect, useState} from 'react';
import {
    Input,
    Button,
    Container,
    Dimmer,
    Dropdown,
    Header,
    Icon,
    Loader,
    Modal,
    Popup,
    Select, Checkbox
} from "semantic-ui-react";
import {API} from "aws-amplify";
import {AuthContext} from "../../shared/context/auth-context";


// const approach_options = [
//     {key: 'fast', value: 'fast', text: 'Fast (May lose formatting)'},
//     {key: 'default', value: 'default', text: 'Standard (May lose some formatting)'},
//     {key: 'advanced', value: 'advanced', text: 'Standard'}
// ]

const approach_options = [
    {key: 'advanced', value: 'advanced', text: 'Advanced'},
]

const formality_options = [
    {key: 'default', value: 'default', text: 'Default'},
    {key: 'less', value: 'less', text: 'Less'},
    {key: 'more', value: 'more', text: 'More'},
]

const keepOriginaltextoptions = [
    {key: false, value: false, text: 'NO'},
    {key: true, value: true, text: 'YES'},
]

const TransaltionSettingModal = (props) => {

    const auth = useContext(AuthContext);

    const [fromlang, setfromlang] = useState('automatic');
    const [tolang, settolang] = useState();
    const [formality, setFormality] = useState('default');
    const [approach, setapproach] = useState('advanced');

    const [possibilites, setpossibilities] = useState('');
    const [isloading, setisloading] = useState(false);
    const [keepOriginalText, setkeepOriginalText] = useState(false);

    const [ignoredTagsList, setignoredTagsList] = useState('');

    const [placholderPreserve, setplacholderPreserve] = useState('{[a-zA-Z]+?}');

    const [desired_output_extension, setdesired_output_extension] = useState(null);

    useEffect(() => {
        setisloading(true);
        const get_possibilites = async () => {
            API.post(
                'documentsmgmnt',
                '/getpossibilites',
                {
                    body: {
                        "key": props.file.key,
                        'to_convert': '',
                        'dominant_lang': '',
                        'operation': 'translationdeep',
                    },
                }).then(res => {
                if (res.possibilities || res.possibilities === []) {
                    setpossibilities(res.possibilities)
                } else {
                    props.handleShowErrorModal("Unexpected Error")
                }
                setisloading(false);
            }).catch(err => {
                props.handleShowErrorModal("Unexpected Error");
                setisloading(false);
            })
        }
        get_possibilites();
    }, [props.file.key])

    const handlefromTranslateLang = (e, data) => {
        setfromlang(data.value);
    }

    const handlotoTranslateLang = (e, data) => {
        settolang(data.value);
    }

    const handleTranslateApproach = (e, data) => {
        setapproach(data.value);
    }

    const handleTranslateFormality = (e, data) => {
        setFormality(data.value);
    }

    const handleChangeKeepText = (e, data) => {
        setkeepOriginalText(data.value)
    }

    const handleCustomOutput = (e, data) => {
        setdesired_output_extension(data.value);
    }

    const handleListIgnoredTags = (e) => {
        setignoredTagsList(e.target.value);
    };

    const handlePlaceHolderToPreserve = (e) => {
        setplacholderPreserve(e.target.value);
    }

    const translateRequest = () => {
        props.setisLoading(true);
        try {
            API.post(
                'translatetext',
                '/translate',
                {
                    body: {
                        "key": props.file.key,
                        'mod': props.transmod,
                        'SourceLanguageCode': fromlang,
                        'TargetLanguageCode': tolang,
                        'split_sentences': 'nonewlines',
                        'preserve_formatting': '0',
                        'formality': formality,
                        'aproach': approach,
                        'desired_output_extension': desired_output_extension,
                        'additional_settings': {
                            'keep_original': keepOriginalText,
                            'ignoredTags': ignoredTagsList,
                            'placholder_preserve': placholderPreserve,
                            'experimental': approach === 'default'
                        }
                    },
                })
                .then(response => {
                    if (response.operation_allowed) {
                        const currentTempInfo = JSON.parse(window.localStorage.getItem('temporary_info'))
                        window.localStorage.setItem('temporary_info', JSON.stringify({
                            'credits': response.updated_credits,
                            'currentPlan': currentTempInfo.currentPlan
                        }))
                        auth.login();
                        props.setfileDetail(response);
                        props.setrequestSuccess('Translation Started');
                    } else {
                        props.handleShowErrorModal("Operation Not Allowed");
                    }
                    props.handlerRefresh();
                })
                .catch(error => {
                    props.handleShowErrorModal("Unexpected Error");
                });
        } catch {
            props.handleShowErrorModal("Unexpected Error");
        }
        props.setselectedaction('');
        props.settranslateModal(false);
        props.setactionbutton(false);
        props.setcreditrequired(false);
    };



    return (
        <Modal
            closeIcon
            open={props.translateModal}
            onClose={() => props.settranslateModal(false)}
            size={"mini"}
        >
            <Header icon='settings' content='Some Settings Before Start'/>
            <Modal.Content scrolling>
                <Dimmer active={isloading}>
                    <Loader> Loading </Loader>
                </Dimmer>
                {!!possibilites
                && !!possibilites.full_supported_formats
                && !possibilites.full_supported_formats.includes(props.file.extension) &&
                <p style={{fontStyle: 'italic'}}>
                    {'.' + props.file.extension + ' is not yet fully supported; the output will appear as a .txt file.'}
                    <Popup
                        trigger={<Icon circular name='info'/>}
                        content={'Currently, the extensions that are fully supported are ' +
                        possibilites.full_supported_formats + '.'}
                    />
                </p>
                }
                <Container>
                    <b> Current Lenguage </b>
                    <br/>
                    <Dropdown
                        name={"langfrom"}
                        onChange={handlefromTranslateLang}
                        options={possibilites.input}
                        placeholder={"Automatic"}
                        value={fromlang}
                        search
                        selection
                    />
                    <Popup
                        trigger={<Icon circular name='info'/>}
                        content='The language used in the input file.'
                        size='mini'
                    />
                    <br/>
                    <br/>
                    <b> Target Lenguage </b>
                    <br/>
                    <Dropdown
                        name={"langto"}
                        onChange={handlotoTranslateLang}
                        options={!!possibilites && !!possibilites.output && possibilites.output.filter(item => item.key !== 'automatic')}
                        placeholder={"Select A Lenguage"}
                        value={tolang}
                        search
                        selection
                    />
                    <Popup
                        trigger={<Icon circular name='info'/>}
                        content='The language desired in the target document'
                        size='mini'
                    />
                    <br/>
                    <br/>
                    <b> Formality </b>
                    <br/>
                    <Select
                        name={'formality'}
                        onChange={handleTranslateFormality}
                        options={formality_options}
                        value={formality}
                    />
                    <Popup
                        trigger={<Icon circular name='info'/>}
                        content='Sets whether the translated text should lean towards formal or informal language.'
                        size='mini'
                    />
                    <br/>
                    <br/>
                    {/*{(props.file.extension === 'pptx' || props.file.extension === 'docx' || props.file.extension === 'txt' || props.file.extension === 'html') &&*/}
                    {/*<React.Fragment>*/}
                    {/*    <b> Translation Approach </b>*/}
                    {/*    <Select*/}
                    {/*        name={'approach'}*/}
                    {/*        onChange={handleTranslateApproach}*/}
                    {/*        options={approach_options}*/}
                    {/*        value={approach}*/}
                    {/*    />*/}
                    {/*    <Popup*/}
                    {/*        trigger={<Icon circular name='info'/>}*/}
                    {/*        content='The translation approach to use.'*/}
                    {/*        size='mini'*/}
                    {/*    />*/}
                    {/*    <br/>*/}
                    {/*    <br/>*/}
                    {/*</React.Fragment>*/}
                    {/*}*/}
                    {!!possibilites.custom_output_formats && !!possibilites.custom_output_formats.filter(item => item[props.file.extension]).length > 0 &&
                    <React.Fragment>
                        <b> Cutom Output Format </b>
                        <Dropdown
                            name={"customoutformat"}
                            onChange={handleCustomOutput}
                            options={possibilites.custom_output_formats.filter(item => item[props.file.extension])[0][props.file.extension]}
                            placeholder={"Output Format"}
                            value={desired_output_extension}
                            search
                            selection
                        />
                        <Popup
                            trigger={<Icon circular name='info'/>}
                            content={"." + props.file.extension + " files are not easily editable. " +
                            "If you want to edit the output of this translation easily, select one of the available formats."}
                            size='mini'
                        />
                        <br/>
                        <br/>
                    </React.Fragment>
                    }
                    {props.file.extension === 'docx' && approach === 'fast' &&
                    <React.Fragment>
                        <b> Keep Original Text </b>
                        <Dropdown
                            name={"originaltextkeep"}
                            onChange={handleChangeKeepText}
                            options={keepOriginaltextoptions}
                            placeholder={"KeepOriginalText?"}
                            value={keepOriginalText}
                            search
                            selection
                        />
                        <Popup
                            trigger={<Icon circular name='info'/>}
                            content="If you choose 'YES,' the output document will contain,
                                for each paragraph, the original text under the translated text.
                                Note that this approach may lead to losing some of the original formattings.
                                "
                            size='mini'
                        />
                        <br/>
                        <br/>
                    </React.Fragment>
                    }
                    {props.file.extension === 'json' &&
                    <React.Fragment>
                        <br/>
                        <br/>
                        <b> Placeholders to preserve (regex) </b>
                        <Input
                            style={{width: "210px"}}
                            id="placeholdertoreserve"
                            name="ignoretaglist"
                            placeholder={'Placeholders to preserve'}
                            value={placholderPreserve}
                            onChange={handlePlaceHolderToPreserve}
                        />
                        <Popup
                            trigger={<Icon circular name='info'/>}
                            content='Regex that indicates the placholder to preserve (e.g, if regex is "{[a-zA-Z]+?}"
                            then {"title": "Hello, {firstName}! This is a title."}
                            will be translated as {"title": "Hallo, {firstName}! Dies ist ein Titel."}'
                            size='mini'
                        />
                        <p style={{fontStyle: 'italic'}}> Note: Key will be preserved in any cases</p>
                    </React.Fragment>
                    }
                    {/*{props.file.extension === 'xml' &&*/}
                    {/*<React.Fragment>*/}
                    {/*    <b> Tags to Ignore </b>*/}
                    {/*    <br/>*/}
                    {/*    <Input*/}
                    {/*        style={{width: "210px"}}*/}
                    {/*        id="ignoretaglist"*/}
                    {/*        name="ignoretaglist"*/}
                    {/*        placeholder={'List of comma separated tags to ignore (e.g., tag1,tag2)'}*/}
                    {/*        value={ignoredTagsList}*/}
                    {/*        onChange={handleListIgnoredTags}*/}
                    {/*    />*/}
                    {/*    <Popup*/}
                    {/*        trigger={<Icon circular name='info'/>}*/}
                    {/*        content='To ensure that elements in the original text are not altered in translation*/}
                    {/*                (e.g. trademarks or product names),*/}
                    {/*                set this to YES and specify a tag for this translation. It required*/}
                    {/*                that your text contains the xml tags to be ignored (e.g., <sk> Ingore this </sk>.*/}
                    {/*                In sert a comma-separated list of XML tags that indicate text not to be translated. (e.g., sk)'*/}
                    {/*        size='mini'*/}
                    {/*    />*/}
                    {/*</React.Fragment>*/}
                    {/*}*/}
                    {!!props.file.size && +props.file.size / 1000000 >= 10 && props.file.extension !== 'json' &&
                    <p style={{fontStyle: 'italic'}}> Note: The file size is more than 10MB; the translation is disabled
                        because it may fail.
                        Please, split your file into multiple parts and retry. This issue will be solved soon.
                    </p>
                    }
                </Container>
            </Modal.Content>
            <Modal.Actions>
                <Button secondary onClick={() => props.settranslateModal(false)}>
                    <Icon name='remove'/> Close
                </Button>
                <Button primary
                        disabled={!fromlang || !tolang || (!!props.file.size && +props.file.size / 1000000 >= 10 && props.file.extension !== 'json')}
                        onClick={translateRequest}>
                    <Icon name='checkmark'/> Continue
                </Button>
            </Modal.Actions>
        </Modal>
    );

};

export default TransaltionSettingModal;