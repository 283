import React, {useEffect, useState} from 'react';
import {Header, Radio, Segment} from "semantic-ui-react";
import {API} from "aws-amplify";


const NotificationSettings = (props) => {

    const [completedTranslations, setcompletedTranslations] = useState(!!props.notifications && !!props.notifications.completed_translations);
    const [completedTranscriptions, setcompletedTranscriptions] = useState(!!props.notifications && !!props.notifications.completed_transcriptions);
    const [newsNotification, setnewsNotification] = useState(!!props.notifications && !!props.notifications.news_notification);

    const [isLoading, setisLoading] = useState(false);
    const [isFirstMount, setisFirstMount] = useState(true);

    const handleChangeJobTranslations = (e, data) => {
        if (data.checked === true) {
            setcompletedTranslations(true)
        } else {
            setcompletedTranslations(false);
        }
    }

    const handleChangeJobTranscriptions = (e, data) => {
        if (data.checked === true) {
            setcompletedTranscriptions(true);
        } else {
            setcompletedTranscriptions(false);
        }
    }

    const handleChangeJobNewsLetter = (e, data) => {
        if (data.checked === true) {
            setnewsNotification(true);
        } else {
            setnewsNotification(false);
        }
    }

    useEffect(() => {
        const handleUpdateConfigs = async () => {
            setisLoading(true);
            try {
                await API.post(
                    'settings',
                    '/notifications-settings',
                    {
                        body: {
                            notifications: {
                                completed_transcriptions: completedTranscriptions,
                                completed_translations: completedTranslations,
                                news_notification: newsNotification
                            }
                        }
                    })
            } catch {

            }
            setisLoading(false);
        }

        if (!isFirstMount) {
            handleUpdateConfigs()
        } else {
            setisFirstMount(false);
        }

    }, [completedTranslations, completedTranscriptions, newsNotification])


    return (
        <React.Fragment>

            <Segment loading={!!isLoading}>
                <Header as="h1">Notifications</Header>
                <Header as="h3">Email Notifications</Header>
                <Header as="h4">Completed Transcriptions</Header>
                <Radio
                    toggle
                    checked={completedTranscriptions}
                    onClick={handleChangeJobTranscriptions}
                />

                <Header as="h4">Completed Translations</Header>
                <Radio toggle
                       checked={completedTranslations}
                       onClick={handleChangeJobTranslations}
                />

                <Header as="h4"> Newsletter </Header>
                <Radio toggle
                       checked={newsNotification}
                       onClick={handleChangeJobNewsLetter}
                />

            </Segment>

        </React.Fragment>
    );

};

export default NotificationSettings;