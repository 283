import React from 'react';

const AppIE = () => {
    return (
        <div>
            <h1>Hi there. You’re using an outdated browser</h1>
            <p>For a safer and faster user experience use a modern browser like Chrome, Firefox, Safari, Opera, or Edge.</p>
        </div>
    );
};

export default AppIE;
