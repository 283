import React from 'react';
import TableO from "./Table";
import {Button, Container, Dimmer, Loader, Pagination} from "semantic-ui-react";

const itemperpage = [
    {"value": 10, "id": 10, "text": "10"},
    {"value": 25, "id": 25, "text": "25"},
    {"value": 50, "id": 50, "text": "50"},
];

const OrdersTable = (props) => {
    const handlePageChange = (page) => {

        if (props.currentPage === page) {
            return;
        }

        console.log(page)
        if (!props.isloading) {
            let tagetPage;
            if (page === 'next') {
                if (!props.LastEvaluatedKey || props.lastPage === props.currentPage) {
                    return;
                }
                tagetPage = props.currentPage + 1
                props.setCurrentPage(tagetPage);
            } else if (page === 'previous') {
                if (props.currentPage === -1) {
                    return;
                }
                tagetPage = props.currentPage - 1;
                if (tagetPage >= -1) {
                    props.setCurrentPage(props.currentPage - 1);
                }
            } else {
                props.setCurrentPage(page);
            }
        }
    };

    const handleFilterOrderStatus = (status) => {
        props.setOrderStatus(status)
    }

    const handleChangeItemPerPage = (e) => {
        props.setitemPerPage(e.target.value);
        props.setLastPage(-2);
        props.setLastEvaluatedKey([]);
        props.setpageData(null);
        props.setCurrentPage(-1);
    }

    return (
        <Container fluid>
            <Container style={{marginBottom: "20px"}}>
                <Button
                    basic={!!props.orderStatus}
                    color="secondary"
                    className={"btn m-1"}
                    onClick={() => handleFilterOrderStatus('')}
                >
                    All
                </Button>
                {" "}
                <Button
                    basic={!(props.orderStatus === 'WAITING_PAYMENT')}
                    color="secondary"
                    className={"btn m-1"}
                    onClick={() => handleFilterOrderStatus('WAITING_PAYMENT')}
                >
                    Waiting For Payment
                </Button>
                {" "}
                <Button
                    basic={!(props.orderStatus === 'IN_PROGRESS')}
                    color="secondary"
                    className={"btn m-1"}
                    onClick={() => handleFilterOrderStatus('IN_PROGRESS')}
                >
                    In Progress
                </Button>
                {" "}
                <Button
                    basic={!(props.orderStatus === 'COMPLETED')}
                    color="secondary"
                    className={"btn m-1"}
                    onClick={() => handleFilterOrderStatus('COMPLETED')}
                >
                    Completed
                </Button>
            </Container>
            {!!props.pageData && !!props.pageData && !!props.pageData.columns && !!props.pageData.data &&
            <TableO
                columns={props.pageData.columns}
                data={props.pageData.data}
                handleRefres={props.handleRefres}
            />
            }
            {(!(!!props.pageData && !!props.pageData.columns && !!props.pageData.data) || props.isloading) &&
            <Dimmer active={true} page>
                <Loader/>
            </Dimmer>
            }
            {!!props.pageData && !!props.pageData.columns && !!props.pageData.data &&
            <Container textAlign={"right"} style={{marginTop: "20px"}}>
                <Pagination
                    firstItem={null}
                    lastItem={null}
                    totalPages={props.LastEvaluatedKey.length + 1}
                    onPageChange={(e, data) => {
                        handlePageChange(data.activePage - 2)
                    }}
                >
                </Pagination>
            </Container>
            }
        </Container>
    );

};

export default OrdersTable;